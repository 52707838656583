import {
  useState,
  useMemo,
  forwardRef,
  ChangeEvent,
  ForwardedRef,
} from "react";

interface DropdownMultipleSelectorSelectProps<T> {
  title: string;
  items: T[];
  value: T[];
  size?: number;
  filterPlaceholder?: string;
  itemToString: (item: T) => string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function DropdownMultipleSelectorSelectInner<T>(
  props: DropdownMultipleSelectorSelectProps<T>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const {
    title,
    items,
    value,
    filterPlaceholder,
    itemToString,
    onFilterChange,
    ...rest
  } = props;
  const [filter, setFilter] = useState<string>("");

  const filtered = useMemo(
    () =>
      value.filter((item: T) =>
        itemToString(item).toLowerCase().includes(filter)
      ),
    [value, filter, itemToString]
  );

  return (
    <div className="d-flex flex-column gap-1 w-100">
      <span className="h6 m-0">{title}</span>
      <input
        className="form-control"
        value={filter}
        onChange={(e) => {
          onFilterChange?.(e);
          setFilter(e.currentTarget.value.toLowerCase());
        }}
        placeholder={filterPlaceholder}
      />
      <select
        ref={ref}
        className="form-select form-select-field2 h-100"
        required={false} // Don't want browser default validation
        multiple
        {...rest}
      >
        {filtered.map((item: T, key: number) => (
          <option key={key} value={items.indexOf(item)}>
            {itemToString(item)}
          </option>
        ))}
      </select>
    </div>
  );
}

export const DropdownMultipleSelectorSelect = forwardRef(
  DropdownMultipleSelectorSelectInner
) as <T>(
  props: DropdownMultipleSelectorSelectProps<T> & {
    ref?: ForwardedRef<HTMLSelectElement>;
  }
) => ReturnType<typeof DropdownMultipleSelectorSelectInner>;
