import "./SideColFooter.scss";

export const SideColFooter = () => (
  <div className="side-col-footer text-center">
    <div className="fwv-links">
      <ul className="d-flex">
        <li>
          <a
            href="https://www.forwardworks.org.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            What is it?
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/whatisthenfwv.html#howdoesitwork"
            target="_blank"
            rel="noopener noreferrer"
          >
            How does it work?
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/casestudies.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Case studies
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/whatisthenfwv.html#why"
            target="_blank"
            rel="noopener noreferrer"
          >
            Benefits
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/faq.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/contact.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>
        </li>
      </ul>
    </div>
    <div className="terms-links">
      <ul>
        <li>
          <a
            href="https://www.forwardworks.org.nz/termsofuse"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/copyright"
            target="_blank"
            rel="noopener noreferrer"
          >
            Copyright
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/disclaimer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Disclaimer
          </a>
        </li>
        <li>
          <a
            href="https://www.forwardworks.org.nz/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
        </li>
      </ul>
    </div>
  </div>
);
