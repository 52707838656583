import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "savedSearchName";
const maxLength = 35;

export const savedSearchNameConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().when("savedSearch", {
    is: true,
    then: Yup.string().required("Name is required."),
  }),
};

export const SavedSearchName = () => (
  <TextField
    label="Saved search name"
    placeholder="Create a name for this search"
    name={fieldName}
    maxLength={maxLength}
  />
);
