import { LeftHeaderTable, LeftHeaderRow } from "components/table";
import { Stage } from "utils/types/django";
import { DateInfo } from "./TableFields/DateInfo";
import { ImpactsInfo } from "./TableFields/ImpactsInfo";

export const StageTable = ({ stage }: { stage: Stage }) => (
  <div>
    <div className="px-2 py-3">
      <DateInfo startDate={stage.start_date} endDate={stage.end_date} />
      <ImpactsInfo impacts={stage.impacts} />
    </div>
    <div className="px-2 py-3 gray-bg">
      <LeftHeaderTable small>
        <LeftHeaderRow header="Last updated" data={stage.last_updated} />
        <LeftHeaderRow header="FWV stage ID" data={stage.id} />
      </LeftHeaderTable>
    </div>
  </div>
);
