import * as Yup from "yup";

import { FormFieldConfig } from "utils/types/form";
import { ComboBox } from "components/form/ComboBox";
import { useProjectContactNames } from "components/fetch/useProjectContactsQuery";
import { defaultFilterValue } from "../MyOrganisationFilterContext";
import { FieldSwitcher } from "./FieldSwitcher";
import { useMyOrganisationFilter } from "../MyOrganisationFilterContext";

export const fieldName = "contact";

export const useContactNameConfig = (): FormFieldConfig => {
  const contactNames = useProjectContactNames();
  return {
    name: fieldName,
    initialValue: defaultFilterValue.contact,
    validationSchema: Yup.string().oneOf(
      contactNames,
      "Please select a contact name from the list."
    ),
  };
};

export const ContactName = () => {
  const contactNames = useProjectContactNames();
  const { getIsActive } = useMyOrganisationFilter();

  return (
    <FieldSwitcher fieldName={fieldName} label="Contact name">
      <ComboBox
        name={fieldName}
        items={contactNames}
        placeholder="Start typing and select user"
        clearCondition={!getIsActive(fieldName)}
      />
    </FieldSwitcher>
  );
};
