import { useEffect, useState } from "react";
import { CheckStates } from "components/layers/LayersProvider";
import { CHECKBOX_STATES } from "./CascadingCheckboxAccordion";

export const useParentCheckState = (checkStates: CheckStates) => {
  const [checked, setChecked] = useState(CHECKBOX_STATES.Empty);

  useEffect(() => {
    const allChecked =
      Object.entries(checkStates).length > 0 &&
      Object.entries(checkStates).reduce(
        (prev, [_key, value]) => prev && value,
        true
      );
    const anyChecked = Object.entries(checkStates).reduce(
      (prev, [_key, value]) => prev || value,
      false
    );
    if (allChecked) {
      setChecked(CHECKBOX_STATES.Checked);
    } else if (anyChecked) {
      setChecked(CHECKBOX_STATES.Indeterminate);
    } else {
      setChecked(CHECKBOX_STATES.Empty);
    }
  }, [checkStates]);

  return checked;
};
