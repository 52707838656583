import { useState } from "react";
import { ConfirmCancelPromptModal } from "components/modal/PromptModal";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProject } from "components/project/ProjectProvider";
import { useErrorModal } from "components/modal/useErrorModal";
import { usePermission } from "components/permission/usePermission";
import { LoadingModal } from "components/loading/LoadingModal";

export const MakeProjectMultiStageButton = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { selectedProject } = useSelectedProject();
  const { data: project, refetch } = useProjectByIdQuery(selectedProject);
  const { partialUpdateProject } = useProject();
  const { setErrorModal } = useErrorModal();
  const permission = usePermission();

  const onClick = () => {
    setShowPrompt(true);
  };

  const makeProjectMultiStage = async () => {
    setShowPrompt(false);

    let isError = false;

    if (project) {
      setIsSaving(true);
      try {
        await partialUpdateProject(project, {
          stages: [
            { ...project.stages[0], title: `${project.title} - Stage 1` },
          ],
          is_multistage: true,
        });
        refetch();
      } catch (error: unknown) {
        console.error(error);
        isError = true;
      } finally {
        setIsSaving(false);
      }
    } else {
      isError = true;
    }

    if (isError) {
      setErrorModal({
        title: "Error updating",
        content: "Could not make project multi-stage",
      });
    }
  };

  const promptContent = (
    <div>
      <p>You are about to make this project multi-stage.</p>
      <p>
        The work boundaries (and any traffic impacts) you've already added to
        this project will be converted into your first stage.
      </p>
      <p>You can edit this stage once it's been created.</p>
      <p>Do you wish to continue?</p>
    </div>
  );

  return project && permission.editProject(project.organisation.id) ? (
    <>
      {!!project?.stages?.[0]?.shape && ( // hide button when there's no geometry
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onClick}
        >
          Make project multi-stage
        </button>
      )}
      <ConfirmCancelPromptModal
        show={showPrompt}
        title="Make project multi-stage"
        content={promptContent}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => setShowPrompt(false)}
        onConfirm={makeProjectMultiStage}
      />
      {isSaving && <LoadingModal />}
    </>
  ) : null;
};
