import { Formik, Form } from "formik";
import { Path } from "utils/constants/paths";
import { useDateRangeMapFilter } from "./DateRangeMapFilterProvider";
import { DateRangeField } from "components/form/DateRangeField";
import { buildFormConfig } from "utils/form";
import { FormFieldConfig } from "utils/types/form";
import * as Yup from "yup";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT } from "utils/constants/date";
import "./DateRangeControl.scss";

export const DateRangeControl = () => {
  const { dates, setDates } = useDateRangeMapFilter();

  const durationConfig: FormFieldConfig = {
    name: "duration",
    initialValue: [null, null],
    validationSchema: Yup.array()
      .test(
        "is-date-range",
        "Please select start/end date.",
        (value, _context) => {
          return (
            Array.isArray(value) &&
            value[0] instanceof Date &&
            value[1] instanceof Date
          );
        }
      )
      .required(),
  };

  const { initialValues, validationSchema } = buildFormConfig(durationConfig);

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setDates({
          startDate: values.duration[0],
          endDate: values.duration[1],
        });

        /**
         * Close the project drawer so that a project is no longer selected on
         * the map. This ensures that we don't get a false positive where a
         * project that is outside of the search date range does not remain
         * selected on the map after the Search button is clicked.
         */
        navigate(Path.Index);
      }}
      onReset={() => {
        setDates({ startDate: null, endDate: null });
      }}
    >
      <Form noValidate>
        <div className="d-flex">
          <DateRangeField
            label="Date Range "
            name="duration"
            placeholderText="dd/mm/yyyy - dd/mm/yyyy"
            className="date-range-control"
          />
          <button type="submit" className="btn btn-outline-primary h-100">
            Apply
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <label id="current-viewing">
            Currently viewing:{" "}
            <span className="ms-1">
              {dates?.startDate && dates?.endDate && (
                <>
                  <strong>{format(dates.startDate, DATE_FORMAT)}</strong> to{" "}
                  <strong>{format(dates.endDate, DATE_FORMAT)}</strong>
                </>
              )}
              {!dates?.startDate &&
                !dates?.endDate &&
                "All current and future projects"}
            </span>
          </label>
          {dates?.startDate && dates?.endDate && (
            <button type="reset" className="btn btn-outline-primary">
              Clear
            </button>
          )}
        </div>
      </Form>
    </Formik>
  );
};
