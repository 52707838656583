import { useLayers } from "components/layers/LayersProvider";

export const ProjectPopupSwitch = () => {
  const { showProjectPopup, toggleShowProjectPopup } = useLayers();
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        name="project-popup-switch"
        id="project-popup-switch"
        checked={showProjectPopup}
        onChange={() => toggleShowProjectPopup()}
      />
      <label className="form-check-label" htmlFor="project-popup-switch">
        Show project info on hover
      </label>
    </div>
  );
};
