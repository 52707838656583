import { useState } from "react";

import { ConfirmCancelPromptModal } from "components/modal/PromptModal";
import { useErrorModal } from "components/modal/useErrorModal";
import { ReactComponent as BinIcon } from "assets/other-icons/delete.svg";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { Stage } from "utils/types/django";
import { useProject } from "components/project/ProjectProvider";

export const DeleteStageButton = ({ stage }: { stage: Stage }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { setErrorModal } = useErrorModal();
  const { selectedProject, navigateToStagesTab } = useSelectedProject();
  const { refetch } = useProjectByIdQuery(selectedProject);

  const { deleteStage } = useProject();
  const onConfirm = async () => {
    try {
      setShowPrompt(false);
      await deleteStage(stage);
      navigateToStagesTab();
      refetch();
    } catch (error) {
      setErrorModal({
        title: "Request failed",
        content: "Failed to delete stage.",
      });
    }
  };

  return (
    <>
      <BinIcon
        type="button"
        width={24}
        height={36}
        className="btn-delete"
        onClick={() => setShowPrompt(true)}
        aria-label="Delete stage"
      />
      <ConfirmCancelPromptModal
        show={showPrompt}
        title="Delete stage?"
        content={`Are you sure you wish to delete stage ${stage.id} from this project?`}
        cancelText="Cancel"
        confirmText="Confirm deletion"
        onCancel={() => setShowPrompt(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};
