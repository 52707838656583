import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import {
  usePanels,
  PanelID,
  PanelVisibility,
} from "components/panel/PanelsProvider";
import { PanelWrapper } from "../PanelWrapper";
import { CollapsiblePanelBody } from "../CollapsiblePanelBody";
import {
  useSearchedProjects,
  PointSearchStatus,
} from "components/project/searched-projects/SearchedProjectsContext";
import { ProjectList } from "../Project/ProjectList";
import { SearchResultsButtonsContainer } from "./SearchResultsButtons";

export const SearchResultsInAreaPanel = () => {
  const {
    someSearchPanelsAreOpen,
    setPanelVisibilities,
    isOpen,
    isClosed,
    closePanel,
  } = usePanels();

  const {
    formSearchResults,
    pointSearchResults,
    pointSearchStatus,
    resetPointSearch,
  } = useSearchedProjects();

  const isOnSearchResultsInAreaPath = useMatchPath(Path.SearchResultsAtPoint);

  const navigate = useNavigate();

  const projects = pointSearchResults?.results ?? [];

  useEffect(() => {
    // When no longer on the main search form/results path, ensure that the
    // search results panel is closed since this panel replaces it.
    // Also when navigating to this path, close the project panel if it's open.
    if (
      someSearchPanelsAreOpen &&
      isOnSearchResultsInAreaPath &&
      isClosed(PanelID.SearchResultsInArea)
    ) {
      setPanelVisibilities([
        {
          id: PanelID.SearchForm,
          visibility: PanelVisibility.Collapsed,
        },
        {
          id: PanelID.SearchResults,
          visibility: PanelVisibility.Closed,
        },
        {
          id: PanelID.SearchResultsInArea,
          visibility: PanelVisibility.Expanded,
        },
        {
          id: PanelID.Project,
          visibility: PanelVisibility.Closed,
        },
      ]);
    }
  }, [
    closePanel,
    isOpen,
    setPanelVisibilities,
    isOnSearchResultsInAreaPath,
    someSearchPanelsAreOpen,
    isClosed,
  ]);

  const closeSearchResultsInAreaPanel = (
    newSearchResultsVisibility: PanelVisibility
  ) => {
    /**
     * Since the user can be on the /projects/X/Y route, navigate back
     * to the search route, replace this panel with the original Search
     * Results panel in the specified state and clear the point search since we
     * no longer need to remember this.
     */
    navigate(Path.Search);
    setPanelVisibilities([
      {
        id: PanelID.SearchResultsInArea,
        visibility: PanelVisibility.Closed,
      },
      {
        id: PanelID.SearchResults,
        visibility: newSearchResultsVisibility,
      },
    ]);
    resetPointSearch();
  };

  const panelName = "Search results in area";

  return (
    <div className="d-flex">
      <PanelWrapper isOpen={isOpen(PanelID.SearchResultsInArea)}>
        <CollapsiblePanelBody
          panelId={PanelID.SearchResultsInArea}
          expandedTitle={`${panelName} (${projects.length})`}
          collapsedTitle={panelName}
          isCollapsible={true}
          hideCloseButton={isOpen(PanelID.Project)}
          onClose={() =>
            closeSearchResultsInAreaPanel(
              someSearchPanelsAreOpen
                ? PanelVisibility.Collapsed
                : PanelVisibility.Closed
            )
          }
        >
          <ProjectList
            projects={projects}
            totalProjectsCount={projects.length}
            isAreaSearch={!!pointSearchResults}
          >
            {/**
             * Hide the View All Results button when there are no form results
             * loaded (but show it if a search has been made with 0 results).
             */}
            {formSearchResults &&
              pointSearchResults &&
              pointSearchStatus === PointSearchStatus.Inactive && (
                <SearchResultsButtonsContainer>
                  <button
                    onClick={() =>
                      closeSearchResultsInAreaPanel(PanelVisibility.Expanded)
                    }
                    className="btn btn-outline-primary"
                  >
                    View all results ({formSearchResults?.count})
                  </button>
                </SearchResultsButtonsContainer>
              )}
          </ProjectList>
        </CollapsiblePanelBody>
      </PanelWrapper>
    </div>
  );
};
