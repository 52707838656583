import {
  useSearchedProjects,
  FormSearchStatus,
} from "components/project/searched-projects/SearchedProjectsContext";
import { PaginationContainer } from "components/project/pagination/PaginationContainer";
import { PaginationLoading } from "components/project/pagination/PaginationLoading";
import {
  PaginationNavigation,
  projectsPerPage,
} from "components/project/pagination/PaginationNavigation";

interface ProjectListPaginationProps {
  totalProjectsCount: number;
  currentPageNumber: number;
}

export function ProjectListPagination({
  totalProjectsCount,
  currentPageNumber,
}: ProjectListPaginationProps) {
  const { formSearchStatus, currentFormSearch } = useSearchedProjects();

  const pageCount = Math.ceil(totalProjectsCount / projectsPerPage);

  return (
    <PaginationContainer
      pageCount={pageCount}
      currentPageNumber={currentPageNumber}
    >
      {formSearchStatus === FormSearchStatus.InProgress && (
        <PaginationLoading
          totalProjectsCount={totalProjectsCount}
          currentSearch={currentFormSearch}
        />
      )}
      {formSearchStatus === FormSearchStatus.Inactive && (
        <PaginationNavigation
          pageCount={pageCount}
          currentPageNumber={currentPageNumber}
          currentRouteSlug="search"
        />
      )}
    </PaginationContainer>
  );
}
