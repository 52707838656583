import React from "react";
import { useField, FieldHookConfig } from "formik";
import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { Label } from "./Label";

interface Props {
  label: string;
}

export const TextField = (
  props: Props & FieldHookConfig<string> & React.HTMLProps<HTMLInputElement>
) => {
  const { label, ...rest } = props;
  const [field, meta] = useField(rest);
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;

  return (
    <FieldWrapper>
      <Label
        htmlFor={id}
        label={label}
        required={props.required} // required is used only cosmetically for label
      />
      <input
        {...rest}
        {...field}
        id={id}
        className="form-control"
        type={props.type || "text"}
        value={field.value ?? ""}
        required={false} // Don't want browser default validation
        onChange={(e) => {
          field.onChange(e);
          rest?.onChange && rest.onChange(e);
        }}
      />
      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
