import { useState } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  QueryClientConfig,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import deepmerge from "deepmerge";

// Based on useToasts example in the comment section of
// https://tkdodo.eu/blog/react-query-error-handling
export const QueryProvider = ({
  configOverride,
  children,
}: {
  configOverride?: QueryClientConfig;
  children: React.ReactNode;
}) => {
  const baseConfig: QueryClientConfig = {
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
    queryCache: new QueryCache(),
  };

  // Override for unit test.
  // QueryCache breaks when overridden, so only defaultOptions and logger can be overridden.
  const config: QueryClientConfig = {
    ...baseConfig,
    defaultOptions: deepmerge(
      baseConfig.defaultOptions!,
      configOverride?.defaultOptions ?? {}
    ),
  };
  if (configOverride?.logger) {
    config.logger = configOverride.logger;
  }

  const [queryClient] = useState(() => new QueryClient(config));

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};
