import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";
import { useField } from "formik";

export const fieldName = "reference";
// Matching backend definition in backend.projects.models.ProjectReference.reference
const maxLength = 50;

export const referenceConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string(),
};

export const Reference = () => {
  const [searchByCustomBoundaryField] = useField("searchByCustomBoundary");

  return (
    <TextField
      label="Organisation reference"
      name={fieldName}
      maxLength={maxLength}
      disabled={searchByCustomBoundaryField.value}
    />
  );
};
