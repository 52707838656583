import { useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import { Organisation } from "utils/types/django";
import { DropdownMultipleSelector } from "components/form/DropdownMultipleSelector";
import { ConfirmCancelPromptModal, PromptModal } from "components/modal/PromptModal";
import { useOrganisationsQuery } from "components/fetch/useOrganisationsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { fieldName } from "./ShareProject";

export interface ShareProjectModalsProps {
  show: boolean;
  onCancel: () => void;
}

export const ShareProjectModals = ({
  show,
  onCancel,
}: ShareProjectModalsProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { selectedProject } = useSelectedProject();
  const { data: organisations } = useOrganisationsQuery();
  const { data: project } = useProjectByIdQuery(selectedProject);
  const { submitForm, resetForm } = useFormikContext();
  const [{ value }] = useField(fieldName);

  const handleSubmit = () => {
    setShowConfirmation(false);
    submitForm();
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    resetForm();
    onCancel();
  };

  const confirmationModalContent = useMemo(() => {
    const defaultMessage = "This will share your internal project with the selected organisations, are you sure?";

    const oldOrgs = project?.shared_organisations ?? [];
    const newOrgs = value.map((o: Organisation) => o.name);

    if (newOrgs.length === 0) {
      if (oldOrgs.length === 0) {
        return "You haven't selected any organisations to share this project with, are you sure?";
      }
      return "You have chosen to remove access to this internal project to all external organisations. Are you sure?";
    }

    const hasAddedOrgs = newOrgs.some((name: string) => !oldOrgs.includes(name));
    const hasRemovedOrgs = oldOrgs.some((name: string) => !newOrgs.includes(name)); 

    if (hasAddedOrgs) {
      if (hasRemovedOrgs) {
        return "This will change which organisations your internal project has been shared with, are you sure?";
      }
      return defaultMessage;
    } else if (hasRemovedOrgs) {
      return "You have chosen to remove access to this internal project to some external organisations. Are you sure?";
    }

    // organisations have not changed, just use the default message
    return defaultMessage;
  }, [value, project]);

  if (!project || !organisations) {
    return null;
  }

  return (
    <>
      <PromptModal
        title="Use the functions below to invite organisations to view your project"
        content={
          <DropdownMultipleSelector
            name="sharedOrganisations"
            items={organisations.filter(
              (o: Organisation) => o.id !== project.organisation.id
            )}
            itemToString={(item) => item?.name ?? "N/A"}
            size={10}
          />
        }
        show={show && !showConfirmation}
        cancelText="Cancel"
        onCancel={handleCancel}
        size="xl"
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowConfirmation(true)}
        >
          Confirm
        </button>
      </PromptModal>

      <ConfirmCancelPromptModal
        title="Are you sure?"
        content={confirmationModalContent}
        show={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleSubmit}
      />
    </>
  );
};
