import { Header, SortDirection, flexRender } from "@tanstack/react-table";
import { WatchlistProject } from "components/watchlist/WatchlistContext";

interface WatchlistProjectTableHeaderProps {
  header: Header<WatchlistProject, unknown>;
}

const sortIcons = {
  asc: "▲",
  desc: "▼",
};

export const WatchlistProjectTableHeader = ({
  header,
}: WatchlistProjectTableHeaderProps) => {
  const sorted = header.column.getIsSorted();
  return (
    <th colSpan={header.colSpan}>
      <div
        style={{ cursor: header.column.getCanSort() ? "pointer" : undefined }}
        className="d-flex gap-1 align-items-center user-select-none"
        onClick={header.column.getToggleSortingHandler()}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        {sorted && <small>{sortIcons[sorted as SortDirection]}</small>}
      </div>
    </th>
  );
};
