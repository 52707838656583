import { useField, useFormikContext } from "formik";
import {
  SavedSearchState,
  fieldName,
} from "pages/root/Panel/Search/formFields/SavedSearch";

interface SaveSearchButtonProps {
  text: string;
}

export const SavedSearchButton = ({ text }: SaveSearchButtonProps) => {
  const { errors } = useFormikContext();
  const [, , { setValue: setState }] = useField(fieldName);
  const [, { error: addressError }] = useField("address");

  const onClick = () => {
    const errorFields = Object.keys(errors);

    if (
      errorFields.length === 0 ||
      (errorFields.length === 1 && addressError)
    ) {
      setState(SavedSearchState.Saving);
    }
  };

  return (
    <button
      type="button"
      className="btn btn-outline-primary mt-3"
      onClick={onClick}
    >
      {text}
    </button>
  );
};
