import { ReactComponent as LogoutIcon } from "assets/sidebar-icons/logout.svg";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";

export const Logout = () => {
  const { logout } = useAuth();
  const { setErrorModal } = useErrorModal();
  const onClick = async () => {
    try {
      await logout();
      setErrorModal({
        title: "You have successfully logged out.",
        content: null,
      });
    } catch (error) {
      setErrorModal({
        title: "Logout failed",
        content: "Something went wrong. Please try again later.",
      });
    }
  };

  return (
    <LogoutIcon
      data-testid="logout-icon"
      type="button"
      className="sidebar-icon logout-icon flex-shrink-0"
      onClick={onClick}
    />
  );
};
