import "./SelectedItems.scss";

interface Props {
  selectedItems: any[];
  getSelectedItemProps: any;
  removeSelectedItem: any;
  itemToString: (item: any) => string;
}

export const SelectedItems = ({
  selectedItems,
  getSelectedItemProps,
  removeSelectedItem,
  itemToString,
}: Props) => (
  <div className="mb-1">
    {selectedItems?.length > 0 && (
      <span className="text-small">Selected:&nbsp;</span>
    )}
    {selectedItems?.map((selectedItem, index) => (
      <span
        key={`selected-item-${index}`}
        {...getSelectedItemProps({ selectedItem, index })}
        className="selected-item-badge badge me-2"
      >
        {itemToString(selectedItem)}
        <button
          onClick={(e) => {
            e.stopPropagation();
            removeSelectedItem(selectedItem);
          }}
          type="button"
          className="clear-button ms-1"
        >
          &#10005;
        </button>
      </span>
    ))}
  </div>
);
