import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { ReactComponent as MeasureIcon } from "assets/control-icons/measure-icon.svg";
import L from "leaflet";
import "./leaflet-ruler.css";
import "./leaflet-ruler";

interface MeasureToolArgs {
  color?: string;
  markerRadius?: number;
  lineWeight?: number;
  displayUnit?: string;
  displayDecimals?: number;
  displayScale?: number;
  displayLabel?: string;
  type?: string;
  className?: string;
}

export const MeasureTool = (props: MeasureToolArgs) => {
  const map = useMap();
  const [ruler, setRuler] = useState({});

  const onClick = () => {
    // @ts-ignore
    ruler.toggleMeasure();
  };

  useEffect(() => {
    if (!map) return;

    const opts = {
      circleMarker: {
        color: props?.color ?? "#2B3B4F",
        radius: props?.markerRadius ?? 5,
      },
      lineStyle: {
        color: props?.color ?? "#2B3B4F",
        weight: props?.lineWeight ?? 3,
      },
      lengthUnit: {
        display: props?.displayUnit ?? "km",
        decimal: props?.displayDecimals ?? 3,
        factor: props?.displayScale ?? 1,
        label: props?.displayLabel ?? "",
      },
    };

    // @ts-ignore
    setRuler(L.control.ruler(opts).addTo(map));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <MeasureIcon
      data-testid="measure-icon"
      type={props?.type ?? "button"}
      className={props?.className ?? ""}
      onClick={onClick}
    />
  );
};
