import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectedProjectContext, Id } from "./SelectedProjectContext";
import { useHoveredProject } from "./hovered-project/HoveredProjectContext";

// Map needs to be provided with selected project/stage so this provider is still useful.
// Gets selected project/stage from URL and provides to consumer.
// Responsible for navigation where project/stage ids are involved.
export const SelectedProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const { removeHover } = useHoveredProject();
  const match = /^\/project\/(\d+)(\/stages\/(\d+))?/g.exec(pathname);
  const projectId = Number.isNaN(Number(match?.[1]))
    ? null
    : Number(match?.[1]);
  const stageId = Number.isNaN(Number(match?.[3])) ? null : Number(match?.[3]);
  const isStageTabActive = pathname.includes("stages");

  const [selectedClop, setSelectedClop] = useState(null as Id);
  const [layer, setLayer] = useState("fwv:projects" as string | null);
  // true by default so it does flyTo from a shared link
  const [shouldFlyTo, setShouldFlyTo] = useState(true);

  const selectClop = useCallback((projectId: Id, flyTo: boolean = false) => {
    setSelectedClop(projectId);
    setShouldFlyTo(flyTo);
  }, []);

  const clearSelectedClop = useCallback(() => {
    setSelectedClop(null);
  }, []);

  const navigate = useNavigate();
  const navigateToProject = useCallback(
    (projectId: Id, flyTo: boolean = false, stageId?: Id, layer?: string) => {
      setSelectedClop(null);
      removeHover();
      if (layer) setLayer(layer);
      if (!projectId) return;
      setSelectedClop(null);
      setShouldFlyTo(flyTo);
      navigate(`/project/${projectId}`);
    },
    [navigate, removeHover]
  );

  const navigateToStage = useCallback(
    (stageId: Id, flyTo: boolean = false) => {
      setSelectedClop(null);
      removeHover();
      if (!stageId) return;
      setShouldFlyTo(flyTo);
      navigate(`/project/${projectId}/stages/${stageId}`);
    },
    [navigate, projectId, removeHover]
  );

  const navigateToSummaryTab = useCallback(() => {
    navigate(`/project/${projectId}`);
    setSelectedClop(null);
  }, [navigate, projectId]);

  const navigateToStagesTab = useCallback(() => {
    navigate(`/project/${projectId}/stages`);
    setSelectedClop(null);
  }, [navigate, projectId]);

  const navigateToEditProject = useCallback(() => {
    navigate(`/project/${projectId}/edit`);
  }, [navigate, projectId]);

  const navigateToAddStage = useCallback(() => {
    navigate(`/project/${projectId}/stages/add`);
  }, [navigate, projectId]);

  const navigateToEditStage = useCallback(
    (stageId: Id) => {
      navigate(`/project/${projectId}/stages/${stageId}/edit`);
    },
    [navigate, projectId]
  );

  const value = {
    selectedProject: projectId,
    selectedStage: stageId,
    selectedClop,
    isStageTabActive,
    layer,
    shouldFlyTo,
    setShouldFlyTo,
    selectClop,
    clearSelectedClop,
    navigateToProject,
    navigateToStage,
    navigateToSummaryTab,
    navigateToStagesTab,
    navigateToEditProject,
    navigateToAddStage,
    navigateToEditStage,
  };
  return (
    <SelectedProjectContext.Provider value={value}>
      {children}
    </SelectedProjectContext.Provider>
  );
};
