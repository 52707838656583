import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "phoneNumber";
const maxLength = 20;

export const phoneNumberConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().matches(
    /^[0-9\s]*$/,
    "Please enter a valid phone number."
  ),
};

export const PhoneNumber = () => (
  <TextField label="Phone number" name={fieldName} maxLength={maxLength} />
);
