import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMatchPath } from "utils/path";
import { Path } from "utils/constants/paths";
import { PanelID, usePanels } from "components/panel/PanelsProvider";

export interface NavBarButtonProps {
  component: React.FC<React.SVGProps<SVGSVGElement>>;
  path: Path;
  panelId?: PanelID;
}

export const NavBarButton = ({
  component,
  path,
  panelId,
  ...props
}: NavBarButtonProps & React.SVGProps<SVGSVGElement>) => {
  const navigate = useNavigate();
  const { isOpen, closeAllPanels } = usePanels();
  const { onClick, ...rest } = props;

  const onPath = useMatchPath(path);

  const isActive = useMemo(
    () => (panelId ? isOpen(panelId) : onPath),
    [panelId, onPath, isOpen]
  );
  const Component = component;

  return (
    <Component
      {...rest}
      type="button"
      className={"sidebar-icon" + (isActive ? " active" : "")}
      onClick={(e) => {
        onClick?.(e);

        // Ensure that any open panels are also reset if any are still collapsed:
        closeAllPanels();
        navigate(isActive ? Path.Index : path);
      }}
    />
  );
};
