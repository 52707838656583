import { Formik, Form, useFormikContext } from "formik";
import { Location, locationConfig } from "./formFields/Location";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { buildFormConfig } from "utils/form";
import { GeoJSON, LatLngBounds } from "leaflet";
import { parse } from "wkt";
import { ReactComponent as MagnifyingGlassIcon } from "assets/control-icons/magnifying-glass-solid.svg";
import { useMapToggle } from "components/map/useMapToggle";

interface AddressSchema {
  address: string;
}

const UpdateMapActive = () => {
  const [enableMap] = useMapToggle();
  const { values } = useFormikContext();
  useEffect(() => {
    // turn the map on when dropdown is selected (mouse is on the map but doesn't bubble)
    if ((values as AddressSchema)["address"] !== "") {
      // but when field empty, mouse is not on map
      enableMap();
    }
  }, [values, enableMap]);
  return null;
};

export const LocationControl = () => {
  const { initialValues, validationSchema } = buildFormConfig(locationConfig);
  const [locationBounds, setLocationBounds] = useState<
    LatLngBounds | undefined
  >(undefined);
  const map = useMap();
  useEffect(() => {
    if (locationBounds && locationBounds.isValid()) {
      if (locationBounds.getSouthWest() === locationBounds.getNorthEast()) {
        map.flyTo(locationBounds.getCenter(), 15, { animate: false });
      } else {
        map.flyToBounds(locationBounds, { animate: false });
      }
    }
    setLocationBounds(undefined);
  }, [map, locationBounds]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ location: { wkt } }) => {
        let geojson = new GeoJSON(parse(wkt));
        setLocationBounds(geojson.getBounds());
      }}
    >
      <Form noValidate>
        <div className="d-flex justify-content-center align-items-start gap-1">
          <UpdateMapActive />
          <Location onSelectCompletion={setLocationBounds} showDelete />
          <button
            type="submit"
            className="btn btn-outline-primary btn-magnifying-glass"
          >
            <MagnifyingGlassIcon width={20} height={20} />
          </button>
        </div>
      </Form>
    </Formik>
  );
};
