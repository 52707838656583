import { Accordion } from "components/accordion/Accordion";

export const ChoosingAccordion = () => (
  <Accordion
    id="type-of-project-help"
    title={<div className="fw-600">Not sure what option to choose?</div>}
  >
    <div className="text-small">
      <strong>Create project with stages</strong> – this option allows you to
      add stages to your project, each with their own start/end dates,
      boundaries, description and traffic impacts.
      <br></br>
      <strong>Create project</strong> – this option is for projects which do not
      have separate stages. If things change, you can add stages by editing your
      project later.
    </div>
  </Accordion>
);
