import * as Yup from "yup";
import { useField } from "formik";
import { Link } from "react-router-dom";
import { FormFieldConfig } from "utils/types/form";
import { VisuallyHidden } from "components/layout/VisuallyHidden";
import { Password } from "./Password";
import { RememberMe } from "./RememberMe";

const fieldName = "sso";

export const ssoConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: true,
  validationSchema: Yup.boolean(),
};

export const SingleSignOn = () => {
  const [{ value }] = useField(fieldName);
  return (
    <VisuallyHidden when={value}>
      <Password />
      <div className="d-flex justify-content-between mt-4">
        <RememberMe />
        <Link to="/reset-password">Forgot/reset password</Link>
      </div>
    </VisuallyHidden>
  );
};
