import { Link, Outlet, useParams } from "react-router-dom";

import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { PanelBody } from "../PanelBody";

export const StageValidator = () => {
  const { projectId, stageId } = useParams();
  const { data: project } = useProjectByIdQuery(projectId ?? null);

  // Project should be already validated, so here just wait for fetch
  if (!project || !stageId) return null;
  const stage = project.stages.find((stage) => stage.id.toString() === stageId);

  if (!stage) {
    return (
      <PanelBody>
        <p className="text-danger">
          Error: Can't display stage details. Selected stage (id: {stageId})
          does not exist.
        </p>
        <Link to={`/project/${projectId}/stages`}>Show existing stages</Link>
      </PanelBody>
    );
  }

  return <Outlet />;
};
