import { Modal } from "react-bootstrap";
import { useErrorModal } from "./useErrorModal";
import "./ErrorModal.scss";

export const ErrorModal = () => {
  const { show, title, content, closeErrorModal } = useErrorModal();

  return (
    <Modal
      show={show}
      onHide={closeErrorModal}
      size="lg"
      centered
      scrollable
      contentClassName="p-4"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="error-modal-body">{content}</Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          type="button"
          className="btn btn-primary btn-wide"
          onClick={closeErrorModal}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
