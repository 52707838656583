import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useNavPrompt = () => {
  const navigate = useNavigate();
  const [shouldPrompt, setShouldPrompt] = useState(true);
  const [url, forceNavigate] = useState<string | undefined>(undefined);

  // If `url` is set via `forceNavigate` function, it unblocks the prompt,
  // which will cause subsequent render and runs the `navigate` function.
  useEffect(() => {
    if (!url) return;
    if (shouldPrompt) {
      setShouldPrompt(false);
      return;
    }
    navigate(url);
  }, [navigate, shouldPrompt, url]);

  return {
    shouldPrompt,
    forceNavigate,
  };
};
