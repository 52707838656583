import { useCallback, useMemo } from "react";
import { useField } from "formik";
import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { DropdownMultipleCombobox } from "components/form/DropdownMultipleCombobox";
import { useProjecttypesQuery } from "components/fetch/useProjecttypesQuery";
import { ProjectType as DjangoProjectType } from "utils/types/django";
import { Projecttype } from "components/fetch/useProjecttypesQuery";

const fieldName = "projectType";

const allHorizontal: Projecttype = {
  name: "horizontal_all",
  display_name: "All Horizontal Projects",
  type: DjangoProjectType.horizontal,
};

const allVertical: Projecttype = {
  name: "vertical_all",
  display_name: "All Vertical Projects",
  type: DjangoProjectType.vertical,
};

const allEvents: Projecttype = {
  name: "events_all",
  display_name: "All Planned Events",
  type: DjangoProjectType.events,
};

export const projectTypeConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: [],
  validationSchema: Yup.array().of(Yup.string()),
};

export const ProjectType = ({
  clearCondition,
}: {
  clearCondition?: boolean;
}) => {
  const [field, , helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;

  const { data } = useProjecttypesQuery();

  const types = useMemo(
    () => ({
      horizontal_all:
        data?.filter((item) => item.type === DjangoProjectType.horizontal) ??
        [],
      vertical_all:
        data?.filter((item) => item.type === DjangoProjectType.vertical) ?? [],
      events_all:
        data?.filter((item) => item.type === DjangoProjectType.events) ?? [],
    }),
    [data]
  );

  const items = useMemo(() => {
    const isAllHorizontalSelected = types.horizontal_all
      .map((item) => item.name)
      .every((val) => value.includes(val));
    const isAllVerticalSelected = types.vertical_all
      .map((item) => item.name)
      .every((val) => value.includes(val));
    const isAllEventsSelected = types.events_all
      .map((item) => item.name)
      .every((val) => value.includes(val));

    // Only include "All x projects" option when there are subtypes that are not selected.
    const items: Projecttype[] = [];
    if (!isAllHorizontalSelected) {
      items.push(allHorizontal);
    }
    items.push(...types.horizontal_all);
    if (!isAllVerticalSelected) {
      items.push(allVertical);
    }
    items.push(...types.vertical_all);
    if (!isAllEventsSelected) {
      items.push(allEvents);
    }
    items.push(...types.events_all);
    return items;
  }, [value, types]);

  const itemNames = useMemo(() => items.map((item) => item.name), [items]);

  const itemToString = useCallback(
    (name: string) => {
      const item = items?.find((item) => item.name === name);
      if (!item) return name;
      const noTransform = [
        allHorizontal.name,
        allVertical.name,
        allEvents.name,
      ];
      if (noTransform.includes(name)) {
        return item.display_name;
      }
      return `${item.type.replace(" Construction", "")} - ${item.display_name}`;
    },
    [items]
  );

  // Give offset styling for the subtypes.
  const getCustomClassNames = useCallback(
    (name: string): string => {
      const item = items?.find((item) => item.name === name);
      if (!item) return "";
      const noClassNames = [
        allHorizontal.name,
        allVertical.name,
        allEvents.name,
      ];
      if (noClassNames.includes(name)) {
        return "";
      }
      return "offset";
    },
    [items]
  );

  return (
    <DropdownMultipleCombobox
      label="Infrastructure type"
      name={fieldName}
      items={itemNames}
      itemToString={itemToString}
      getCustomClassNames={getCustomClassNames}
      placeholder="Select type(s)"
      onItemSelected={(selectedItem) => {
        const items = [...value];
        switch (selectedItem) {
          case allHorizontal.name:
          case allVertical.name:
          case allEvents.name:
            for (const item of (types as { [key: string]: Projecttype[] })[
              selectedItem
            ]) {
              if (!items.includes(item.name)) {
                items.push(item.name);
              }
            }
            break;
          default:
            if (!value.includes(selectedItem)) {
              items.push(selectedItem);
            }
            break;
        }
        setValue(items);
      }}
      clearCondition={clearCondition}
    />
  );
};
