import {
  WatchlistProject,
  useWatchlists,
} from "components/watchlist/WatchlistContext";
import { ReactComponent as BinIcon } from "assets/other-icons/delete.svg";

export const RemoveFromWatchlistButton = ({
  project,
}: {
  project: WatchlistProject;
}) => {
  const { removeBookmark } = useWatchlists();
  return (
    <div className="d-flex justify-content-center align-items-center mx-2">
      <BinIcon
        type="button"
        width={24}
        height={36}
        className="btn-delete"
        title="Remove project from watchlist"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        onClick={() => removeBookmark(project.id, true)}
      />
    </div>
  );
};
