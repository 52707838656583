import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import geojson from "geojson";
import {
  featureCollection,
  multiPolygon,
  multiLineString,
  Polygon,
  MultiLineString,
  Position,
  Feature,
  MultiPolygon,
  Properties,
} from "@turf/helpers";
import {
  normaliseCoordinatesToMultiPolygonCompatible,
  convertCoordinatesToMultiLineStringCompatible,
  areNestedCoordinatesPopulated,
} from "utils/geoJson";

// TODO: The type GeoJSON is not very precise. Investigate splitting this out
// into multiple states with more precise types for the specific geometry that
// is allowed in each context. For example, for the SearchByCustomBoundary
// feature we could have seperate states for the shapes used when
// "actively drawing" (polygons) and "already drawn" (line strings) so that
// they're not mixed together.
export type GeoJSON =
  | geojson.FeatureCollection<geojson.GeometryObject, any>
  | null
  | undefined;

export enum DrawMode {
  Inactive = "Inactive",
  CreateStageBoundary = "CreateStageBoundary",
  LoadingSavedSearch = "LoadingSavedSearch",
  SearchByCustomBoundary = "SearchByCustomBoundary",
  LoadingSearchResults = "LoadingSearchResults",
  ViewSearchResults = "ViewSearchResults",
}

interface DrawLayerContextInterface {
  drawMode: DrawMode;
  setDrawMode: React.Dispatch<React.SetStateAction<DrawMode>>;
  geoJson: GeoJSON;
  setGeoJson: React.Dispatch<React.SetStateAction<GeoJSON>>;
  showDrawLayer: boolean;
  isDrawingAllowed: boolean;
  endDrawingSession: () => void;
  combineGeoJsonFeaturesIntoMultiPolygon: (
    geoJsonParam: GeoJSON
  ) => Feature<MultiPolygon, Properties> | null;
  getGeoJsonAsOutlineOnly: (geoJsonParam: GeoJSON) => GeoJSON;
}

export const DrawLayerContext = createContext<DrawLayerContextInterface>(null!);

export const DrawLayerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [drawMode, setDrawMode] = useState(DrawMode.Inactive);

  // This state is reused in many drawing contexts and for search results.

  const [geoJson, setGeoJson] = useState<GeoJSON>(null as GeoJSON);

  /**
   * Only show the draw layer if the user needs to draw or viewed drawn
   * polygons.
   */
  const showDrawLayer: boolean = useMemo(() => {
    return [
      DrawMode.CreateStageBoundary,
      DrawMode.SearchByCustomBoundary,
      DrawMode.ViewSearchResults,
    ].includes(drawMode);
  }, [drawMode]);

  /**
   * Returns true if the drawing tools are available to start creating a polygon
   */
  const isDrawingAllowed: boolean = useMemo(() => {
    return [
      DrawMode.CreateStageBoundary,
      DrawMode.SearchByCustomBoundary,
    ].includes(drawMode);
  }, [drawMode]);

  /**
   * Resets the current drawing session back to what it was when the application
   * was first loaded.
   */
  const endDrawingSession = () => {
    setDrawMode(DrawMode.Inactive);
    setGeoJson(null);
  };

  /**
   * Examines line strings and polygons on the map, returning a combined
   * MultiPolygon.
   **/
  const combineGeoJsonFeaturesIntoMultiPolygon = useCallback(
    (geoJsonParam: GeoJSON): Feature<MultiPolygon, Properties> | null => {
      if (!geoJsonParam || geoJsonParam?.features.length === 0) return null;

      const fc = featureCollection(geoJsonParam.features);

      // Get any multi line strings currently on the map from a previous search:
      const previousCoordinates: Position[][][] = fc.features
        .filter((f) => f.geometry.type === "MultiLineString")
        .map((f) => (f.geometry as MultiLineString).coordinates);

      // Get the newly drawn polygon coordinates from the map:
      const newCoordinates: Position[][][] = fc.features
        .filter((f) => f.geometry.type === "Polygon")
        .map((f) => (f.geometry as Polygon).coordinates);

      /**
       * If there are previous coordinates, combine them with the new ones. We
       * have to be careful how they are combined here, as the type system won't
       * catch an incorrectly nested structure that matches Position[][][]:
       */
      const combinedCoordinates: Position[][][] =
        previousCoordinates.length > 0
          ? newCoordinates.length > 0
            ? [
                newCoordinates[0]
                  .map((nc) => nc)
                  .concat(previousCoordinates[0].map((pc) => pc)),
              ]
            : previousCoordinates
          : newCoordinates;

      const normalisedCoordinates =
        normaliseCoordinatesToMultiPolygonCompatible(combinedCoordinates);

      if (areNestedCoordinatesPopulated(normalisedCoordinates)) {
        return multiPolygon(normalisedCoordinates);
      }
      return null;
    },
    []
  );

  /**
   * When displaying results we should remove the "fill" from geometry. By
   * replacing polygons with line strings, projects can be hovered over/clicked
   * on. Converts, but does not mutate geoJson.
   */
  const getGeoJsonAsOutlineOnly = useCallback(
    (geoJsonParam: GeoJSON): GeoJSON => {
      if (geoJsonParam && (geoJsonParam?.features.length || 0) > 0) {
        const mp = combineGeoJsonFeaturesIntoMultiPolygon(geoJsonParam);

        /**
         * We can't use the multiPolygonToLine function (from the
         * @turf/polygon-to-line package) because it converts the MultiPolygon
         * to multiple line strings, rather than a single MultiLineString.
         *
         * For MultiPolygons, a subset of the coordinates array can be used
         * to generate the equivalent MultiLineString, but how this is
         * structured will depend on the particular MultiPolygon. So, we have to
         * use convertCoordinatesToMultiLineStringCompatible() to ensure that
         * the correct coordinates are passed to multiLineString():
         */
        if (mp) {
          const mls = multiLineString(
            convertCoordinatesToMultiLineStringCompatible(
              mp.geometry.coordinates
            )
          );
          return featureCollection([mls]);
        }
      }
      return null;
    },
    [combineGeoJsonFeaturesIntoMultiPolygon]
  );

  const value = {
    drawMode,
    setDrawMode,
    geoJson,
    setGeoJson,
    showDrawLayer,
    isDrawingAllowed,
    endDrawingSession,
    combineGeoJsonFeaturesIntoMultiPolygon,
    getGeoJsonAsOutlineOnly,
  };

  return (
    <DrawLayerContext.Provider value={value}>
      {children}
    </DrawLayerContext.Provider>
  );
};

export const useDrawLayer = () => {
  return useContext(DrawLayerContext);
};
