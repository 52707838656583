import { ReactComponent as HelpIcon } from "assets/sidebar-icons/help.svg";

export const Help = () => {
  return (
    <a
      href="https://www.forwardworks.org.nz/help"
      target="_blank"
      rel="noopener noreferrer"
    >
      <HelpIcon data-testid="help-icon" className="sidebar-icon" />
    </a>
  );
};
