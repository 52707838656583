import React from "react";

export const LinkButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => (
  <button
    type="button"
    {...props}
    className="btn-link fw-700 text-start bg-transparent border-0 p-0"
  />
);
