import { useMap } from "react-leaflet";
import { ReactComponent as LocateIcon } from "assets/control-icons/centre-on-location-icon.svg";
import { useErrorModal } from "components/modal/useErrorModal";

interface LocateToolProps {
  className: string;
}

export const LocateTool = ({ className }: LocateToolProps) => {
  const map = useMap();
  const { setErrorModal } = useErrorModal();

  const onClick = () => {
    map
      .locate({ setView: true, enableHighAccuracy: true })
      .on("locationerror", (error) => {
        console.error(error);
        setErrorModal({
          title: "Failed to detect your location",
          content: "Something went wrong. Please try again.",
        });
      });
  };

  return (
    <LocateIcon
      data-testid="centre-icon"
      className={className}
      type="button"
      onClick={onClick}
    />
  );
};
