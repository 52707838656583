import { useNavigate } from "react-router-dom";
import { usePermission } from "components/permission/usePermission";
import { PanelID, usePanels } from "components/panel/PanelsProvider";
import { ReactComponent as PlusIcon } from "assets/control-icons/zoom-in-icon.svg";
import { Path } from "utils/constants/paths";

export const CreateProjectButton = () => {
  const navigate = useNavigate();
  const permission = usePermission();
  const { closePanel } = usePanels();

  return permission.addProject() ? (
    <button
      type="button"
      onClick={() => {
        closePanel(PanelID.MyOrganisation);
        navigate(Path.AddProject);
      }}
      className="btn btn-outline-primary btn-add-project"
      data-toggle="tooltip"
      title="Add new project"
    >
      <PlusIcon style={{ width: 21 }} />
    </button>
  ) : null;
};
