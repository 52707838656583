import { useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import {
  ConfirmCancelPromptModal,
  PromptModal,
} from "components/modal/PromptModal";
import {
  SavedSearchName,
  fieldName as savedSearchName,
} from "./SavedSearchName";
import { fieldName as savedSearchWatchlist } from "./SavedSearchWatchlist";
import { useField, useFormikContext } from "formik";
import {
  SavedSearch as SavedSearchData,
  useSavedSearches,
} from "components/project/searched-projects/SavedSearchContext";
import { useSelect } from "downshift";
import { FieldWrapper } from "components/form/FieldWrapper";
import { Label } from "components/form/Label";
import { ChevronDown } from "components/svg/Chevron";
import { ReactComponent as BinIcon } from "assets/other-icons/delete.svg";
import { parse } from "date-fns";
import { DATE_FORMAT } from "utils/constants/date";
import { FormFieldConfig } from "utils/types/form";
import { SavedSearchWatchlist } from "./SavedSearchWatchlist";
import {
  useDrawLayer,
  DrawMode,
  GeoJSON,
} from "components/drawLayer/DrawLayerProvider";
import { parse as parseWKT } from "wkt";
import { featureCollection, multiLineString } from "@turf/helpers";
import { normaliseCoordinatesToMultiLineStringCompatible } from "utils/geoJson";
import { useWatchlists } from "components/watchlist/WatchlistContext";

export enum SavedSearchState {
  None = "none",
  Saving = "saving",
  PromptName = "promptName",
  PromptOverwrite = "promptOverwrite",
  Created = "created",
  Overwritten = "overwritten",
}

export const fieldName = "savedSearch";

export const savedSearchConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: SavedSearchState.None,
  validationSchema: Yup.string().defined(),
};

export const SavedSearch = ({
  clearCondition,
}: {
  clearCondition: boolean;
}) => {
  const [geoJsonFromShape, setGeoJsonFromShape] = useState(null as GeoJSON);
  const [searchToDelete, setSearchToDelete] = useState<SavedSearchData | null>(
    null
  );
  const { setValues, validateForm, submitForm, setFieldError } =
    useFormikContext();
  const { savedSearches, deleteSearch, refreshSearches } = useSavedSearches();
  const { refetchWatchlists } = useWatchlists();

  const [
    { value: savedSearchState },
    { error },
    { setValue: setSavedSearchState },
  ] = useField(fieldName);
  const [{ value: name }, , { setValue: setName }] = useField(savedSearchName);
  const [{ value: watchlist }] = useField(savedSearchWatchlist);

  const itemToString = (item: any) => (item as SavedSearchData)?.name ?? "";

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
    reset,
    selectItem,
  } = useSelect({ items: savedSearches ?? [], itemToString });

  const {
    geoJson,
    setGeoJson,
    drawMode,
    setDrawMode,
    getGeoJsonAsOutlineOnly,
  } = useDrawLayer();

  const onCancel = useCallback(() => {
    setName(selectedItem ? (selectedItem as SavedSearchData).name : "", false);
    setSavedSearchState(SavedSearchState.None);
  }, [selectedItem, setSavedSearchState, setName]);

  const onSave = useCallback(async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setSavedSearchState(SavedSearchState.None);
    } else {
      await submitForm();
    }
  }, [setSavedSearchState, validateForm, submitForm]);

  const onDelete = useCallback(async () => {
    await deleteSearch(searchToDelete!);
    await refreshSearches();
    refetchWatchlists(); // no await as result is not immediately visible
    setSearchToDelete(null);
    reset();
  }, [searchToDelete, deleteSearch, refreshSearches, refetchWatchlists, reset]);

  useEffect(() => {
    if (!selectedItem) return;

    const search = selectedItem as SavedSearchData;

    // If the search contains a shape (shape is truthy), but not a location
    // (location is falsy), we assume that the shape is to be used to set the
    // geoJson (searching via a custom boundary).
    const searchByCustomBoundary =
      (search.location === "" || !search.location) &&
      search.shape !== "" &&
      search.shape !== null;

    setValues({
      address: {
        input: search?.location ?? "",
        wkt: search?.location ? search?.shape ?? "" : "",
      },
      searchByCustomBoundary,
      projectStageId: search?.id_num ? search.id_num.toString() : "",
      projectStageTitle: search?.title ?? "",
      projectDateRange:
        search?.start_date && search?.end_date
          ? [
              parse(search?.start_date, DATE_FORMAT, new Date()),
              parse(search?.end_date, DATE_FORMAT, new Date()),
            ]
          : [null, null],
      reference: search?.reference ?? "",
      contact: search?.contact ?? "",
      organisation: search?.organisations ?? [],
      dateRangeStatus: search?.date_status ?? "",
      projectType: search?.types ?? [],
      completedProjects: !!search?.include_completed,
      savedSearch: SavedSearchState.None,
      savedSearchName: search.name,
    });

    if (searchByCustomBoundary) {
      const parsedShape = parseWKT(search?.shape);
      const normalisedCoordinates =
        normaliseCoordinatesToMultiLineStringCompatible(
          parsedShape.coordinates
        );
      const mls = multiLineString(normalisedCoordinates);
      const fc = featureCollection([mls]);
      setGeoJsonFromShape(fc);

      // Clear existing geometry. This will cause the new saved search geometry
      // to be rendered since the layer count will be set to zero (see logic in
      // DrawLayer.tsx for how is done):
      setGeoJson(null);
      setDrawMode(DrawMode.LoadingSavedSearch);
    }
  }, [
    selectedItem,
    getGeoJsonAsOutlineOnly,
    setDrawMode,
    setGeoJson,
    setValues,
  ]);

  useEffect(() => {
    // Only once we've cleared the existing geoJson do we load new geoJson from
    // the shape. This ensures that geometry from a previous saved search is
    // fully cleared to allow the new geometry to be rendered:
    if (drawMode === DrawMode.LoadingSavedSearch && geoJson === null) {
      setGeoJson(geoJsonFromShape);
      setDrawMode(DrawMode.SearchByCustomBoundary);

      // Clear the geoJsonFromShape now that it has been loaded into the
      // the global geoJson:
      setGeoJsonFromShape(null);
    }
  }, [geoJson, geoJsonFromShape, setDrawMode, setGeoJson, drawMode]);

  useEffect(() => {
    // determine which prompt to show
    if (savedSearchState === SavedSearchState.Saving) {
      setSavedSearchState(
        selectedItem?.name
          ? SavedSearchState.PromptOverwrite
          : SavedSearchState.PromptName,
        false
      );
    }
  }, [savedSearchState, selectedItem, setSavedSearchState]);

  useEffect(() => {
    // return to initial state on error
    error && setSavedSearchState(SavedSearchState.None);
  }, [error, setSavedSearchState]);

  useEffect(() => {
    clearCondition && reset();
  }, [clearCondition, reset]);

  return (
    <>
      <FieldWrapper>
        <Label
          {...getLabelProps()}
          htmlFor={fieldName}
          label="Apply saved search"
        />
        <div className="position-relative">
          <input
            {...getToggleButtonProps()}
            style={{ backgroundColor: "unset" }}
            className="form-control select-input"
            value={itemToString(selectedItem)}
            readOnly
          />
          <button
            {...getToggleButtonProps()}
            aria-label="toggle menu"
            className="form-input-icon-button position-absolute top-50 translate-middle-y px-2"
            type="button"
          >
            <ChevronDown />
          </button>
          <ul
            className="list-group position-absolute w-100"
            {...getMenuProps()}
          >
            {isOpen && (
              <div
                className="border rounded overflow-y-scroll"
                style={{
                  maxHeight: "190px",
                  zIndex: 100, // Just needed something to not go behind other form inputs
                }}
              >
                {savedSearches.length > 0 ? (
                  savedSearches.map((item, index) => (
                    <li
                      className={
                        "p-1 list-group-item" +
                        (highlightedIndex === index ? " active" : "")
                      }
                      key={`saved-search-${item.name}`}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span
                          {...getItemProps({ item, index })}
                          style={{ width: "100%" }}
                        >
                          {itemToString(item)}
                        </span>
                        <BinIcon
                          type="button"
                          width={20}
                          height={26}
                          className="btn-delete"
                          aria-label="Delete item"
                          onClick={() =>
                            setSearchToDelete(item as SavedSearchData)
                          }
                        />
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item text-muted">
                    No matching options
                  </li>
                )}
              </div>
            )}
          </ul>
        </div>
      </FieldWrapper>
      {/* Save confirmation modal */}
      <ConfirmCancelPromptModal
        show={savedSearchState === SavedSearchState.PromptName}
        title="Save this search"
        confirmText="Save"
        content={
          <>
            <SavedSearchName />
            <SavedSearchWatchlist />
          </>
        }
        onCancel={onCancel}
        onConfirm={onSave}
      />
      {/* Overwrite confirmation model */}
      <PromptModal
        show={savedSearchState === SavedSearchState.PromptOverwrite}
        title="Overwrite existing search?"
        content={
          <>
            <span className="text-danger">Caution:</span> Is this saved search
            linked to a watchlist? If you overwrite the search, the watchlist
            will be updated with projects which meet the new criteria. Any
            existing projects which no longer meet the criteria will be removed.
            Would you like to overwrite the search, or create a new saved search
            and watchlist?
          </>
        }
        onCancel={onCancel}
      >
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onSave}
        >
          Overwrite existing search
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            // reset saved search name field
            setName("", false);
            setFieldError(savedSearchName, undefined);
            setSavedSearchState(SavedSearchState.PromptName);
          }}
        >
          Create new search
        </button>
      </PromptModal>
      {/* Created confirmation modal */}
      <PromptModal
        show={savedSearchState === SavedSearchState.Created}
        title="Saved search"
        content={
          watchlist
            ? `Your search has been saved, and a watchlist called '${name}' has been created in the dashboard.`
            : "Your search has been saved."
        }
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            const savedSearch = savedSearches.find(
              (value: SavedSearchData) => value.name === name
            );
            if (savedSearch) {
              selectItem(savedSearch);
            }
          }}
        >
          Ok
        </button>
      </PromptModal>
      {/* Overwritten confirmation modal */}
      <PromptModal
        show={savedSearchState === SavedSearchState.Overwritten}
        title="Saved search"
        content="Saved search and watchlist successfully updated."
      >
        <button type="button" className="btn btn-primary" onClick={onCancel}>
          Ok
        </button>
      </PromptModal>
      {/* Delete confirmation modal */}
      <ConfirmCancelPromptModal
        show={!!searchToDelete}
        title="Delete this saved search?"
        content="Is this saved search linked to a watchlist? If it is, the watchlist will also be deleted. Are you sure you want to delete this search?"
        confirmText="Yes"
        cancelText="Cancel"
        onCancel={() => setSearchToDelete(null)}
        onConfirm={onDelete}
      />
    </>
  );
};
