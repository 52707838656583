import { useState } from "react";
import { Project } from "utils/types/django";
import {
  FormSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { ConfirmCancelPromptModal } from "components/modal/PromptModal";

export const BookmarkAll = () => {
  const [areAllBookmarked, setAreAllBookmarked] = useState(false);
  const [projectIds, setProjectIds] = useState<number[]>([]);
  const { formSearchResults, formSearchStatus } = useSearchedProjects();
  const { addBookmarks, removeBookmarks } = useWatchlists();

  return (
    <>
      {formSearchStatus === FormSearchStatus.Inactive && (
        <button
          type="button"
          className="btn btn-primary"
          title={
            areAllBookmarked
              ? "Remove all search results from Bookmarked Projects watchlist"
              : "Add all search results to Bookmarked Projects watchlist"
          }
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          onClick={async () => {
            const projectIds = formSearchResults?.results.map(
              (p: Project) => p.id
            );
            projectIds && setProjectIds(projectIds);
          }}
        >
          {areAllBookmarked ? "Unbookmark all" : "Bookmark all"}
        </button>
      )}
      <ConfirmCancelPromptModal
        show={projectIds.length > 0 && !areAllBookmarked}
        title="Bookmark All?"
        content="All projects in your search results will be added to your Bookmarked Projects watchlist. Continue?"
        onConfirm={async () => {
          setProjectIds([]);
          await addBookmarks(projectIds);
          setAreAllBookmarked(true);
        }}
        onCancel={() => setProjectIds([])}
      />
      <ConfirmCancelPromptModal
        show={projectIds.length > 0 && areAllBookmarked}
        title="Unbookmark All?"
        content="All projects in your search results which are currently bookmarked will be removed from your Bookmarked Projects watchlist. Continue?"
        onConfirm={async () => {
          setProjectIds([]);
          await removeBookmarks(projectIds);
          setAreAllBookmarked(false);
        }}
        onCancel={() => setProjectIds([])}
      />
    </>
  );
};
