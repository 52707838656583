import * as Yup from "yup";
import { useField } from "formik";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "email";
const maxLength = 100;

export const useEmailConfig = (): FormFieldConfig => {
  const savedEmail = localStorage.getItem(fieldName);
  return {
    name: fieldName,
    initialValue: savedEmail ?? "",
    validationSchema: Yup.string()
      .trim()
      .email("Please enter a valid email address.")
      .required("Please enter an email address."),
  };
};

export const Email = () => {
  const [, , { setValue: setSSO }] = useField("sso");
  return (
    <TextField
      label="Email address"
      name={fieldName}
      maxLength={maxLength}
      required
      onChange={() => setSSO(true)} // hide password field
    />
  );
};
