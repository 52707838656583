import { Project } from "utils/types/django";
import { DateInfo } from "./TableFields/DateInfo";
import { ReactComponent as ContactIcon } from "assets/other-icons/contact.svg";
import { ReactComponent as EmailIcon } from "assets/other-icons/email.svg";
import { ReactComponent as PhoneIcon } from "assets/other-icons/phone.svg";
import { LeftHeaderTable, LeftHeaderRow } from "components/table";
import { ImpactsInfo } from "./TableFields/ImpactsInfo";
import { EMPTY_SIGN } from "utils/constants/ui";

export const ProjectSummaryTable = ({ project }: { project: Project }) => {
  return (
    <>
      <div className="px-2 py-3 gray-bg">
        <LeftHeaderTable>
          <LeftHeaderRow
            header="Organisation"
            data={project.organisation.name}
          />
          <LeftHeaderRow header="Org reference" data={project.reference} />
          <LeftHeaderRow header="Type" data={project.project_type} />
          <LeftHeaderRow
            header="Infrastructure"
            data={project.infrastructure_type}
          />
          <LeftHeaderRow header="State" data={project.state} />
        </LeftHeaderTable>
      </div>

      <div className="px-2 py-3">
        <DateInfo startDate={project.start_date} endDate={project.end_date} />
      </div>
      <div className="px-2 pt-0 pb-3 text-small">
        <div>
          <strong>Details</strong>
        </div>
        {project.details || "No Details"}
        {!project.is_multistage && (
          <ImpactsInfo impacts={project.stages[0].impacts} />
        )}
      </div>
      <div className="px-2 py-3 text-small gray-bg">
        <div className="d-flex align-items-center gap-3 mb-1 contact-row">
          <ContactIcon width={18} height={18} aria-label="Contact" />
          {project.contact?.name || EMPTY_SIGN}
        </div>
        <div className="d-flex align-items-center gap-3 mb-1 contract-row">
          <PhoneIcon width={18} height={18} aria-label="Phone" />
          {project.contact?.phone_num || EMPTY_SIGN}
        </div>
        <div className="d-flex align-items-center gap-3 contact-row">
          <EmailIcon width={18} height={13} aria-label="Email" />
          {project.contact?.email ? (
            <a href={"mailto:" + project.contact.email}>
              {project.contact.email}
            </a>
          ) : (
            <>{EMPTY_SIGN}</>
          )}
        </div>
      </div>
      <div className="px-2 py-3">
        <LeftHeaderTable small>
          <LeftHeaderRow header="Last updated" data={project.last_updated} />
          <LeftHeaderRow header="FWV project ID" data={project.id} />
          <LeftHeaderRow
            header="Project visibility"
            data={
              project.is_internal
                ? "Internal and invited parties only"
                : "All FWV users"
            }
          />
        </LeftHeaderTable>
      </div>
    </>
  );
};
