import { useState } from "react";
import { usePermission } from "components/permission/usePermission";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { ShareProjectButton } from "./ShareProjectButton";
import { ShareProjectFormModal } from "./ShareProjectFormModal";
import { ShareProjectInfoModal } from "./ShareProjectInfoModal";

export const fieldName = "sharedOrganisations";

export const ShareProject = () => {
  const [active, setActive] = useState(false);

  const { editProject } = usePermission();
  const { selectedProject } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  const openModal = () => setActive(true);
  const closeModal = () => setActive(false);

  if (!project?.is_internal) {
    return null;
  }

  const userCanShare = editProject(project.organisation.id);

  return (
    <>
      <ShareProjectButton
        title={
          userCanShare
            ? "Invite other organisations to view this project"
            : "Organisations shared with"
        }
        active={active || project.shared_organisations.length > 0}
        onClick={openModal}
      />
      {userCanShare ? (
        <ShareProjectFormModal
          onSubmit={closeModal}
          onCancel={closeModal}
          show={active}
        />
      ) : (
        <ShareProjectInfoModal
          project={project}
          show={active}
          onCancel={closeModal}
        />
      )}
    </>
  );
};
