import { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { AddressField } from "components/form/AddressField";
import { useField } from "formik";
const fieldName = "address";

export const addressConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: { input: "", wkt: "" },
  validationSchema: Yup.object({
    input: Yup.string(),
    wkt: Yup.string(),
  }),
};

export const Address = ({
  label = "Search by address or area",
  showDelete = false,
  clearCondition,
}: {
  label?: string;
  showDelete?: boolean;
  clearCondition?: boolean;
}) => {
  const [searchByCustomBoundaryField] = useField("searchByCustomBoundary");
  const [searchByCustomBoundary, setSearchByCustomBoundary] = useState(
    searchByCustomBoundaryField.value || false
  );

  // Cached version of searchByCustomBoundary field to prevent re-renders.
  useEffect(() => {
    setSearchByCustomBoundary(searchByCustomBoundaryField.value);
  }, [searchByCustomBoundaryField, setSearchByCustomBoundary]);

  return (
    <AddressField
      label={label}
      name={fieldName}
      placeholder="Type address or area"
      showDelete={showDelete}
      srid="4326"
      showLabel={true}
      clearCondition={clearCondition || searchByCustomBoundary}
      disabled={searchByCustomBoundaryField.value}
    />
  );
};
