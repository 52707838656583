import { Accordion } from "components/accordion/Accordion";

export const TypeOfAccessHelpText = () => (
  <div className="mb-3">
    <Accordion
      id="type-of-access-help"
      title={<div className="fw-600">Not sure what access you need?</div>}
    >
      <p>Business user - You can view all projects and related information</p>
      <p>
        Project planner - You can view all projects, but you can also create and
        manage projects for your organisation
      </p>
    </Accordion>
  </div>
);
