import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "fullName";
const maxLength = 100;

export const fullNameConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please enter your full name."),
};

export const FullName = () => (
  <TextField
    label="Full name"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
