import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { FullName, fullNameConfig } from "./formFields/FullName";
import { Email, emailConfig } from "./formFields/Email";
import { PhoneNumber, phoneNumberConfig } from "./formFields/PhoneNumber";
import { Organisation, useOrganisationConfig } from "./formFields/Organisation";
import {
  NewOrganisation,
  useNewOrganisationConfig,
} from "./formFields/NewOrganisation";
import { JobTitle, jobTitleConfig } from "./formFields/JobTitle";
import { Region, regionConfig } from "./formFields/Region";
import { TypeOfAccess, typeOfAccessConfig } from "./formFields/TypeOfAccess";
import {
  AcceptTermsOfUse,
  acceptTermsOfUseConfig,
} from "./formFields/AcceptTermsOfUse";
import { SubmitButton } from "components/form/SubmitButton";
import { TypeOfAccessHelpText } from "pages/new-account/TypeOfAccessHelpText";
import { LoadingModal } from "components/loading/LoadingModal";
import { useAddUserMutation } from "./useAddUserMutation";

interface NewAccountApplicationFormProps {
  onSuccess: () => void;
}

export const NewAccountApplicationForm = ({
  onSuccess,
}: NewAccountApplicationFormProps) => {
  const organisationConfig = useOrganisationConfig();
  const newOrganisationConfig = useNewOrganisationConfig();
  const { initialValues, validationSchema } = buildFormConfig(
    fullNameConfig,
    emailConfig,
    phoneNumberConfig,
    organisationConfig,
    newOrganisationConfig,
    jobTitleConfig,
    regionConfig,
    typeOfAccessConfig,
    acceptTermsOfUseConfig
  );

  const addUser = useAddUserMutation({ onSuccess });
  const onSubmit = (values: any) => {
    addUser.mutate({
      email: values[emailConfig.name],
      full_name: values[fullNameConfig.name],
      system_role: values[typeOfAccessConfig.name],
      region: values[regionConfig.name],
      organisation:
        values[organisationConfig.name] || values[newOrganisationConfig.name],
      position: values[jobTitleConfig.name],
      phone_num: values[phoneNumberConfig.name],
    });
  };

  return (
    <>
      {addUser.isLoading && <LoadingModal />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <FullName />
            <Email />
            <PhoneNumber />
            <Organisation newOrganisationValue={values.newOrganisation} />
            <NewOrganisation organisationValue={values.organisation} />
            <JobTitle />
            <Region />
            <TypeOfAccess />

            <div className="mt-4">
              <AcceptTermsOfUse />
            </div>

            <TypeOfAccessHelpText />
            <div className="d-flex justify-content-between align-items-baseline">
              <SubmitButton text="Send" />
              <Link to="/login">Return to log in</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
