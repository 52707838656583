import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { Select } from "components/form/Select";

const fieldName = "projectType";

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please select project type."),
};

export const ProjectType = () => {
  const items = ["Horizontal", "Vertical", "Planned Events"];
  return (
    <Select label="Project type" name={fieldName} required>
      <option value=""></option>
      {items?.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </Select>
  );
};
ProjectType.config = config;
