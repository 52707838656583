import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "projectName";
// Matching backend definition in backend.projects.models.Project.title
const maxLength = 150;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please enter project name."),
};

export const ProjectName = () => (
  <TextField
    label="Project name"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
ProjectName.config = config;
