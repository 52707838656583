import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { CancelButton } from "./CancelButton";
import { TrafficImpacts } from "./formFields/TrafficImpacts";
import { StageName } from "./formFields/StageName";
import { StageDuration } from "./formFields/StageDuration";
import { StageDescription } from "./formFields/StageDescription";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { parseDate } from "utils/date";
import { DATE_FORMAT } from "utils/constants/date";
import { Impact, Stage } from "utils/types/django";

export const EditStageForm = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (values: any) => Promise<void>;
}) => {
  const { selectedProject, selectedStage } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  const stage: Stage | undefined = project?.stages.find(
    (s: Stage) => s.id === selectedStage
  );

  const { initialValues, validationSchema } = buildFormConfig(
    { ...StageName.config, initialValue: stage?.title },
    {
      ...StageDuration.config,
      initialValue: [
        parseDate(stage?.start_date ?? "01/01/1970", DATE_FORMAT),
        parseDate(stage?.end_date ?? "01/01/1970", DATE_FORMAT),
      ],
    },
    { ...StageDescription.config, initialValue: stage?.description ?? "" },
    {
      ...TrafficImpacts.config,
      initialValue:
        stage?.impacts.map((impact: Impact) => ({
          id: impact.id,
          type: impact.impact_type,
          timeframe: impact.time_of_effect,
        })) ?? [],
    }
  );

  return project ? (
    <>
      <h3 className="h5">{project?.title}</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form noValidate>
              <div className="mb-3 text-small">
                <div className="d-flex flex-row gap-1">
                  <strong>Project start date:</strong>
                  {project.start_date}
                </div>
                <div className="d-flex flex-row gap-1">
                  <strong>Project end date:</strong>
                  {project.end_date}
                </div>
              </div>
              <hr />

              <h4 className="h6">Stage details</h4>
              <StageName />
              <StageDuration />
              <StageDescription />
              <hr />

              <h4 className="h6">Stage boundary</h4>
              <p>
                Use the top left controls in the map to define the stage
                boundaries.
              </p>
              <hr />

              <TrafficImpacts title="Stage traffic impacts" values={values} />
              <hr />

              <div className="d-flex align-items-baseline justify-content-end gap-3">
                <CancelButton onCancel={onCancel} />
                <button type="submit" className="btn btn-primary mt-3">
                  Save stage
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  ) : null;
};
