import { isValid, format, parse } from "date-fns";
import { DATE_FORMAT } from "./constants/date";
import { Stage } from "./types/django";

export const formatDate = (date: Date, formatStr: string) => {
  return isValid(date) ? format(date, formatStr) : "Invalid date";
};

export const parseDate = (date: string, formatStr: string) => {
  return date ? parse(date, formatStr, new Date()) : "Invalid date";
};

export const durationFromStages = (stages: Stage[]) => [
  new Date(
    Math.min.apply(
      null,
      stages.map((stage: Stage) =>
        parse(stage.start_date, DATE_FORMAT, new Date()).valueOf()
      )
    )
  ),
  new Date(
    Math.max.apply(
      null,
      stages.map((stage: Stage) =>
        parse(stage.end_date, DATE_FORMAT, new Date()).valueOf()
      )
    )
  ),
];
