import { useQueryClient } from "@tanstack/react-query";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { ReactNode, createContext, useContext } from "react";
import { Project, ProjectOverview, Stage } from "utils/types/django";
import { useVectorGrid } from "components/map/VectorGridProvider";
import { replaceText } from "utils/replaceText";
import { useProjecttypesQuery } from "components/fetch/useProjecttypesQuery";

interface ProjectContextInterface {
  createProject: (data: any) => Promise<void>;
  partialUpdateProject: (project: Project, data: any) => Promise<void>;
  updateProject: (project: Project, data: any) => Promise<void>;
  deleteProject: (project: Project) => Promise<void>;
  createStage: (data: any) => Promise<void>;
  updateStage: (stageId: number, data: any) => Promise<void>;
  deleteStage: (stage: Stage) => Promise<void>;
}

export const ProjectContext = createContext<ProjectContextInterface>(null!);

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const { postDjangoApi, putDjangoApi, deleteDjangoApi } = useDjangoApi();
  const { data: projectTypes } = useProjecttypesQuery();
  const queryClient = useQueryClient();
  const { refreshVectorGrid } = useVectorGrid();

  const invalidateRelatedProjects = (project: Pick<Project, "stages">) => {
    project.stages
      .reduce(
        // collect all related project IDs from clashes/opportunities
        (projectIds: number[], stage: Stage) => [
          ...projectIds,
          ...stage.clashes.map((project: ProjectOverview) => project.id),
          ...stage.opportunities.map((project: ProjectOverview) => project.id),
        ],
        []
      )
      .forEach(
        // invalidate cache for each affected project
        (projectId: number) => {
          queryClient.invalidateQueries([
            "django",
            `/api/projects/${projectId}/`,
          ]);
        }
      );
  };

  // TODO: getProject could be defined here as a replacement for useProjectByIdQuery
  const createProject = async (data: any) => {
    const project: Project | undefined = await postDjangoApi(
      "/api/projects/",
      data
    );

    if (project) {
      invalidateRelatedProjects(project);
      refreshVectorGrid();
    }
  };

  const partialUpdateProject = async (project: Project, data: any) => {
    const updatedProject: Project | undefined = await putDjangoApi(
      `/api/projects/${project.id}/`,
      {
        title: project.title,
        details: project.details,
        reference: project.reference,
        infrastructure_type: replaceText(
          project.infrastructure_type,
          projectTypes!.map((type) => ({
            from: type.display_name,
            to: type.name,
          }))
        ),
        start_date: project.start_date,
        end_date: project.end_date,
        contact: project.contact,
        stages: project.stages,
        is_multistage: project.is_multistage,
        ...data,
      }
    );

    if (updatedProject) {
      invalidateRelatedProjects(project);
      refreshVectorGrid();
    }
  };

  const updateProject = async (project: Project, data: any) => {
    const updatedProject: Project | undefined = await putDjangoApi(
      `/api/projects/${project.id}/`,
      data
    );

    if (updatedProject) {
      invalidateRelatedProjects(project);
      refreshVectorGrid();
    }
  };

  const deleteProject = async (project: Project) => {
    await deleteDjangoApi(`/api/projects/${project.id}/`);
    invalidateRelatedProjects(project);
    refreshVectorGrid();
  };

  const createStage = async (data: any) => {
    const stage: Stage | undefined = await postDjangoApi("/api/stages/", data);

    if (stage) {
      invalidateRelatedProjects({ stages: [stage] });
      refreshVectorGrid();
    }
  };

  const updateStage = async (stageId: number, data: any) => {
    const stage: Stage | undefined = await putDjangoApi(
      `/api/stages/${stageId}/`,
      data
    );

    if (stage) {
      invalidateRelatedProjects({ stages: [stage] });
      refreshVectorGrid();
    }
  };

  const deleteStage = async (stage: Stage) => {
    await deleteDjangoApi(`/api/stages/${stage.id}/`);
    invalidateRelatedProjects({ stages: [stage] });
    refreshVectorGrid();
  };

  const value = {
    createProject,
    partialUpdateProject,
    updateProject,
    deleteProject,
    createStage,
    updateStage,
    deleteStage,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export const useProject = () => useContext(ProjectContext);
