import { format } from "date-fns";
import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { CancelButton } from "./CancelButton";
import { DATE_FORMAT } from "utils/constants/date";
import { TrafficImpacts } from "./formFields/TrafficImpacts";
import { StageName } from "./formFields/StageName";
import { StageDuration } from "./formFields/StageDuration";
import { StageDescription } from "./formFields/StageDescription";

const StageDetails = () => {
  return (
    <div>
      <h4 className="h6">Stage details</h4>
      <StageName />
      <StageDuration />
      <StageDescription />
      <hr />
    </div>
  );
};

export const ProjectDetailForm = ({
  pageValues,
  onBack,
  onSubmit,
  isMultiStage,
}: {
  pageValues: any[];
  onBack: (values: any) => void;
  onSubmit: (values: any) => Promise<void>;
  isMultiStage: boolean;
}) => {
  const projectName = pageValues?.[0]?.projectName;
  const { initialValues, validationSchema } = buildFormConfig(
    {
      ...StageName.config,
      initialValue: pageValues?.[1]?.stageName ?? projectName,
    },
    {
      ...StageDuration.config,
      initialValue: pageValues?.[1]?.stageDuration ??
        pageValues?.[0]?.projectDuration ?? [null, null],
    },
    {
      ...StageDescription.config,
      initialValue: pageValues?.[1]?.stageDescription ?? "",
    },
    {
      ...TrafficImpacts.config,
      initialValue:
        pageValues?.[1]?.trafficImpacts ?? TrafficImpacts.config.initialValue,
    }
  );

  return (
    <>
      <h3 className="h5">{projectName}</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values }) => {
          const title = isMultiStage ? "Stage" : "Project";
          const [projectStartDate, projectEndDate] = pageValues?.[0]
            ?.projectDuration ?? [null, null];

          return (
            <Form noValidate>
              <div className="mb-3 text-small">
                <div className="d-flex flex-row gap-1">
                  <strong>Project start date:</strong>
                  {projectStartDate && format(projectStartDate, DATE_FORMAT)}
                </div>
                <div className="d-flex flex-row gap-1">
                  <strong>Project end date:</strong>
                  {projectEndDate && format(projectEndDate, DATE_FORMAT)}
                </div>
              </div>

              <p>
                Use the tools below to add additional details to your project.
                Adding at least one boundary is required. However, traffic
                impacts are optional.
              </p>
              <hr />

              {isMultiStage && <StageDetails />}

              <div>
                <h4 className="h6">{title} boundary</h4>
                <p>
                  Use the top left controls in the map to define the{" "}
                  {title.toLowerCase()} boundaries.
                </p>
              </div>
              <hr />

              <TrafficImpacts
                title={
                  isMultiStage ? "Stage traffic impacts" : "Traffic impacts"
                }
                values={values}
              />
              <hr />

              <div className="d-flex align-items-baseline justify-content-end gap-3">
                <CancelButton />
                <button
                  type="button"
                  onClick={() => onBack(values)}
                  className="btn btn-outline-primary mt-3"
                >
                  Back
                </button>
                <button type="submit" className="btn btn-primary mt-3">
                  Save project
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
