import { createContext } from "react";

export type Id = number | null;

interface SelectedProjectContextInterface {
  selectedProject: Id;
  selectedStage: Id;
  selectedClop: Id;
  isStageTabActive: boolean;
  layer: string | null;
  shouldFlyTo: boolean;
  setShouldFlyTo: React.Dispatch<React.SetStateAction<boolean>>;
  selectClop: (projectId: Id, flyTo?: boolean) => void;
  clearSelectedClop: () => void;
  navigateToProject: (projectId: Id, flyTo?: boolean) => void;
  navigateToStage: (stageId: Id, flyTo?: boolean) => void;
  navigateToSummaryTab: () => void;
  navigateToStagesTab: () => void;
  navigateToEditProject: () => void;
  navigateToAddStage: () => void;
  navigateToEditStage: (stageId: Id) => void;
}

export const SelectedProjectContext =
  createContext<SelectedProjectContextInterface>(null!);
