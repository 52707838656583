import { useSelectedProject } from "components/project/useSelectedProject";
import { ReactComponent as EditIcon } from "assets/other-icons/edit.svg";
import { usePermission } from "components/permission/usePermission";
import { usePanels, PanelID } from "components/panel/PanelsProvider";

export const EditProjectButton = ({
  organisationId,
}: {
  organisationId: number;
}) => {
  const { navigateToEditProject } = useSelectedProject();
  const permission = usePermission();
  const { closePanel } = usePanels();

  return permission.editProject(organisationId) ? (
    <EditIcon
      type="button"
      width={20}
      height={36}
      className="btn-edit"
      onClick={() => {
        navigateToEditProject();
        closePanel(PanelID.Project);
      }}
      aria-label="Edit project"
    />
  ) : null;
};
