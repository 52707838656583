import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { Select } from "components/form/Select";
import { useRolesQuery } from "components/fetch/useRolesQuery";

export const fieldName = "typeOfAccess";

export const typeOfAccessConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please select a type of access."),
};

export const TypeOfAccess = () => {
  const { data: roles } = useRolesQuery();
  // Backend takes role name, so set the value to role.name
  return (
    <Select label="Select type of access" name={fieldName} required>
      <option value=""></option>
      {roles?.map((role) => (
        <option key={role.id} value={role.name}>
          {role.name}
        </option>
      ))}
    </Select>
  );
};
