import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";
import { useOrganisationNames } from "components/fetch/useOrganisationsQuery";

export const fieldName = "newOrganisation";
const maxLength = 100;

export const useNewOrganisationConfig = (): FormFieldConfig => {
  const organisationNames = useOrganisationNames();
  return {
    name: fieldName,
    initialValue: "",
    validationSchema: Yup.string().when("organisation", {
      is: (organisation: string) => !organisationNames.includes(organisation),
      then: Yup.string().required(
        "Please select an organisation from the list, or enter a new organisation name."
      ),
      otherwise: Yup.string(),
    }),
  };
};

interface NewOrganisationProps {
  organisationValue: string;
}

export const NewOrganisation = ({
  organisationValue,
}: NewOrganisationProps) => {
  const organisationNames = useOrganisationNames();
  return (
    <TextField
      label="Can't find your organisation on the list? Enter it here."
      name={fieldName}
      maxLength={maxLength}
      disabled={organisationNames.includes(organisationValue)}
      required
    />
  );
};
