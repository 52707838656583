import { PanelBody } from "../PanelBody";
import { Accordion } from "components/accordion/Accordion";
import { HorizontalLayers } from "./HorizontalLayers";
import { CompletedHorizontalLayers } from "./CompletedHorizontalLayers";
import { CompletedVerticalLayers } from "./CompletedVerticalLayers";
import { CompletedEventLayers } from "./CompletedEventLayers";
import { VerticalLayers } from "./VerticalLayers";
import { EventLayers } from "./EventLayers";
import { ContextualLayers } from "./ContextualLayers";
import { ProjectPopupSwitch } from "./ProjectPopupSwitch";

export const LayersPanel = () => (
  <PanelBody>
    <Accordion
      id="layer-projects"
      defaultExpanded
      underline={false}
      padding={false}
      title={<h2 className="h3 mb-0">Projects</h2>}
    >
      <HorizontalLayers />
      <VerticalLayers />
      <EventLayers />
    </Accordion>

    <Accordion
      id="layer-completed-projects"
      defaultExpanded
      underline={false}
      padding={false}
      title={<h2 className="h3 mb-0">Completed Projects</h2>}
    >
      <CompletedHorizontalLayers />
      <CompletedVerticalLayers />
      <CompletedEventLayers />
    </Accordion>

    <Accordion
      id="layer-contextual"
      defaultExpanded
      underline={false}
      padding={false}
      title={<h2 className="h3 mb-0">Contextual layers</h2>}
    >
      <ContextualLayers />
    </Accordion>
    <div className="mt-1">
      <ProjectPopupSwitch />
    </div>
  </PanelBody>
);
