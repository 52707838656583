import { ReactComponent as FilterIcon } from "assets/other-icons/filter-solid.svg";
import { accordionId } from "./MyOrganisationFilterContext";

export const FilterButton = () => (
  <button
    type="button"
    className="btn btn-outline-primary btn-filter-projects"
    title="Filter projects"
    data-bs-toggle="collapse"
    data-bs-target={`#${accordionId}`}
    aria-expanded="false"
    aria-controls={accordionId}
    onClick={(e) => e.currentTarget.blur()}
  >
    <FilterIcon style={{ width: 21 }} />
  </button>
);
