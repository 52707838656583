import { LayersControl, TileLayer } from "react-leaflet";
import "./BaseLayerControl.scss";

// TODO: LINZ API key is a temporary one. Replace with a proper one.
export const BaseLayerControl = () => (
  <LayersControl position="topright">
    <LayersControl.BaseLayer checked name="Map">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={19}
      />
    </LayersControl.BaseLayer>
    <LayersControl.BaseLayer name="Aerial">
      <TileLayer
        attribution='&copy; <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> &copy; <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>'
        url="https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png?api=d01eyvkkr9dgqg76ckfx6sb578w"
        maxZoom={19}
      />
    </LayersControl.BaseLayer>
  </LayersControl>
);
