import { PaginationContainer } from "components/project/pagination/PaginationContainer";
import {
  PaginationNavigation,
  projectsPerPage,
} from "components/project/pagination/PaginationNavigation";

interface PublishedProjectsTablePaginationProps {
  totalProjectsCount: number;
  currentPageNumber: number;
}

export function PublishedProjectsTablePagination({
  totalProjectsCount,
  currentPageNumber,
}: PublishedProjectsTablePaginationProps) {
  const pageCount = Math.ceil(totalProjectsCount / projectsPerPage);

  return (
    <PaginationContainer
      pageCount={pageCount}
      currentPageNumber={currentPageNumber}
    >
      <PaginationNavigation
        pageCount={pageCount}
        currentPageNumber={currentPageNumber}
        currentRouteSlug="my-organisation"
      />
    </PaginationContainer>
  );
}
