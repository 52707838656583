export const TabPane = ({
  id,
  children,
  active = false,
}: {
  id: string;
  children: React.ReactNode;
  active?: boolean;
}) => (
  <div
    className={`tab-pane fade ${active ? "show active" : ""}`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
    data-testid={id}
  >
    {children}
  </div>
);
