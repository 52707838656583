import * as Yup from "yup";
import { useField } from "formik";
import { FormFieldConfig } from "utils/types/form";
import { Select } from "components/form/Select";
import { useProjecttypesQuery } from "components/fetch/useProjecttypesQuery";
import { useEffect } from "react";
import { VisuallyHidden } from "components/layout/VisuallyHidden";

const fieldName = "infrastructureType";

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().when("projectType", {
    is: (projectType: string) =>
      ["Horizontal", "Vertical", "Planned Events"].includes(projectType),
    then: Yup.string().required("Please select infrastructure type."),
    otherwise: Yup.string(),
  }),
};

export const InfrastructureType = ({
  projectType,
}: {
  projectType: "Horizontal" | "Vertical" | "Planned Events" | "";
}) => {
  const { data } = useProjecttypesQuery();
  const items = data?.filter((item) => item.type.includes(projectType));

  const [, meta, helpers] = useField(fieldName);
  useEffect(() => {
    if (!items?.find((item) => item.name === meta.value)) {
      helpers.setValue("");
      helpers.setTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  return (
    <VisuallyHidden when={!projectType}>
      <Select label="Infrastructure type" name={fieldName} required>
        <option value=""></option>
        {items?.map((item) => (
          <option key={item.name} value={item.name}>
            {item.display_name}
          </option>
        ))}
      </Select>
    </VisuallyHidden>
  );
};
InfrastructureType.config = config;
