import { useCallback } from "react";

import { Project } from "utils/types/django";
import { CheckStates } from "components/layers/LayersProvider";
import { LayerProperties } from "./VectorGrid";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { DateInterface } from "./map-controls/DateRangeMapFilterProvider";
import { useMyOrganisationsProjectsMapFilter } from "../Panel/MyOrganisation/MyOrganisationsProjectsMapFilterProvider";
import { usePanels, PanelID } from "components/panel/PanelsProvider";

interface VisibilityDataInterface {
  highlightedProject: Project | undefined;
  dates: DateInterface;
  layerVisibility: CheckStates;
}

export const useVisibilityFilter = (
  visibilityData: VisibilityDataInterface
) => {
  const { formSearchResultsIds } = useSearchedProjects();
  const { someAreOpen } = usePanels();
  const { filter: filterByMyOrganisation } =
    useMyOrganisationsProjectsMapFilter();

  const filter = useCallback(
    (properties: LayerProperties): boolean => {
      const isMyOrgProject = filterByMyOrganisation(properties);

      if (isMyOrgProject !== null) {
        return isMyOrgProject;
      }

      // date range filter is set, however the properties dates are not within range
      if (visibilityData.dates.startDate && visibilityData.dates.endDate) {
        if (
          visibilityData.dates.startDate > new Date(properties.end_date) ||
          visibilityData.dates.endDate < new Date(properties.start_date)
        ) {
          return false;
        }
      }

      // in search results view: only show projects in the search results
      if (someAreOpen([PanelID.SearchResults, PanelID.SearchResultsInArea])) {
        return formSearchResultsIds?.includes(properties.project_id) ?? false;
      }

      // project is selected: show
      if (visibilityData.highlightedProject?.id === properties.project_id) {
        return true;
      }

      // layer is visible: show
      if (visibilityData.layerVisibility[properties.infrastructure_type]) {
        return true;
      }

      // all other cases: don't show
      return false;
    },
    [
      filterByMyOrganisation,
      visibilityData.dates.startDate,
      visibilityData.dates.endDate,
      visibilityData.highlightedProject?.id,
      visibilityData.layerVisibility,
      someAreOpen,
      formSearchResultsIds,
    ]
  );

  return filter;
};
