import { useState } from "react";
import {
  LoginPageLayout,
  GenericSideContent,
} from "components/layout/LoginPageLayout";
import { PageTitle } from "components/scaffolding/PageTitle";
import { NewAccountApplicationForm } from "./NewAccountApplicationForm";
import { SuccessMessage } from "./SuccessMessage";

export const NewAccountApplicationPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const title = isSubmitted
    ? "New account requested"
    : "New account application";
  const onSuccess = () => setIsSubmitted(true);
  const sideText = isSubmitted
    ? "Thank you for signing up"
    : "Common good technology to build a better New Zealand";

  return (
    <LoginPageLayout sideCol={<GenericSideContent sideText={sideText} />}>
      <PageTitle title={title} />
      {!isSubmitted && (
        <>
          <p className="fw-600 mb-4">Please enter your details below.</p>
          <NewAccountApplicationForm onSuccess={onSuccess} />
        </>
      )}
      {isSubmitted && <SuccessMessage />}
    </LoginPageLayout>
  );
};
