import React from "react";
import { RequiredStar } from "./RequiredStar";

interface Props {
  htmlFor: string;
  label: React.ReactNode;
  required?: boolean;
  className?: string;
  id?: string;
  hidden?: boolean;
}

export const Label = ({
  htmlFor,
  label,
  required = false,
  className = "fw-700 text-small",
  id = undefined,
  hidden = false,
}: Props) => (
  <label id={id} htmlFor={htmlFor} className={hidden ? "visually-hidden" : ""}>
    <span className={className}>{label}</span>&nbsp;
    {required && <RequiredStar />}
  </label>
);
