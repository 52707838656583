import { createContext, useContext, useState } from "react";

export interface DateInterface {
  startDate: Date | null;
  endDate: Date | null;
}

interface DateRangeMapFilterContextInterface {
  dates: DateInterface;
  setDates: React.Dispatch<React.SetStateAction<DateInterface>>;
}

export const DateRangeMapFilterContext =
  createContext<DateRangeMapFilterContextInterface>(null!);

export const DateRangeMapFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dates, setDates] = useState<DateInterface>({
    startDate: null,
    endDate: null,
  });

  const value = {
    dates,
    setDates,
  };
  return (
    <DateRangeMapFilterContext.Provider value={value}>
      {children}
    </DateRangeMapFilterContext.Provider>
  );
};

export const useDateRangeMapFilter = () => {
  return useContext(DateRangeMapFilterContext);
};
