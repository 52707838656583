import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";

export enum PanelID {
  SearchForm = "SearchForm",
  SearchResults = "SearchResults", // Results from form search
  SearchResultsInArea = "SearchResultsInArea",
  Projects = "Projects", // Projects in area
  Project = "Project",
  AddProject = "AddProject",
  EditProject = "EditProject",
  AddStage = "AddStage",
  EditStage = "EditStage",
  MyOrganisation = "MyOrganisation",
}

/**
 * Note: Although in the panels array, absent represents a closed state, we
 * still need a Closed option that can be used when passing a subset of panels
 * to be updated to this provider.
 */
export enum PanelVisibility {
  Closed = "Closed",
  Collapsed = "Collapsed",
  Expanded = "Expanded",
}

export interface Panel {
  id: PanelID;
  visibility: PanelVisibility;
}

interface PanelsContextType {
  panels: Panel[];

  setPanelVisibilities(panelsToUpdate: Panel[]): void;
  expandPanel(panelId: PanelID): void;
  collapsePanel(panelId: PanelID): void;
  closePanel(panelId: PanelID): void;
  closeRightMostPanel(): void;
  closeAllPanels(): void;
  isExpanded(panelId: PanelID): boolean;
  isCollapsed(panelId: PanelID): boolean;
  isOpen(panelId: PanelID): boolean;
  isClosed(panelId: PanelID): boolean;
  someAreExpanded(panelIds: PanelID[]): boolean;
  someAreCollapsed(panelIds: PanelID[]): boolean;
  someAreOpen(panelIds: PanelID[]): boolean;
  someAreClosed(panelIds: PanelID[]): boolean;
  someSearchPanelsAreOpen: boolean;
}

const PanelsContext = createContext<PanelsContextType>(null!);

export const PanelsProvider = ({ children }: { children: React.ReactNode }) => {
  /**
   * A collection of panels that can be expanded and collapsed (missing from the
   * array means that the panel is closed so that we don't need a seperate
   * sequence tracking array that can become out of sync based on the order that
   * React re-renders in).
   */
  const [panels, setPanels] = useState<Panel[]>([]);

  /**
   * Takes an array of panels containing the new visibility that you want to
   * update them to. This allows us to update multiple panel visibilities at
   * once, useful when a panel should be collapsed when another is expanded.
   */
  const setPanelVisibilities = useCallback(
    (panelsToUpdate: Panel[]): void => {
      const newPanels = [...panels];
      panelsToUpdate.forEach((panelToUpdate) => {
        if (panelToUpdate.visibility === PanelVisibility.Closed) {
          const index = newPanels.findIndex((p) => p.id === panelToUpdate.id);
          if (index !== -1) {
            newPanels.splice(index, 1);
          }
        } else {
          const newPanel = newPanels.find((p) => p.id === panelToUpdate.id);
          if (newPanel) {
            newPanel.visibility = panelToUpdate.visibility;
          } else {
            newPanels.push({
              id: panelToUpdate.id,
              visibility: panelToUpdate.visibility,
            });
          }
        }
      });
      setPanels([...newPanels]);
    },
    [panels]
  );

  const expandPanel = useCallback(
    (panelId: PanelID): void => {
      const newPanels = [...panels];
      const panel = newPanels.find((p) => p.id === panelId);
      if (panel) {
        panel.visibility = PanelVisibility.Expanded;
      } else {
        newPanels.push({ id: panelId, visibility: PanelVisibility.Expanded });
      }
      setPanels([...newPanels]);
    },
    [panels]
  );

  const collapsePanel = useCallback(
    (panelId: PanelID): void => {
      const newPanels = [...panels];
      const panel = newPanels.find((p) => p.id === panelId);
      if (panel) {
        panel.visibility = PanelVisibility.Collapsed;
      } else {
        newPanels.push({ id: panelId, visibility: PanelVisibility.Collapsed });
      }
      setPanels([...newPanels]);
    },
    [panels]
  );

  const closePanel = useCallback(
    (panelId: PanelID): void => {
      const newPanels = [...panels];
      const index = newPanels.findIndex((p) => p.id === panelId);
      if (index !== -1) {
        newPanels.splice(index, 1);
        setPanels([...newPanels]);
      }
    },
    [panels]
  );

  const closeRightMostPanel = useCallback((): void => {
    if (panels.length > 0) {
      const rightMostPanel = panels[panels.length - 1];
      closePanel(rightMostPanel.id);
    }
  }, [closePanel, panels]);

  const closeAllPanels = (): void => setPanels([]);

  const isExpanded = useCallback(
    (panelId: PanelID): boolean =>
      panels.find((p) => p.id === panelId)?.visibility ===
      PanelVisibility.Expanded,
    [panels]
  );

  const isCollapsed = useCallback(
    (panelId: PanelID): boolean =>
      panels.find((p) => p.id === panelId)?.visibility ===
      PanelVisibility.Collapsed,
    [panels]
  );

  const isOpen = useCallback(
    (panelId: PanelID): boolean => isExpanded(panelId) || isCollapsed(panelId),
    [isCollapsed, isExpanded]
  );

  const isClosed = useCallback(
    (panelId: PanelID): boolean => {
      const index = panels.findIndex((p: Panel) => p.id === panelId);
      return index === -1;
    },
    [panels]
  );

  const someAreExpanded = useCallback(
    (panelIds: PanelID[]): boolean =>
      panels
        .filter((p: Panel) => panelIds.includes(p.id))
        .some((p: Panel) => p.visibility === PanelVisibility.Expanded),
    [panels]
  );

  const someAreCollapsed = useCallback(
    (panelIds: PanelID[]): boolean =>
      panels
        .filter((p: Panel) => panelIds.includes(p.id))
        .some((p: Panel) => p.visibility === PanelVisibility.Collapsed),
    [panels]
  );

  const someAreOpen = useCallback(
    (panelIds: PanelID[]): boolean =>
      someAreExpanded(panelIds) || someAreCollapsed(panelIds),
    [someAreCollapsed, someAreExpanded]
  );

  const someAreClosed = useCallback(
    (panelIds: PanelID[]): boolean =>
      panels.some((p: Panel) => !panelIds.includes(p.id)),
    [panels]
  );

  const someSearchPanelsAreOpen: boolean = useMemo(
    () => someAreOpen([PanelID.SearchForm, PanelID.SearchResults]),
    [someAreOpen]
  );

  const value = {
    panels,
    setPanelVisibilities,
    expandPanel,
    collapsePanel,
    closePanel,
    closeRightMostPanel,
    closeAllPanels,
    isExpanded,
    isCollapsed,
    isOpen,
    isClosed,
    someAreExpanded,
    someAreCollapsed,
    someAreOpen,
    someAreClosed,
    someSearchPanelsAreOpen,
  };

  return (
    <PanelsContext.Provider value={value}>{children}</PanelsContext.Provider>
  );
};

export const usePanels = () => {
  return useContext(PanelsContext);
};
