import { compareAsc } from "date-fns";
import { LinkButton } from "components/button/LinkButton";
import { useSelectedProject } from "components/project/useSelectedProject";
import { StageTable } from "./StageTable";
import { Stage } from "utils/types/django";
import { AddStageButton } from "./AddStageButton";
import { ReactComponent as EditIcon } from "assets/other-icons/edit.svg";
import { DeleteStageButton } from "./DeleteStageButton";
import { ClashButton } from "./ClashButton";
import { OpportunitiesButton } from "./OpportunitiesButton";
import {
  ClashOpportunityState,
  useClashOpportunityState,
} from "./ClashOpportunityState";
import { ClashDetails } from "./ClashDetails";
import { OpportunitiesDetails } from "./OpportunitiesDetails";
import "./StageDetails.scss";
import { usePermission } from "components/permission/usePermission";
import { useHoveredProject } from "components/project/hovered-project/HoveredProjectContext";
import { usePanels, PanelID } from "components/panel/PanelsProvider";

const sortStagesStartDateAsc = (a: Stage, b: Stage) =>
  compareAsc(new Date(a.start_date), new Date(b.start_date));

export const StageDetails = ({
  organisationId,
  stages,
}: {
  organisationId: number;
  stages: Stage[];
}) => {
  const [clopState, setClopState] = useClashOpportunityState();
  const { setHoveredStageId, removeHover } = useHoveredProject();
  const {
    selectedStage: selectedStageId,
    navigateToStage,
    navigateToEditStage,
    navigateToStagesTab,
  } = useSelectedProject();
  const permission = usePermission();
  const { closePanel } = usePanels();

  const selectedStage =
    selectedStageId && stages.find((stage) => stage.id === selectedStageId);
  const isMultiStage = stages.length > 1;

  // if stage selected, only show that stage
  const displayStages = selectedStage ? [selectedStage] : stages;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2 stages-title">
        <h3 className="h5 mb-0">Stage details</h3>
        {selectedStage ? (
          <LinkButton
            onClick={() => {
              navigateToStagesTab();
              setClopState(ClashOpportunityState.None);
            }}
          >
            Show all stages
          </LinkButton>
        ) : (
          <AddStageButton />
        )}
      </div>

      {displayStages.sort(sortStagesStartDateAsc).map((stage) => (
        <div key={stage.id} className="mb-3 border rounded">
          <div className="d-flex flex-column align-items-top p-2 pb-3 gray-bg">
            <div className="d-flex flex-row align-items-center justify-content-between">
              {!selectedStage && (
                <LinkButton
                  onClick={() => {
                    setClopState(ClashOpportunityState.None);
                    navigateToStage(stage.id);
                  }}
                  onMouseEnter={() => {
                    setHoveredStageId(stage.id);
                  }}
                  onMouseLeave={removeHover}
                >
                  {stage.title}
                </LinkButton>
              )}
              {!!selectedStage && <div className="fw-700">{stage.title}</div>}
              {permission.editProject(organisationId) && (
                <div className="d-flex gap-2 px-1">
                  <EditIcon
                    type="button"
                    width={20}
                    height={36}
                    className="btn-edit"
                    onClick={() => {
                      navigateToEditStage(stage.id);
                      closePanel(PanelID.Project);
                    }}
                    aria-label="Edit stage"
                  />
                  {isMultiStage && <DeleteStageButton stage={stage} />}
                </div>
              )}
            </div>

            <div className="text-small">
              {stage.description || "No description"}
            </div>
          </div>
          <StageTable stage={stage} />
          <div className="d-flex gap-2 p-2 pt-0 gray-bg">
            <ClashButton
              stageId={stage.id}
              active={
                selectedStage
                  ? clopState === ClashOpportunityState.Clashes
                  : false
              }
              disabled={false}
              onClick={() => {
                setClopState((state: ClashOpportunityState) =>
                  selectedStage && state === ClashOpportunityState.Clashes
                    ? ClashOpportunityState.None
                    : ClashOpportunityState.Clashes
                );
                navigateToStage(stage.id);
              }}
            />

            <OpportunitiesButton
              stageId={stage.id}
              active={
                selectedStage
                  ? clopState === ClashOpportunityState.Opportunities
                  : false
              }
              disabled={false}
              onClick={() => {
                setClopState((state: ClashOpportunityState) =>
                  selectedStage && state === ClashOpportunityState.Opportunities
                    ? ClashOpportunityState.None
                    : ClashOpportunityState.Opportunities
                );
                navigateToStage(stage.id);
              }}
            />
          </div>
        </div>
      ))}
      {clopState === ClashOpportunityState.Clashes && !!selectedStage && (
        <ClashDetails stages={[selectedStage]} />
      )}
      {clopState === ClashOpportunityState.Opportunities && !!selectedStage && (
        <OpportunitiesDetails stages={[selectedStage]} />
      )}
    </>
  );
};
