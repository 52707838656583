import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { saveCoordinates, saveZoom } from "utils/saveLastView";

export const SaveLastView = () => {
  const map = useMap();
  useEffect(() => {
    map.on("moveend", () => {
      saveCoordinates(map.getCenter());
    });
    map.on("zoomend", () => {
      saveZoom(map.getZoom());
    });
  }, [map]);

  return null;
};
