import { useEffect, useMemo } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.vectorgrid";
import {} from "utils/CustomVectorGrid";
import { hatchPattern } from "./CanvasPatterns";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useVectorGrid } from "components/map/VectorGridProvider";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { replaceText } from "utils/replaceText";
import { useProjecttypesQuery } from "components/fetch/useProjecttypesQuery";
import { CheckStates } from "components/layers/LayersProvider";
import { COLOUR, infrastructureTypeColours } from "utils/constants/colours";
import { InfrastructureType, State } from "utils/types/django";
import { useHoveredProject } from "components/project/hovered-project/HoveredProjectContext";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { useProjectPopup } from "./useProjectPopup";
import { useVisibilityFilter } from "./vectorGridHelper";
import { useDateRangeMapFilter } from "./map-controls/DateRangeMapFilterProvider";

import "./VectorGrid.scss";
import { useAuth } from "components/auth/useAuth";

const defaultPathOptions = {
  stroke: true,
  color: COLOUR.DEFAULT,
  weight: 1.5,
  opacity: 1,
  fill: true,
  fillOpacity: 0.4,
};

const highlightPathOptions = {
  ...defaultPathOptions,
  color: COLOUR.PINK,
  fillColor: COLOUR.PINK,
  fillOpacity: 0.6,
};

const intenseHighlightPathOptions = {
  ...defaultPathOptions,
  color: COLOUR.BRIGHTER_PINK,
  fillColor: COLOUR.BRIGHTER_PINK,
  fillOpacity: 0.8,
};

export interface LayerProperties {
  id: number;
  title: string; // stage title
  is_internal: boolean;
  project_id: number;
  project_title: string;
  start_date: string;
  end_date: string;
  infrastructure_type: InfrastructureType;
  state: State;
  org_name: string;
}

interface VectorGridProps {
  layerName: string;
  layerVisibility: CheckStates;
  zIndex: number;
  className?: string;
}

// Styling and mouse events: see https://leaflet.github.io/Leaflet.VectorGrid/vectorgrid-api-docs.html#updating-styles
export const VectorGrid = ({
  layerName,
  layerVisibility,
  zIndex,
  className,
}: VectorGridProps) => {
  const { selectedProject, selectedClop, selectedStage } = useSelectedProject();
  const { hoveredProjectId, hoveredStageId } = useHoveredProject();
  const { formSearchResultsIds, pointSearchResultsIds, pointSearchResults } =
    useSearchedProjects();

  // We run another search after running a form search when clicking on a
  // point - both sets of search results are retained, but the non-form
  // results should take precedence if they are populated.
  const searchedProjectsIds = pointSearchResults
    ? pointSearchResultsIds
    : formSearchResultsIds;

  const { data: hoveredProject } = useProjectByIdQuery(hoveredProjectId);
  const { data: highlightedProject } = useProjectByIdQuery(
    selectedClop || selectedProject
  );
  const { data: projectTypes } = useProjecttypesQuery();
  const { dates } = useDateRangeMapFilter();

  const { authToken } = useAuth();

  const highlightedInfrastructure = useMemo(
    () =>
      replaceText(
        highlightedProject?.infrastructure_type ?? "",
        projectTypes?.map((type) => ({
          from: type.display_name,
          to: type.name,
        })) ?? []
      ) as InfrastructureType,
    [highlightedProject?.infrastructure_type, projectTypes]
  );

  const filter = useVisibilityFilter({
    highlightedProject,
    dates,
    layerVisibility,
  });

  const { nonce } = useVectorGrid();

  const { layerContainer, map } = useLeafletContext();
  const container = layerContainer ?? map;

  const url = `/api/v2/tiles/${layerName}/{z}/{x}/{y}/`;

  const vectorGrid = useMemo(() => {
    return L.vectorGrid.customProtobuf(url, {
      className: className,
      rendererFactory: L.canvas.customTile,
      vectorTileLayerStyles: {
        // https://leaflet.github.io/Leaflet.VectorGrid/vectorgrid-api-docs.html#styling-vectorgrids
        // TODO: Move this logic out of the VectorGrid itself
        projects: (properties: LayerProperties, zoom: number) => {
          const colour =
            infrastructureTypeColours[properties.infrastructure_type];
          return {
            ...defaultPathOptions,
            fillColor: colour,
            color: properties.state === "IN_PROGRESS" ? "black" : colour,
          };
        },
        completed_projects: (properties: LayerProperties, zoom: number) => {
          const colour =
            infrastructureTypeColours[properties.infrastructure_type];
          return {
            ...defaultPathOptions,
            fillColor: colour,
            color: colour,
            pattern: hatchPattern(colour),
          };
        },
      },
      interactive: true,
      getFeatureId: (feature: any) => feature.id,
      zIndex: zIndex,
      filter,
      fetchOptions: {
        headers: authToken
          ? {
              Authorization: `Bearer ${authToken}`,
            }
          : {},
      },
      updateWhenIdle: true, // only load tiles at idle
      updateWhenZooming: false, // disable tile loading during zoom
      updateInterval: 500, // extend tile update interval from 200ms
      keepBuffer: 100, // keep a much larger client tile cache
    });
  }, [url, authToken, zIndex, filter, className]);

  // Change nonce to force refresh vector layer
  useEffect(() => {
    // VectorGrid extends GridLayer which is where this redraw function comes from.
    // "Causes the layer to clear all the tiles and request them again."
    // https://leafletjs.com/reference.html#gridlayer-redraw
    vectorGrid.redraw();
  }, [nonce, vectorGrid]);

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
    };
  }, [container, vectorGrid]);

  // Update filter if highlighted project is not visible
  useEffect(() => {
    // Updates if project would not longer be visible under the infrastructure layers or search results
    const shouldSetFilter =
      highlightedProject &&
      (!layerVisibility[highlightedInfrastructure] ||
        (searchedProjectsIds &&
          !searchedProjectsIds.includes(highlightedProject.id)));
    if (shouldSetFilter) {
      vectorGrid.setFilter(filter);
    }

    // Reset filter
    return () => {
      if (shouldSetFilter) {
        vectorGrid.setFilter(filter);
      }
    };
  }, [
    highlightedInfrastructure,
    highlightedProject,
    searchedProjectsIds,
    layerVisibility,
    vectorGrid,
    filter,
  ]);

  useProjectPopup(vectorGrid);

  // Update styles when selected project/stage is changed
  useEffect(() => {
    const stageIdsOfHighlightedProject =
      highlightedProject?.stages.map((stage) => stage.id) ?? [];
    const stageIdsOfHoveredProject =
      hoveredProject?.stages.map((stage) => stage.id) ?? [];

    const allStageIdsThatNeedHighlighting = [
      ...stageIdsOfHighlightedProject,
      ...stageIdsOfHoveredProject,
    ];

    // Give intense highlighting for a selected stage in selected project
    const highlightedStageId = hoveredStageId || selectedStage;
    const stageIdWithIntenseHighlighting =
      highlightedStageId &&
      stageIdsOfHighlightedProject.includes(highlightedStageId)
        ? highlightedStageId
        : null;

    // ensure stageIdWithIntenseHighlighting is the last element of the array
    // so that it's brought to front last and is always on top when highlighting
    allStageIdsThatNeedHighlighting.sort((stageId, _) =>
      stageId === stageIdWithIntenseHighlighting ? 1 : 0
    );

    const weight = Math.max(Math.round(map.getZoom() / 4), 3);
    const updateStyle = () =>
      allStageIdsThatNeedHighlighting.forEach((stageId: number) => {
        vectorGrid.setFeatureStyle(stageId, {
          ...(stageId === stageIdWithIntenseHighlighting
            ? intenseHighlightPathOptions
            : highlightPathOptions),
          weight,
        });
      });
    updateStyle();

    // Run again after pan/zoom ends to bring the feature to front again.
    const delayedUpdateStyle = () => {
      setTimeout(() => {
        updateStyle();
      }, 250);
    };
    map.on("moveend", delayedUpdateStyle);

    // Cleanup
    return () => {
      allStageIdsThatNeedHighlighting.forEach((stageId: number) => {
        vectorGrid.resetFeatureStyle(stageId);
      });
      map.off("moveend", delayedUpdateStyle);
    };
  }, [
    highlightedProject,
    map,
    hoveredProject,
    hoveredStageId,
    selectedStage,
    vectorGrid,
  ]);

  return null;
};
