import { LoginMainCol } from "./LoginMainCol";
import { LoginSideCol } from "./LoginSideCol";

interface LoginLayoutProps {
  children: React.ReactNode;
  sideCol: React.ReactNode;
}

interface GenericSideContentProps {
  sideText: string;
}

export const LoginPageLayout = ({ children, sideCol }: LoginLayoutProps) => (
  <div className="container-fluid">
    <div className="row">
      <LoginMainCol>{children}</LoginMainCol>
      <LoginSideCol>{sideCol}</LoginSideCol>
    </div>
  </div>
);

export const GenericSideContent = ({ sideText }: GenericSideContentProps) => (
  <div className="login-side-content display-6 d-flex justify-content-center flex-column flex-grow-1 text-center">
    <p>{sideText}</p>
  </div>
);
