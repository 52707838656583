import { DateRangeField } from "components/form/DateRangeField";
import { FormFieldConfig } from "utils/types/form";
import { Select } from "components/form/Select";

export const dateRangeConfig: FormFieldConfig = {
  name: "projectDateRange",
  initialValue: [null, null],
};

export const dateRangeStatusConfig: FormFieldConfig = {
  name: "dateRangeStatus",
  initialValue: "in-progress",
};

const dateRangeStatusOptions = [
  { value: "in-progress", text: "In progress" },
  { value: "starting", text: "Starting" },
  { value: "ending", text: "Ending" },
];

export const ProjectDateRange = () => {
  return (
    <div className="d-flex gap-2">
      <div style={{ flexBasis: "60%" }}>
        <DateRangeField name="projectDateRange" label="Project date range" />
      </div>

      <div style={{ flexBasis: "40%" }}>
        <Select name="dateRangeStatus" label="For projects:">
          {dateRangeStatusOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
