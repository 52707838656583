import { Path } from "utils/constants/paths";
import { NavBarButton } from "./NavBarButton";
import { ReactComponent as DashboardIcon } from "assets/sidebar-icons/dashboard.svg";

const path = Path.Dashboard;

export const Dashboard = () => (
  <NavBarButton
    component={DashboardIcon}
    path={path}
    data-testid="dashboard-icon"
  />
);
