import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { usePermission } from "components/permission/usePermission";
import { usePanels } from "components/panel/PanelsProvider";
import { PanelID } from "components/panel/PanelsProvider";

export const AddStageButton = () => {
  const { selectedProject, navigateToAddStage } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);
  const permission = usePermission();
  const { closePanel } = usePanels();

  return project && permission.editProject(project.organisation.id) ? (
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={() => {
        navigateToAddStage();
        closePanel(PanelID.Project);
      }}
    >
      Add stage
    </button>
  ) : null;
};
