export const TabItem = ({
  paneId,
  children,
  active = false,
  onClick = undefined,
}: {
  paneId: string;
  children: React.ReactNode;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <li className="nav-item">
    <button
      className={`nav-link fw-700 ${active ? "active" : ""}`}
      id={`${paneId}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#${paneId}`}
      type="button"
      role="tab"
      aria-controls={paneId}
      aria-selected={active ? "true" : "false"}
      onClick={onClick}
    >
      {children}
    </button>
  </li>
);
