import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMatchPath } from "utils/path";
import { Path } from "utils/constants/paths";
import { PanelBody } from "../PanelBody";
import { PanelWrapper } from "../PanelWrapper";
import { ProjectList } from "./ProjectList";
import {
  usePanels,
  PanelID,
  PanelVisibility,
} from "components/panel/PanelsProvider";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { useLayers } from "components/layers/LayersProvider";

/**
 * This is the "Projects in area" panel. It is not used when doing a form
 * search; there is now a dedicated "Search results in area" panel for this
 * purpose.
 */
export const ProjectsPanel = () => {
  const { setPanelVisibilities, isOpen, isClosed, closePanel } = usePanels();

  const navigate = useNavigate();
  const { isProjectTypeLayerVisible } = useLayers();

  const { pointSearchResults } = useSearchedProjects();

  const isOnProjectsAtPointPath = useMatchPath(Path.ProjectsAtPoint);

  const projects = useMemo(() => (
    (pointSearchResults?.results || []).filter(isProjectTypeLayerVisible)
  ), [pointSearchResults, isProjectTypeLayerVisible]);

  useEffect(() => {
    if (isOnProjectsAtPointPath && projects.length > 0) {
      // If closed, expand the projects panel to ensure that results can be
      // seen, and collapse the project panel since only one panel is displayed
      // at a time when viewing Projects by area.
      // Also close the Search Form panel since it is possible that it could be
      // open but not submitted.
      if (isClosed(PanelID.Projects)) {
        setPanelVisibilities([
          {
            id: PanelID.SearchForm,
            visibility: PanelVisibility.Closed,
          },
          {
            id: PanelID.Projects,
            visibility: PanelVisibility.Expanded,
          },
          {
            id: PanelID.Project,
            visibility: PanelVisibility.Closed,
          },
          {
            id: PanelID.MyOrganisation,
            visibility: PanelVisibility.Closed,
          },
        ]);
      }
    }
  }, [projects, isOnProjectsAtPointPath, isClosed, setPanelVisibilities]);

  return projects.length > 0 ? (
    <PanelWrapper isOpen={isOpen(PanelID.Projects)}>
      <PanelBody
        hideCloseButton={!isOnProjectsAtPointPath}
        onClose={() => {
          navigate(Path.Index);
          closePanel(PanelID.Projects);
        }}
      >
        <h2 className="h3">{`Projects in area (${projects.length})`}</h2>
        <ProjectList
          projects={projects}
          totalProjectsCount={projects.length}
          isAreaSearch
        />
      </PanelBody>
    </PanelWrapper>
  ) : null;
};
