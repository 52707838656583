import React from "react";
import { useField, FieldHookConfig } from "formik";
import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { Label } from "components/form/Label";

interface Props {
  label: string;
}

export const TextArea = (
  props: Props & FieldHookConfig<string> & React.HTMLProps<HTMLTextAreaElement>
) => {
  const { label, ...rest } = props;
  const [field, meta] = useField(rest);
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;

  return (
    <FieldWrapper>
      <Label
        htmlFor={id}
        label={props.label}
        required={props.required} // required is used only cosmetically for label
      />
      <textarea
        {...rest}
        {...field}
        id={id}
        className="form-control"
        style={{ resize: "vertical" }}
        required={false} // Don't want browser default validation
      />
      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
