import "./LoginSideCol.scss";
import { SideColFooter } from "./SideColFooter";

interface LoginSideColProps {
  children: React.ReactNode;
}

export const LoginSideCol = ({ children }: LoginSideColProps) => (
  <aside className="col-6 min-vh-100 bg-login-side text-white">
    <div className="d-flex flex-column h-100 justify-content-between">
      {children}
      <SideColFooter />
    </div>
  </aside>
);
