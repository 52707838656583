import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";

export const FlyToSearchedProjects = () => {
  const map = useMap();
  const { bounds, isFlyingToBounds } = useSearchedProjects();

  useEffect(() => {
    if (bounds && isFlyingToBounds) {
      try {
        map.flyToBounds(bounds);
      } catch (error: unknown) {}
    }
  }, [map, bounds, isFlyingToBounds]);

  return null;
};
