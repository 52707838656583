import { Formik, Form } from "formik";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { buildFormConfig } from "utils/form";
import { Email, useEmailConfig } from "./formFields/Email";
import { SubmitButton } from "components/form/SubmitButton";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";

interface RequestPasswordResetFormArgs {
  onSubmit: () => void;
}

export const RequestPasswordResetForm = ({
  onSubmit,
}: RequestPasswordResetFormArgs) => {
  const emailConfig = useEmailConfig();

  const { initialValues, validationSchema } = buildFormConfig(emailConfig);

  const { resetPassword } = useAuth();
  const { setErrorModal } = useErrorModal();

  const _onSubmit = async (values: any) => {
    const email = values[emailConfig.name];

    try {
      await resetPassword({ email });
      onSubmit();
    } catch (error) {
      let errorMessage = "Something went wrong. Please try again later.";
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.detail ?? errorMessage;
      }
      setErrorModal({
        title: "Request failed",
        content: errorMessage,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_onSubmit}
    >
      <Form noValidate>
        <Email />

        <div className="d-flex justify-content-between align-items-baseline">
          <SubmitButton text="Submit" />
          <Link to="/login">Return to log in</Link>
        </div>
      </Form>
    </Formik>
  );
};
