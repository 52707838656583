import { PanelBody } from "../PanelBody";
import { PanelWrapper } from "../PanelWrapper";
import { MyWatchlists } from "./MyWatchlists";
import { Watchlist } from "./Watchlist";

export const DashboardPanel = () => (
  <>
    <PanelWrapper isOpen>
      <PanelBody>
        <h2 className="h3">Dashboard</h2>
        <MyWatchlists />
      </PanelBody>
    </PanelWrapper>
    <Watchlist />
  </>
);
