import { useDjangoQuery } from "./useDjangoQuery";

export type Projecttype = {
  name: string;
  display_name: string;
  type: string;
};
type Projecttypes = ReadonlyArray<Projecttype>;

export const useProjecttypesQuery = () =>
  useDjangoQuery<Projecttypes>("/api/projecttypes/");
