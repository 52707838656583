export const COLOUR = {
  DEFAULT: "#fff", // This shouldn't be actually used. If you see this, there's something worng with infrastructure_type.
  ORANGE: "#fe5000",
  YELLOW: "#f6e324",
  GREY: "#767171",
  BLUE: "#00c5ff",
  PURPLE: "#9966ff",
  INDIGO: "#330066",
  TURQUOISE: "#339966",
  PINK: "#ff399c",
  BRIGHTER_PINK: "#ff007f",
  RED_PURPLE: "#c724b1",
  BROWN: "#8c0500",
  RED: "#ff0000",
};

export const infrastructureTypeColours = {
  // horizontal
  electricity: COLOUR.ORANGE,
  gas: COLOUR.YELLOW,
  transport: COLOUR.GREY,
  "three waters": COLOUR.BLUE,
  telecom: COLOUR.PURPLE,
  greenspace: COLOUR.TURQUOISE,

  // vertical
  residential: COLOUR.INDIGO,
  commercial: COLOUR.INDIGO,
  mixed: COLOUR.INDIGO,
  education: COLOUR.INDIGO,
  recreation: COLOUR.INDIGO,
  healthcare: COLOUR.INDIGO,
  government: COLOUR.INDIGO,
  other: COLOUR.INDIGO,

  // events
  events: COLOUR.RED_PURPLE,
  roadworks: COLOUR.RED,
};
