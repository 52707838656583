import { useEffect } from "react";
import Switch from "components/form/Switch";
import { FormFieldConfig } from "utils/types/form";
import { useField, useFormikContext } from "formik";
import "./SearchByCustomBoundary.scss";
import { useDrawLayer, DrawMode } from "components/drawLayer/DrawLayerProvider";
import { fieldName as projectStageIdFieldName } from "./ProjectStageId";
import { fieldName as projectStageTitleFieldName } from "./ProjectStageTitle";
import { fieldName as referenceFieldName } from "./Reference";

const fieldName = "searchByCustomBoundary";

export const searchByCustomBoundaryConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: false,
};

export default function SearchByCustomBoundary() {
  const [, meta] = useField(fieldName);
  const { drawMode, setDrawMode, setGeoJson } = useDrawLayer();

  const formikProps = useFormikContext();
  const setFieldValue = formikProps.setFieldValue;

  useEffect(() => {
    setDrawMode(
      meta.value ? DrawMode.SearchByCustomBoundary : DrawMode.Inactive
    );

    // Clear fields that will be disabled:
    if (meta.value) {
      setFieldValue(projectStageIdFieldName, "");
      setFieldValue(projectStageTitleFieldName, "");
      setFieldValue(referenceFieldName, "");
    }

    // Clear any geometry when the switch is toggled off:
    if (!meta.value) {
      setGeoJson(null);
    }
  }, [
    setFieldValue,
    meta.value,
    setDrawMode,
    formikProps.setFieldValue,
    setGeoJson,
  ]);

  return (
    <div className="search-by-custom-boundary">
      <Switch label="Search by custom boundary" name={fieldName} />
      {drawMode === DrawMode.SearchByCustomBoundary && (
        <p className="gray-bg mb-3 p-2">
          Use the drawing tools to the side of the map to draw a boundary to
          search for projects within. Note - this may mean other search
          parameters will no longer work.
        </p>
      )}
    </div>
  );
}
