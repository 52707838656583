type Props = {
  currentProgress: number;
  completionValue: number;
};

export default function ProgressBar({
  currentProgress,
  completionValue,
}: Props) {
  const currentProgressAsPercentage = Math.round(
    (currentProgress / completionValue) * 100
  );

  return (
    <div
      className="progress"
      role="progressbar"
      aria-label="Loading progress"
      aria-valuenow={currentProgressAsPercentage}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div
        className="progress-bar"
        style={{ width: `${currentProgressAsPercentage}%` }}
      >
        {`${currentProgressAsPercentage}%`}
      </div>
    </div>
  );
}
