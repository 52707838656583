import logo from "assets/logo-circle.png";
import { Dashboard } from "./menu/Dashboard";
import { Search } from "./menu/Search";
import { Layers } from "./menu/Layers";
import { MyProjects } from "./menu/MyProjects";
import { Help } from "./menu/Help";
import { Logout } from "./menu/Logout";
import { Admin } from "./menu/Admin";
import "./Sidebar.scss";

export const SideBar = () => {
  return (
    <div className="sidebar vh-100 p-3 d-flex flex-column justify-content-between">
      <img
        src={logo}
        className="sidebar-logo"
        alt="National Forward Works Viewer logo"
      />

      <ul className="nav flex-column text-center">
        <li className="nav-item mb-3" data-toggle="tooltip" title="Dashboard">
          <Dashboard />
        </li>
        <li className="nav-item mb-3" data-toggle="tooltip" title="Search">
          <Search />
        </li>
        <li className="nav-item mb-3" data-toggle="tooltip" title="Layers">
          <Layers />
        </li>
        <li
          className="nav-item mb-3"
          style={{ height: "40px" }}
          data-toggle="tooltip"
          title="My projects"
        >
          <MyProjects />
        </li>
        <li className="nav-item" data-toggle="tooltip" title="Help">
          <Help />
        </li>
      </ul>

      <ul className="nav flex-column text-center">
        <li className="nav-item" data-toggle="tooltip" title="Admin">
          <Admin />
        </li>
        <li className="nav-item" data-toggle="tooltip" title="Log out">
          <Logout />
        </li>
      </ul>
    </div>
  );
};
