import { useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { ReactComponent as XMarkIcon } from "assets/control-icons/xmark-solid.svg";
import "./CloseButton.scss";

export const CloseButton = ({ onClick }: { onClick?: (() => void) | null }) => {
  const navigate = useNavigate();
  return (
    <XMarkIcon
      type="button"
      className="btn-close-panel shadow rounded-circle p-2"
      title="Close panel"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      onClick={() => (onClick ? onClick() : navigate(Path.Index))}
    />
  );
};
