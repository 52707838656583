import { Outlet, useParams } from "react-router-dom";

import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { PanelBody } from "../PanelBody";
import { LoadingModal } from "components/loading/LoadingModal";

export const ProjectValidator = () => {
  const { projectId } = useParams();
  const { data: project, isLoading } = useProjectByIdQuery(projectId ?? null);

  if (!projectId) {
    return (
      <PanelBody>
        <div className="text-danger">
          Error: Can't display project summary. Project not selected.
        </div>
      </PanelBody>
    );
  }

  // We still show PanelBody, otherwise switching projects causes annoying panel staggering.
  if (isLoading) {
    return (
      <>
        <PanelBody>
          {/* PanelBody requires children but we don't want to show anything hence empty div */}
          <div></div>
        </PanelBody>
        <LoadingModal />
      </>
    );
  }

  if (!project) {
    return (
      <PanelBody>
        <div className="text-danger">
          Error: Can't display project summary. Selected project (id:{" "}
          {projectId}) does not exist.
        </div>
      </PanelBody>
    );
  }

  return <Outlet />;
};
