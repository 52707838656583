import { useDjangoQuery } from "./useDjangoQuery";
import { Organisation } from "utils/types/django";

type Organisations = ReadonlyArray<Organisation>;

export const useOrganisationsQuery = () =>
  useDjangoQuery<Organisations>("/api/organisations/");

export const useOrganisationNames = () => {
  const { data } = useOrganisationsQuery();
  return (
    data
      ?.map((organisation) => organisation.name)
      .sort((a, b) => {
        const aLower = a.toLowerCase();
        const bLower = b.toLowerCase();
        if (aLower < bLower) return -1;
        if (bLower < aLower) return 1;
        return 0;
      }) ?? []
  );
};
