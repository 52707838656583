import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "jobTitle";
const maxLength = 100;

export const jobTitleConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
};

export const JobTitle = () => (
  <TextField label="Job title" name={fieldName} maxLength={maxLength} />
);
