import { Collapse } from "react-bootstrap";
import {
  useMyOrganisationFilter,
  FieldName,
} from "../MyOrganisationFilterContext";

/**
 * Shows a checkbox that represents whether the field is active.
 * On check, marks the field active and shows the field (children).
 * @param param0
 * @returns
 */
export const FieldSwitcher = ({
  fieldName,
  label,
  children,
}: {
  fieldName: FieldName;
  label: string;
  children: React.ReactNode;
}) => {
  const { getIsActive, setIsActive } = useMyOrganisationFilter();
  const isActive = getIsActive(fieldName);
  return (
    <div>
      <label className="form-check-label fw-600">
        <input
          type="checkbox"
          className="form-check-input me-2"
          checked={isActive}
          onChange={(e) => {
            setIsActive(fieldName, e.target.checked);
          }}
        />
        {label}
      </label>
      <Collapse in={isActive}>
        <div className="ms-4">{children}</div>
      </Collapse>
    </div>
  );
};
