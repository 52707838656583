import React from "react";

// Use this when you don't want to unmount component, so that you can keep state (e.g. forms)
export const VisuallyHidden = ({
  when,
  children,
}: {
  when: boolean;
  children: React.ReactNode;
}) => <div className={when ? "d-none" : ""}>{children}</div>;
