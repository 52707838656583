import { useField } from "formik";
import DatePicker from "react-datepicker";
import { Label } from "components/form/Label";
import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { DATE_FORMAT } from "utils/constants/date";
import { format, isValid, parse } from "date-fns";
import "./DateRangeField.scss";

const dayFormats = ["d", "dd"];
const monthFormats = ["M", "MM"];
const yearFormats = ["yy", "yyyy"];
const allowedDateFormats: string[] = [];
dayFormats.forEach((dayFormat) => {
  monthFormats.forEach((monthFormat) => {
    yearFormats.forEach((yearFormat) => {
      allowedDateFormats.push(`${dayFormat}/${monthFormat}/${yearFormat}`);
    });
  });
});

const strictParseDate = (value: string): Date | null => {
  let parsedDate: Date | null = null;
  let strictParsingValueMatch = true;
  allowedDateFormats.forEach((df) => {
    let tryParseDate = parse(value, df, new Date());
    strictParsingValueMatch =
      isValid(tryParseDate) && value === format(tryParseDate, df);
    if (strictParsingValueMatch) {
      parsedDate = tryParseDate;
    }
  });
  return parsedDate;
};

interface Props {
  label: string;
  name: string;
  required?: boolean;
  placeholderText?: string;
  className?: string;
}

type DateRange = [Date | null, Date | null];

export const DateRangeField = (props: Props) => {
  const { label, required, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const id = props.name;
  const isError = meta.touched && meta.error;
  const [startDate, endDate] = field.value;
  const onChange = (update: DateRange) => {
    // Start date must not be empty
    if (!update[0]) return;
    // Set times
    const newStartDate = new Date(update[0]);
    newStartDate.setHours(0, 0, 0, 0);
    let newEndDate = null;
    if (update[1]) {
      newEndDate = new Date(update[1]);
      newEndDate.setHours(23, 59, 59, 0);
    }
    helpers.setValue([newStartDate, newEndDate]);
  };

  // If end date is not selected or end date is before start date, set it end of start date.
  const onCalendarClose = () => {
    let defaultEndDate = null;
    if ((startDate && !endDate) || startDate > endDate) {
      defaultEndDate = new Date(startDate);
      defaultEndDate.setHours(23, 59, 59, 0);
      helpers.setValue([startDate, defaultEndDate]);
    }
  };

  const onChangeRaw = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateInput = event.target.value;
    if (dateInput === undefined) return;
    if (dateInput === "") {
      helpers.setValue([null, null]);
    }
    const dateRange = dateInput.replace(/\s/g, "").split("-");
    // Start date must not be empty
    if (!dateRange[0]) return;
    let newStartDate = strictParseDate(dateRange[0]);
    newStartDate?.setHours(0, 0, 0, 0);
    let newEndDate = null;
    if (dateRange[1]) {
      newEndDate = strictParseDate(dateRange[1]);
      newEndDate?.setHours(23, 59, 59, 0);
    }
    let validStartDate = null;
    let validEndDate = null;
    if (!dateRange[1]) {
      // Only one date is entered.
      // Hacky way to trick datepicker. Without this, when user typed "2022",
      // it sets the start date to 2020 when the first "20" was entered,
      // then 2022 as the end date when the year is fully entered.
      // This hack clears the first date when the user typed "202" so that when
      // "2022" is fully entered it's set as the start date.
      validStartDate = isValid(newStartDate) ? newStartDate : null;
    } else {
      validStartDate = isValid(newStartDate) ? newStartDate : startDate;
      validEndDate = isValid(newEndDate) ? newEndDate : endDate;
    }
    helpers.setValue([validStartDate, validEndDate]);
  };

  return (
    <FieldWrapper>
      <div className={props.className}>
        <Label htmlFor={id} label={props.label} required={props.required} />
        <DatePicker
          {...rest}
          {...field}
          selectsRange
          onChange={onChange}
          onChangeRaw={(e) => onChangeRaw(e)}
          startDate={startDate}
          endDate={endDate}
          id={id}
          autoComplete="off"
          onCalendarClose={onCalendarClose}
          className="form-control"
          // First format becomes the display format, hence explicitly put DATE_FORMAT first
          dateFormat={[DATE_FORMAT, ...allowedDateFormats]}
          placeholderText={
            props.placeholderText ? props.placeholderText : "DD/MM/YY-DD/MM/YY"
          }
          strictParsing
        />
        {isError && <FieldError>{meta.error}</FieldError>}
      </div>
    </FieldWrapper>
  );
};
