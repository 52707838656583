import { useCallback, useEffect, useState } from "react";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { ReactComponent as BookmarkIcon } from "assets/other-icons/bookmark.svg";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { useErrorModal } from "components/modal/useErrorModal";

export const BookmarkProjectButton = () => {
  const { selectedProject } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);
  const { addBookmark, removeBookmark } = useWatchlists();
  const { setErrorModal } = useErrorModal();

  const [isActive, setIsActive] = useState(project?.is_bookmarked ?? false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => project && setIsActive(project.is_bookmarked), [project]);

  const toggle = useCallback(async () => {
    if (!project) {
      setErrorModal({
        title: "Bookmark failed",
        content: "Failed to bookmark project.",
      });
      return;
    } else if (isActive) {
      await removeBookmark(project.id);
    } else {
      await addBookmark(project.id);
    }
  }, [project, isActive, addBookmark, removeBookmark, setErrorModal]);

  return project && project.state.toUpperCase() !== "COMPLETE" ? (
    <button
      type="button"
      className="btn btn-outline-primary btn-bookmark-project"
      title={isActive ? "Remove from bookmarks" : "Bookmark"}
      aria-pressed={isActive}
      onClick={(e) => {
        setIsActive((active) => !active);
        setIsLoading(true);
        e.currentTarget.blur();
        toggle().finally(() => setIsLoading(false));
      }}
      disabled={isLoading}
    >
      <BookmarkIcon width={21} height={20} />
    </button>
  ) : null;
};
