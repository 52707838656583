import { ReactComponent as MapToolsIcon } from "assets/control-icons/secondary-functions-icon.svg";
import { MeasureTool } from "./measure/MeasureTool";
import { LocateTool } from "./LocateTool";
import "./MapTools.scss";

const baseClasses = "map-tools-icon m-2 rounded-2";
const hiddenIconClasses = baseClasses + " hidden-icon";

export const MapTools = () => (
  <div className="map-tools shadow m-3 rounded-2">
    <MeasureTool type="button" className={hiddenIconClasses} />
    <LocateTool className={hiddenIconClasses} />
    <MapToolsIcon data-testid="map-tools-icon" className={baseClasses} />
  </div>
);
