interface FieldWrapperProps {
  noWrapper?: boolean;
  children: React.ReactNode;
}

export const FieldWrapper = ({
  noWrapper = false,
  children,
}: FieldWrapperProps) =>
  noWrapper ? (
    <div className="w-100">{children}</div>
  ) : (
    <div className="mb-2 w-100">{children}</div>
  );
