import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "contactPhone";
const maxLength = 15;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string()
    .matches(/^[\d\s+]*$/, "Please enter a valid phone number.")
    .required("Please enter a phone number."),
};

export const ContactPhone = () => (
  <TextField
    label="Contact phone number"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
ContactPhone.config = config;
