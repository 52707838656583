import { EMPTY_SIGN } from "utils/constants/ui";

export const LeftHeaderRow = ({
  header,
  data,
}: {
  header: React.ReactNode;
  data: React.ReactNode;
}) => (
  <tr>
    <th className="align-top pe-1">{header}</th>
    <td className="align-top">
      {data === null || data === "" ? EMPTY_SIGN : data}
    </td>
  </tr>
);
