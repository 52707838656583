import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextArea } from "components/form/TextArea";

const fieldName = "stageDescription";
const maxLength = 200;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string(),
};

export const StageDescription = () => (
  <TextArea label="Stage description" name={fieldName} maxLength={maxLength} />
);
StageDescription.fieldName = fieldName;
StageDescription.config = config;
