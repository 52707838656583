import { useEffect } from "react";
import { useMap } from "react-leaflet";

export const MapResize = () => {
  const map = useMap();
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });
    //React-leaflet does not support ref in MapContainer so need to access DOM directly to access the element for map
    const mapDiv = document.getElementById("map-container");
    if (mapDiv) {
      resizeObserver.observe(mapDiv);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [map]);
  return null;
};
