import { useCallback, createContext, useContext } from "react";
import { useDjangoQuery } from "components/fetch/useDjangoQuery";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { QueryObserverResult } from "@tanstack/react-query";
import { useErrorModal } from "components/modal/useErrorModal";

const API_ENDPOINT = "/api/v2/projects/search/saved/";

export type SavedSearch = {
  name: string;
  id?: number;
  id_num?: number;
  shape?: string;
  location?: string;
  title?: string;
  reference?: string;
  contact?: string;
  organisations?: string[];
  start_date?: string;
  end_date?: string;
  date_status?: "in-progress" | "starting" | "ending";
  types?: string[];
  include_completed?: boolean;
  watchlist?: boolean;
};
type SavedSearches = SavedSearch[];

interface SavedSearchContextInterface {
  savedSearches: SavedSearches;
  getSearch: (search: SavedSearch) => SavedSearch | undefined;
  saveSearch: (search: SavedSearch) => Promise<void>;
  updateSearch: (search: SavedSearch) => Promise<void>;
  deleteSearch: (search: SavedSearch) => Promise<void>;
  refreshSearches: () => Promise<QueryObserverResult<SavedSearches, unknown>>;
}

export const SavedSearchProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setErrorModal } = useErrorModal();
  const { data, refetch } = useDjangoQuery<SavedSearches>(API_ENDPOINT);
  const api = useDjangoApi();

  const onError = useCallback(
    (error?: any) => {
      // only check name or non-field errors
      // form validation should pick up other errors before getting to this stage
      const data = error?.response?.data;
      const message = data?.name
        ? "Please specify a valid search name."
        : data?.non_field_errors?.[0];
      setErrorModal({
        title: "Save failed",
        content:
          message ?? "There was an error saving your search. Please try again.",
      });
      throw error;
    },
    [setErrorModal]
  );

  const getSearch = useCallback(
    (search: SavedSearch) =>
      data?.find((s: SavedSearch) => s.name === search.name),
    [data]
  );

  const getSearchId = useCallback(
    (search: SavedSearch) => search?.id ?? getSearch(search)?.id,
    [getSearch]
  );

  const saveSearch = useCallback(
    async (search: SavedSearch) => {
      try {
        return await api.postDjangoApi(API_ENDPOINT, search);
      } catch (error) {
        onError(error);
      }
    },
    [api, onError]
  );

  const updateSearch = useCallback(
    async (search: SavedSearch) => {
      try {
        return await api.putDjangoApi(
          `${API_ENDPOINT}${getSearchId(search)}/`,
          search
        );
      } catch (error) {
        onError(error);
      }
    },
    [api, getSearchId, onError]
  );

  const deleteSearch = useCallback(
    async (search: SavedSearch) => {
      try {
        return await api.deleteDjangoApi(
          `${API_ENDPOINT}${getSearchId(search)}/`
        );
      } catch (error) {
        onError(error);
      }
    },
    [api, getSearchId, onError]
  );

  const value = {
    savedSearches: (data ?? [])
      .slice()
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    getSearch,
    saveSearch,
    updateSearch,
    deleteSearch,
    refreshSearches: refetch,
  };

  return (
    <SavedSearchContext.Provider value={value}>
      {children}
    </SavedSearchContext.Provider>
  );
};

export const SavedSearchContext = createContext<SavedSearchContextInterface>(
  null!
);

export const useSavedSearches = () => useContext(SavedSearchContext);
