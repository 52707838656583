import { useCallback } from "react";

import { useMyUserQuery } from "components/fetch/useMyUserQuery";
import { SystemRole } from "utils/types/django";

/**
 * Returns functions that return boolean value whether the user has permission.
 */
export const usePermission = () => {
  const { data: me } = useMyUserQuery();

  const addProject = useCallback(
    () =>
      me?.system_role === SystemRole.Superuser ||
      me?.system_role === SystemRole.ProjectPlanner,
    [me]
  );

  const editProject = useCallback(
    (projectOrgId: number) => {
      const isMyOrganisationProject = projectOrgId === me?.organisation.id;
      return (
        me?.system_role === SystemRole.Superuser ||
        (me?.system_role === SystemRole.ProjectPlanner &&
          isMyOrganisationProject)
      );
    },
    [me]
  );

  return {
    role: me?.system_role,
    addProject,
    editProject,
  };
};
