import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { CancelButton } from "./CancelButton";
import { TrafficImpacts } from "./formFields/TrafficImpacts";
import { StageName } from "./formFields/StageName";
import { StageDuration } from "./formFields/StageDuration";
import { StageDescription } from "./formFields/StageDescription";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";

export const CreateStageForm = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (values: any) => Promise<void>;
}) => {
  const { initialValues, validationSchema } = buildFormConfig(
    StageName.config,
    StageDuration.config,
    StageDescription.config,
    TrafficImpacts.config
  );
  const { selectedProject } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  return (
    <>
      <h3 className="h5">{project?.title}</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form noValidate>
              <div className="mb-3 text-small">
                <div className="d-flex flex-row gap-1">
                  <strong>Project start date:</strong>
                  {project?.start_date ?? ""}
                </div>
                <div className="d-flex flex-row gap-1">
                  <strong>Project end date:</strong>
                  {project?.end_date ?? ""}
                </div>
              </div>

              <p>
                Use the tools below to add additional details to your project.
                Adding at least one boundary is required. However, traffic
                impacts are optional.
              </p>
              <hr />

              <h4 className="h6">Stage details</h4>
              <StageName />
              <StageDuration />
              <StageDescription />
              <hr />

              <h4 className="h6">Stage boundary</h4>
              <p>
                Use the top left controls in the map to define the stage
                boundaries.
              </p>
              <hr />

              <TrafficImpacts title="Stage traffic impacts" values={values} />
              <hr />

              <div className="d-flex align-items-baseline justify-content-end gap-3">
                <CancelButton onCancel={onCancel} />
                <button type="submit" className="btn btn-primary mt-3">
                  Save stage
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
