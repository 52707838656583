import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "stageName";
// Matching backend definition in backend.projects.models.Stage.name
const maxLength = 150;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please enter stage name."),
};

export const StageName = () => (
  <TextField
    label="Stage name"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
StageName.fieldName = fieldName;
StageName.config = config;
