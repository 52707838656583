import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";

export const fieldName = "savedSearchWatchlist";

export const savedSearchWatchlistConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: false,
  validationSchema: Yup.boolean(),
};

export const SavedSearchWatchlist = () => (
  <div className="py-2">
    <CheckBox
      label="Create a watchlist from this saved search"
      name={fieldName}
    />
    <div style={{ marginLeft: "25px" }}>
      <small className="fw-300">
        Projects matching your search criteria will automatically be added to a
        watchlist. View the watchlists in your Dashboard.
      </small>
    </div>
  </div>
);
