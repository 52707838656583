import { useEffect } from "react";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { ProjectList } from "../Project/ProjectList";
import React from "react";
import { SearchResultsButtons } from "./SearchResultsButtons";

export const SearchResults = ({
  children,
  setSearchResultsHeading,
}: {
  children?: React.ReactNode | React.ReactNode[];
  setSearchResultsHeading?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { isLoadingFirstPage, currentPageResults } = useSearchedProjects();
  const projects = currentPageResults?.results ?? [];

  const totalProjectsCount = currentPageResults?.count ?? 0;

  useEffect(() => {
    if (setSearchResultsHeading) {
      const resultsCountHeading = isLoadingFirstPage
        ? "Loading results..."
        : `Search results (${totalProjectsCount})`;
      setSearchResultsHeading(resultsCountHeading);
    }
  }, [isLoadingFirstPage, setSearchResultsHeading, totalProjectsCount]);

  if (isLoadingFirstPage || !currentPageResults) {
    return null;
  }

  return (
    <>
      <ProjectList
        projects={projects}
        totalProjectsCount={totalProjectsCount}
        isAreaSearch={false}
      >
        <SearchResultsButtons />
        {children}
        {currentPageResults?.count === 0 && (
          <div>
            There are no results to display. Please widen your search criteria.
          </div>
        )}
      </ProjectList>
    </>
  );
};
