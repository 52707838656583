import { useEffect, useState } from "react";
import { PromptModal } from "components/modal/PromptModal";
import { useMyUserQuery } from "components/fetch/useMyUserQuery";

interface MessageOfTheDayProps {
  enabled: boolean;
  motdKey: string;
  title: string;
  content: React.ReactNode;
}

export const MessageOfTheDay = ({
  enabled,
  motdKey,
  title,
  content,
}: MessageOfTheDayProps) => {
  const storageKey = "motd-" + motdKey;
  const { data: me } = useMyUserQuery();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!me) return;
    setIsVisible(
      enabled && !localStorage.getItem(storageKey)?.includes(me.email)
    );
  }, [enabled, storageKey, me]);

  return (
    <PromptModal
      show={isVisible}
      title={title}
      alignTitle="center"
      size="xl"
      content={content}
      cancelText="Close"
      onCancel={() => {
        if (me) {
          const value = localStorage.getItem(storageKey);
          localStorage.setItem(
            storageKey,
            value ? `${value},${me.email}` : me.email
          );
        }
        setIsVisible(false);
      }}
    />
  );
};
