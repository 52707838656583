import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { LinkButton } from "components/button/LinkButton";
import {
  useNavBlocker,
  NavBlockerControl,
} from "components/navBlocker/useNavBlocker";

export const NavPromptModal = ({
  title,
  content,
  cancelText,
  confirmText,
  enabled = true,
  onConfirm,
}: {
  title: string;
  content: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  enabled?: boolean;
  onConfirm?: () => void;
}) => {
  const [show, setShow] = useState(false);
  const navigationRef = useRef<NavBlockerControl>({
    confirm: () => {},
    cancel: () => {},
  });
  useNavBlocker({
    enabled,
    onBlock: (navigation) => {
      setShow(true);
      navigationRef.current = navigation;
    },
  });

  const handleCancelClick = () => {
    navigationRef.current.cancel();
    setShow(false);
  };

  const handleConfirmClick = () => {
    navigationRef.current.confirm();
    setShow(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCancelClick}
      size="lg"
      centered
      scrollable
      contentClassName="p-4"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="prompt-modal-body">{content}</Modal.Body>
      <Modal.Footer className="justify-content-center gap-2">
        <LinkButton onClick={handleCancelClick}>
          {cancelText || "Cancel"}
        </LinkButton>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleConfirmClick}
        >
          {confirmText || "Confirm"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
