import { useMutation } from "@tanstack/react-query";
import { useErrorModal } from "components/modal/useErrorModal";
import { djangoApi } from "utils/djangoApi";
import { AxiosError } from "axios";

type PostUserData = {
  email: string;
  full_name: string;
  system_role: string;
  region: string;
  organisation: string;
  position: string;
  phone_num: string;
};

const postUser = async (data: PostUserData) => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, value);
  });

  return djangoApi.post("/api/users/", params);
};

type MutationArgs = {
  onSuccess: () => void;
};

export const useAddUserMutation = ({ onSuccess }: MutationArgs) => {
  const { setErrorModal } = useErrorModal();
  return useMutation(postUser, {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      let errorMessage = "Something went wrong. Please try again later.";
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.detail ?? errorMessage;
      }
      setErrorModal({
        title: "Request failed",
        content: errorMessage,
      });
    },
  });
};
