import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useMyUserQuery } from "components/fetch/useMyUserQuery";
import { LayerProperties } from "pages/root/Map/VectorGrid";
import { useLocation } from "react-router-dom";

interface MyOrganisationsProjectsMapFilterContextInterface {
  enabled: boolean;
  toggle: () => void;
  filter: (properties: LayerProperties) => boolean | null;
}

export const MyOrganisationsProjectsMapFilterContext =
  createContext<MyOrganisationsProjectsMapFilterContextInterface>(null!);

export const MyOrganisationsProjectsMapFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [enabled, setEnabled] = useState(false);
  const { pathname } = useLocation();
  const { data } = useMyUserQuery();

  useEffect(() => {
    // turn off on panel close
    if (pathname !== "/my-organisation") {
      setEnabled(false);
    }
  }, [pathname]);

  const toggle = () => {
    setEnabled((e) => !e);
  };

  const filter = useCallback(
    (properties: LayerProperties) =>
      enabled ? properties.org_name === data?.organisation?.name : null,
    [enabled, data]
  );

  const value = {
    enabled,
    toggle,
    filter,
  };

  return (
    <MyOrganisationsProjectsMapFilterContext.Provider value={value}>
      {children}
    </MyOrganisationsProjectsMapFilterContext.Provider>
  );
};

export const useMyOrganisationsProjectsMapFilter = () => {
  return useContext(MyOrganisationsProjectsMapFilterContext);
};
