import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { PanelID, usePanels } from "components/panel/PanelsProvider";
import { CreateProjectButton } from "./CreateProjectButton";
import { PublishedProjectsTable } from "./PublishedProjectsTable";
import { MyOrganisationFilterProvider } from "./MyOrganisationFilterContext";
import { FilterButton } from "./FilterButton";
import { MyOrganisationFilterForm } from "./MyOrganisationFilterForm";
import { MyOrganisationsProjectsToggleButton } from "./MyOrganisationsProjectsToggleButton";
import { PanelWrapper } from "../PanelWrapper";
import { CollapsiblePanelBody } from "../CollapsiblePanelBody";

const panelId = PanelID.MyOrganisation;
const path = Path.MyOrganisation;

export const MyOrganisationPanel = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { expandPanel, closePanel, isOpen, isClosed, isCollapsed } =
    usePanels();

  useEffect(() => {
    if (pathname === path && isClosed(panelId)) {
      expandPanel(panelId);
    }
  }, [pathname, expandPanel, isClosed]);

  return (
    <>
      <PanelWrapper isOpen={isOpen(panelId)}>
        <CollapsiblePanelBody
          panelId={panelId}
          expandedTitle="My organisation's projects"
          hideCloseButton={isCollapsed(panelId) || isOpen(PanelID.Project)}
          onClose={() => {
            closePanel(panelId);
            navigate(Path.Index);
          }}
        >
          <MyOrganisationFilterProvider>
            <div className="d-flex gap-3 mb-3">
              <CreateProjectButton />
              <FilterButton />
              <MyOrganisationsProjectsToggleButton />
            </div>

            <MyOrganisationFilterForm />

            <h3 className="h5">Published projects</h3>
            <PublishedProjectsTable />
          </MyOrganisationFilterProvider>
        </CollapsiblePanelBody>
      </PanelWrapper>
    </>
  );
};
