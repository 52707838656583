import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { DateRangeField } from "components/form/DateRangeField";

const fieldName = "projectDuration";

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: [null, null],
  validationSchema: Yup.array()
    .test(
      "is-date-range",
      "Please select start/end date.",
      (value, _context) => {
        return (
          Array.isArray(value) &&
          value[0] instanceof Date &&
          value[1] instanceof Date
        );
      }
    )
    .required(),
};

export const EditProjectDuration = () => (
  <DateRangeField label="Start date - end date" name={fieldName} required />
);
EditProjectDuration.config = config;
