import classNames from "classnames";

interface SubmitButtonProps {
  text: string;
  wide?: boolean;
}

export const SubmitButton = ({ text, wide = true }: SubmitButtonProps) => (
  <button
    type="submit"
    className={classNames("btn btn-primary mt-3", { "btn-wide": wide })}
  >
    {text}
  </button>
);
