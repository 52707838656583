import { useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { useDrawLayer } from "components/drawLayer/DrawLayerProvider";

export const PanelWrapper = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) => {
  const { endDrawingSession } = useDrawLayer();

  // clear any drawn geometries on component unmount
  useEffect(() => endDrawingSession, []);

  return (
    <Collapse in={isOpen} dimension="width">
      <div className="panel-wrapper position-relative">{children}</div>
    </Collapse>
  );
};
