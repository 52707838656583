import { LatLng, LatLngTuple } from "leaflet";

export const saveCoordinates = (centre: LatLng): void => {
  localStorage.setItem("coordinates", JSON.stringify([centre.lat, centre.lng]));
};

export const saveZoom = (zoom: number): void => {
  localStorage.setItem("zoom", zoom.toString());
};

export const getSavedView = (): {
  savedCoordinates: LatLngTuple | null;
  savedZoom: number | null;
} => {
  const coordinates = localStorage.getItem("coordinates");
  const zoom = localStorage.getItem("zoom");

  return {
    savedCoordinates: coordinates ? JSON.parse(coordinates) : null,
    savedZoom: zoom ? Number(zoom) : null,
  };
};
