import { useState } from "react";
import { Link } from "react-router-dom";
import {
  LoginPageLayout,
  GenericSideContent,
} from "components/layout/LoginPageLayout";
import { PageTitle } from "components/scaffolding/PageTitle";
import { useAuth } from "components/auth/useAuth";
import { RequestPasswordResetForm } from "./RequestPasswordResetForm";
import { LoadingModal } from "components/loading/LoadingModal";

export const RequestPasswordResetPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isLoading } = useAuth();

  const onSubmit = () => setIsSubmitted(true);

  const title = isSubmitted ? "Request submitted" : "Request password reset";
  const content = isSubmitted ? (
    <div className="mt-4">
      <p>
        If you have an account with us then you should receive an email shortly
        with further password reset instructions.
      </p>
      <p>
        If you have not received this email within the next half hour then
        please check your spam folder, otherwise{" "}
        <a href="mailto:support@forwardworks.co.nz">contact support</a>.
      </p>
      <div className="mt-4">
        <Link to="/login">Return to log in</Link>
      </div>
    </div>
  ) : (
    <div className="mt-4">
      <p className="mb-4">
        Please enter the email address associated with your account below and
        click 'Submit'. You will receive further instructions on how to reset
        your password by email.
      </p>

      <RequestPasswordResetForm onSubmit={onSubmit} />
    </div>
  );

  const sideCol = (
    <GenericSideContent
      sideText={"Common good technology to build a better New Zealand"}
    />
  );

  return (
    <LoginPageLayout sideCol={sideCol}>
      {isLoading && <LoadingModal />}

      <PageTitle title={title} />

      {!isSubmitted && (
        <p className="fw-600">
          Don't have an account? <Link to="/new-account">Register now</Link>
        </p>
      )}

      {content}
    </LoginPageLayout>
  );
};
