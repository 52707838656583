import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { Select } from "components/form/Select";
import { useRegionsQuery } from "components/fetch/useRegionsQuery";

export const fieldName = "region";

export const regionConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please select a region."),
};

export const Region = () => {
  const { data: regions } = useRegionsQuery();
  // Backend takes region name, so set the value to region.name
  return (
    <Select label="Region" name={fieldName} required>
      <option value=""></option>
      {regions?.map((region) => (
        <option key={region.id} value={region.name}>
          {region.name}
        </option>
      ))}
    </Select>
  );
};
