import { Link, Navigate, useLocation } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { LoginPageLayout } from "components/layout/LoginPageLayout";
import { PageTitle } from "components/scaffolding/PageTitle";
import { LoginForm } from "./LoginForm";
import { useAuth } from "components/auth/useAuth";
import { LoadingModal } from "components/loading/LoadingModal";
import { LoginPageSideContent } from "./LoginPageSideContent";

interface StateWithFromPath {
  from: {
    pathname: string;
  };
}

export const LoginPage = () => {
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuth();

  const state: StateWithFromPath = location.state as StateWithFromPath;
  const from = state?.from?.pathname || Path.Index;

  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  return (
    <LoginPageLayout sideCol={<LoginPageSideContent />}>
      {isLoading && <LoadingModal />}
      <PageTitle title="Log in" />

      <p className="fw-600 mb-4">
        Don't have an account? <Link to="/new-account">Register now</Link>
      </p>
      <LoginForm />

      <p
        className="text-muted text-small fw-300"
        style={{ marginTop: "6.25rem" }}
      >
        This Forward Works Viewer is a secure service for authorised users only.
        If authorised, you will need to log in with an email and password that
        will have been provided to you. By logging into this Forward Works
        Viewer, you are agreeing to be bound by our Terms of use (last updated:
        22 May 2020).
      </p>
      <div className="text-smallest d-flex gap-5">
        <a
          href="https://www.digitalbuiltaotearoa.org.nz"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.digitalbuiltaotearoa.org.nz
        </a>

        <a
          href="https://www.forwardworks.org.nz"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.forwardworks.org.nz
        </a>
      </div>
    </LoginPageLayout>
  );
};
