import { ReactComponent as ShareIcon } from "assets/other-icons/share.svg";

interface ShareProjectButtonProps {
  active?: boolean;
  title: string;
  onClick?: () => void;
}

export const ShareProjectButton = ({
  active,
  title,
  onClick,
}: ShareProjectButtonProps) => {
  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-bookmark-project"
      title={title}
      aria-pressed={active}
      onClick={(e) => {
        e.currentTarget.blur();
        onClick?.();
      }}
    >
      <ShareIcon width={21} height={20} />
    </button>
  );
};
