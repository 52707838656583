import { useSelectedContextualFeature } from "components/layers/SelectedContextualFeatureProvider";
import { COLOUR } from "utils/constants/colours";
import { GeoJSON } from "react-leaflet";

export const SelectedContextualFeatureLayer = () => {
  const { selectedContextualFeature } = useSelectedContextualFeature();
  return selectedContextualFeature ? (
    <GeoJSON
      data={selectedContextualFeature}
      style={{
        fillColor: COLOUR.PINK,
        fillOpacity: 0.6,
        color: COLOUR.PINK,
      }}
      pointToLayer={(feature, latlng) => {
        return L.circleMarker(latlng, { radius: 15 });
      }}
    />
  ) : null;
};
