import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useField } from "formik";
import { useCombobox } from "downshift";
import { matchSorter } from "match-sorter";

import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { Label } from "components/form/Label";
import { ChevronDown } from "components/svg/Chevron";

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  items: string[];
  itemToString?: (item: any) => string;
  helpText?: string;
  clearCondition?: boolean;
}

export const ComboBox = ({
  label,
  name,
  placeholder = "",
  required = false,
  disabled = false,
  items,
  itemToString = (item: string) => item,
  helpText,
  clearCondition,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error;
  const { setValue, setTouched } = helpers;

  const [filterValue, setFilterValue] = useState("");
  const filteredItems = useMemo(() => {
    return matchSorter(items, filterValue.toLowerCase(), {
      // Respect original order
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: [(item) => itemToString(item)],
    });
  }, [filterValue, itemToString, items]);

  // Whether the state changed from having a value to not having a value
  const [isValueRemoved, setIsValueRemoved] = useState<boolean>(false);

  // Show the warning when there's something in the input but the actual value is not set so that the user is aware of it.
  // isValueRemoved check was added so that the warning doesn't show when the user is typing something for the first time.
  // We want to show it only when a value was set then removed.
  const showUnsetWarning = isValueRemoved && !field.value && filterValue;

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    selectedItem,
    selectItem,
    inputValue,
  } = useCombobox({
    onInputValueChange(changes) {
      setFilterValue(changes.inputValue ?? "");
      if (changes.inputValue === changes.selectedItem) {
        setValue(changes.selectedItem);
        setIsValueRemoved(false);
      } else {
        // When going from having a value to not
        if (field.value) {
          setIsValueRemoved(true);
        }
        setValue("");
      }
    },
    onSelectedItemChange(changes) {
      setValue(changes.selectedItem);
      setIsValueRemoved(false);
    },
    items: filteredItems,
    itemToString: itemToString,
  });

  // reset inputValue to empty on reset
  useEffect(() => {
    // detect reset by touched changing to false
    if (!meta.touched) {
      setInputValue(field.value);
      setIsValueRemoved(false);
    }
  }, [field.value, meta.touched, setInputValue]);

  useEffect(() => {
    if (clearCondition && inputValue !== "") {
      setInputValue("");
    }
    if (clearCondition && selectedItem !== "") {
      selectItem("");
    }
  }, [clearCondition, selectedItem, selectItem, inputValue, setInputValue]);

  return (
    <FieldWrapper>
      {label && (
        <Label {...getLabelProps()} label={label} required={required} />
      )}

      <div {...getComboboxProps()} className="position-relative">
        <input
          {...getInputProps()}
          className="form-control"
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={() => setTouched(true)}
        />
        <button
          {...getToggleButtonProps({ disabled })}
          aria-label="toggle menu"
          className="form-input-icon-button position-absolute top-50 translate-middle-y px-2"
          type="button"
        >
          <ChevronDown />
        </button>
      </div>

      <div className="position-relative">
        <ul {...getMenuProps()} className="list-group position-absolute w-100">
          {isOpen && (
            <div
              className="border rounded overflow-y-scroll"
              style={{
                maxHeight: "190px",
                zIndex: 100, // Just needed something to not go behind other form inputs
              }}
            >
              {filteredItems.length > 0 &&
                filteredItems.map((item, index) => (
                  <li
                    {...getItemProps({ item, index })}
                    key={`${item}${index}`}
                    className={classNames("list-group-item", {
                      active: highlightedIndex === index,
                    })}
                  >
                    {itemToString(item)}
                  </li>
                ))}
            </div>
          )}
          {isOpen && filteredItems.length < 1 && (
            <li className="list-group-item text-muted">No matching options</li>
          )}
        </ul>
      </div>
      {isError && <FieldError>{meta.error}</FieldError>}
      {showUnsetWarning && (
        <FieldError>
          Value is unset. Please select from the list if you'd like to set a
          value again.
        </FieldError>
      )}
    </FieldWrapper>
  );
};
