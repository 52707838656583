import { useState, useEffect } from "react";
import { format } from "date-fns";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import { useDrawLayer, DrawMode } from "components/drawLayer/DrawLayerProvider";
import { CreateStageForm } from "./CreateStageForm";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useErrorModal } from "components/modal/useErrorModal";
import { DATE_FORMAT } from "utils/constants/date";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { LoadingModal } from "components/loading/LoadingModal";
import { PanelBody } from "../PanelBody";
import { useProject } from "components/project/ProjectProvider";
import { NavPromptModal } from "components/navBlocker/NavPromptModal";
import { useNavPrompt } from "components/navBlocker/useNavPrompt";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { PanelID, usePanels } from "components/panel/PanelsProvider";

export const CreateStagePanel = () => {
  const [isSaving, setIsSaving] = useState(false);

  const { selectedProject, navigateToStagesTab } = useSelectedProject();
  const {
    data: project,
    isLoading,
    refetch,
  } = useProjectByIdQuery(selectedProject);
  const { invalidateWatchlistQueries } = useWatchlists();
  const { geoJson, setGeoJson, setDrawMode } = useDrawLayer();
  const { setErrorModal } = useErrorModal();
  const isOnAddStagePath = useMatchPath(Path.AddStage);

  const { expandPanel, isClosed } = usePanels();

  // Only enter draw polygon mode on details panel
  useEffect(() => {
    setDrawMode(DrawMode.CreateStageBoundary);
    return () => {
      setDrawMode(DrawMode.Inactive);
    };
  }, [setDrawMode]);

  // Clean up saved GeoJSON on unmount
  useEffect(() => {
    return () => {
      setGeoJson(null);
    };
  }, [setGeoJson]);

  const { shouldPrompt, forceNavigate } = useNavPrompt();
  const { createStage } = useProject();

  const returnToProjectPanel = () => {
    forceNavigate(`/project/${selectedProject}/stages`);
  };

  const onSubmit = async (values: any) => {
    if (!geoJson || !geoJson.features || geoJson.features.length < 1) {
      setErrorModal({
        title: "No boundary set",
        content: "Please add at least one boundary before saving.",
      });
      return;
    }
    for (let stage of project?.stages ?? []) {
      if (stage.title === values.stageName) {
        setErrorModal({
          title: "Duplicate stage name",
          content:
            "This project already has a stage with that name. Please change the name before saving.",
        });
        return;
      }
    }

    setIsSaving(true);

    try {
      await createStage({
        project: selectedProject,
        title: values.stageName,
        description: values.stageDescription,
        start_date: format(values.stageDuration[0], DATE_FORMAT),
        end_date: format(values.stageDuration[1], DATE_FORMAT),
        shape: geoJson,
        impacts: values.trafficImpacts.map(
          (impact: { type: string; timeframe: string }) => ({
            impact_type: impact.type,
            time_of_effect: impact.timeframe,
          })
        ),
      });
      refetch();
      invalidateWatchlistQueries();
      returnToProjectPanel();
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (isOnAddStagePath && isClosed(PanelID.AddStage)) {
      expandPanel(PanelID.AddStage);
    }
  }, [expandPanel, isClosed, isOnAddStagePath]);

  return (
    <PanelBody onClose={returnToProjectPanel}>
      {(isLoading || isSaving) && <LoadingModal />}
      <h2 className="h3">Add boundary</h2>
      <CreateStageForm onCancel={navigateToStagesTab} onSubmit={onSubmit} />
      <NavPromptModal
        enabled={shouldPrompt}
        title="Are you sure?"
        content="Do you want to close create stage form? Unsaved data will be lost."
        onConfirm={returnToProjectPanel}
      />
    </PanelBody>
  );
};
