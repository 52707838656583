import { useLayers } from "components/layers/LayersProvider";
import { useParentCheckState } from "./useParentCheckState";
import {
  CascadingCheckboxAccordion,
  CHECKBOX_STATES,
} from "./CascadingCheckboxAccordion";
import { CompletedInfrastructureLegend } from "./CompletedInfrastructureLegend";

export const CompletedVerticalLayers = () => {
  const {
    completedProjectLayers: { verticalLayers },
  } = useLayers();
  const { projectTypes, checkStates, toggleCheckStates, toggleAllCheckStates } =
    verticalLayers;
  const checked = useParentCheckState(checkStates);

  return (
    <CascadingCheckboxAccordion
      id="layer-completed-vertical"
      title="Completed Vertical projects"
      indentation={false}
      checked={checked}
      toggleChecked={() => {
        toggleAllCheckStates(checked !== CHECKBOX_STATES.Checked);
      }}
    >
      {projectTypes?.map((projectType) => (
        <CascadingCheckboxAccordion
          key={projectType.name}
          title={projectType.display_name}
          id={`layer-completed-vertical-${projectType.name.replace(" ", "-")}`}
          checked={
            checkStates[projectType.name]
              ? CHECKBOX_STATES.Checked
              : CHECKBOX_STATES.Empty
          }
          toggleChecked={() => {
            toggleCheckStates(projectType.name);
          }}
        >
          <CompletedInfrastructureLegend infrastructure={projectType.name} />
        </CascadingCheckboxAccordion>
      ))}
    </CascadingCheckboxAccordion>
  );
};
