import { useNavigate } from "react-router-dom";
import { LinkButton } from "components/button/LinkButton";

export const CancelButton = ({ onCancel }: { onCancel?: () => void }) => {
  const navigate = useNavigate();

  return (
    <LinkButton
      type="button"
      onClick={() => {
        // Go back to previous page by default
        onCancel ? onCancel() : navigate(-1);
      }}
    >
      Cancel
    </LinkButton>
  );
};
