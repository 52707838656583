import { useMemo, useEffect } from "react";
import { Feature, FeatureCollection } from "geojson";
import { useMap } from "react-leaflet";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { Stage } from "utils/types/django";

export const FlyToSelectedStage = () => {
  const {
    selectedStage,
    selectedProject,
    selectedClop,
    shouldFlyTo,
    setShouldFlyTo,
  } = useSelectedProject();
  const { data } = useProjectByIdQuery(
    selectedClop ? selectedClop : selectedProject
  );
  const map = useMap();

  const geom = useMemo(() => {
    if (!selectedClop && selectedStage) {
      return data?.stages.find((s: Stage) => s.id === selectedStage)
        ?.shape as FeatureCollection;
    }
    // combine features across all stages so we can pass one object to L.geoJson
    const features = data?.stages.reduce(
      (feat: Feature[], s: Stage) =>
        s?.shape?.features ? [...s.shape.features, ...feat] : feat,
      []
    );
    return features
      ? ({ type: "FeatureCollection", features } as FeatureCollection)
      : null;
  }, [selectedClop, selectedStage, data]);

  useEffect(() => {
    // Wait for everything to be loaded
    if (!shouldFlyTo || !map || !geom) return;
    // Then do flyTo and stop next flyTo
    try {
      const geoJson = L.geoJSON(geom);
      map.flyToBounds(geoJson.getBounds());
    } catch (error: unknown) {}
    setShouldFlyTo(false);
  }, [map, geom, shouldFlyTo, setShouldFlyTo]);

  return null;
};
