import React, { useState } from "react";
import { ErrorModalContext, SetErrorModalArgs } from "./ErrorModalContext";

export const ErrorModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null as React.ReactNode);

  const setErrorModal = ({ title, content }: SetErrorModalArgs) => {
    setShow(true);
    setTitle(title);
    setContent(content);
  };

  const closeErrorModal = () => setShow(false);

  const value = {
    show,
    title,
    content,
    setErrorModal,
    closeErrorModal,
  };
  return (
    <ErrorModalContext.Provider value={value}>
      {children}
    </ErrorModalContext.Provider>
  );
};
