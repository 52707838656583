import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";
import { defaultFilterValue } from "../MyOrganisationFilterContext";

const fieldName = "no_geom_only";

export const noBoundaryConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: defaultFilterValue.no_geom_only,
  validationSchema: Yup.boolean(),
};

export const NoBoundary = () => {
  return <CheckBox label="Projects with no boundary" name={fieldName} />;
};
