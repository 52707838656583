import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { DateRangeField } from "components/form/DateRangeField";

const fieldName = "projectDuration";

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: [null, null],
  validationSchema: Yup.array()
    .test(
      "is-date-range",
      "Please select start/end date.",
      (value, _context) => {
        return (
          Array.isArray(value) &&
          value[0] instanceof Date &&
          value[1] instanceof Date
        );
      }
    )
    .test(
      "is-not-complete",
      "Please select a more recent end date.",
      (value, _context) => {
        const maxAgeMillis = 1209600000; // 14 days in milliseconds
        const today = new Date();
        const endDate = value?.[1];

        // invalid if end date is defined and >14 days old
        return !!endDate
          ? today.valueOf() - endDate.valueOf() < maxAgeMillis
          : true;
      }
    )
    .required(),
};

export const CreateProjectDuration = () => (
  <DateRangeField label="Start date - end date" name={fieldName} required />
);
CreateProjectDuration.config = config;
