import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { AxiosError } from "axios";
import { buildFormConfig } from "utils/form";
import {
  useNewPasswordConfig,
  useConfirmPasswordConfig,
  NewPassword,
  ConfirmPassword,
} from "./formFields/Password";
import { SubmitButton } from "components/form/SubmitButton";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";

interface ResetPasswordFormArgs {
  email: string;
  token: string;
  onSuccess: () => void;
}

export const PasswordResetForm = ({
  email,
  token,
  onSuccess,
}: ResetPasswordFormArgs) => {
  const newPasswordConfig = useNewPasswordConfig();
  const confirmPasswordConfig = useConfirmPasswordConfig();

  const { initialValues, validationSchema } = buildFormConfig(
    newPasswordConfig,
    confirmPasswordConfig
  );

  const { setPassword } = useAuth();
  const { setErrorModal } = useErrorModal();

  const onSubmit = async (values: any) => {
    const password = values[newPasswordConfig.name];

    try {
      await setPassword({ email, token, password });
      onSuccess();
    } catch (error) {
      let errorMessage = "Something went wrong. Please try again later.";
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.detail ?? errorMessage;
      }
      setErrorModal({
        title: "Password reset failed",
        content: errorMessage,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form noValidate>
        <NewPassword />
        <ConfirmPassword />

        <div className="d-flex justify-content-between align-items-baseline">
          <SubmitButton text="Submit" />
          <Link to="/login">Return to log in</Link>
        </div>
      </Form>
    </Formik>
  );
};
