import { LatLngTuple } from "leaflet";
import { MapContainer, ZoomControl, ScaleControl } from "react-leaflet";

import { SaveLastView } from "./SaveLastView";
import { MapResize } from "./MapResize";
import { MapTools } from "./map-tools/MapTools";
import { TopRightButtonGroup } from "./topright-button-group/TopRightButtonGroup";
import { getSavedView } from "utils/saveLastView";
import { FlyToSelectedStage } from "./FlyToSelectedStage";
import { DrawLayer } from "./DrawLayer";
import { useDrawLayer } from "components/drawLayer/DrawLayerProvider";
import { ContextualLayer } from "./ContextualLayer";
import "./Map.scss";
import { SelectedContextualFeatureLayer } from "./SelectedContextualFeatureLayer";
import { FlyToSearchedProjects } from "./FlytoSearchedProjects";
import { MapClickHandler } from "./MapClickHandler";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { MapFilterControls } from "./map-controls/MapFilterControls";
import { VectorGrids } from "./VectorGrids";
import { DrawMode } from "components/drawLayer/DrawLayerProvider";

const CENTRE_OF_NZ: LatLngTuple = [-41.27262, 173.29934];
const ZOOM_NZ_WHOLE: number = 6;

export const Map = () => {
  const { savedCoordinates, savedZoom } = getSavedView();
  const coordinates = savedCoordinates ?? CENTRE_OF_NZ;
  const zoom = savedZoom ?? ZOOM_NZ_WHOLE;

  const { drawMode, showDrawLayer } = useDrawLayer();
  const { resultIncludesCompletedProjects } = useSearchedProjects();

  const { selectedProject } = useSelectedProject();
  const { data: selectedProjectData } = useProjectByIdQuery(selectedProject);

  return (
    <MapContainer
      id="map-container"
      center={coordinates}
      zoom={zoom}
      minZoom={6}
      maxZoom={19}
      zoomDelta={1}
      wheelPxPerZoomLevel={120}
      zoomControl={false}
    >
      <MapResize />
      <SaveLastView />
      <FlyToSelectedStage />
      <FlyToSearchedProjects />

      {/* When creating a stage boundary, we want to prevent filter controls from being used. */}
      {drawMode !== DrawMode.CreateStageBoundary && <MapFilterControls />}
      <TopRightButtonGroup />
      <ScaleControl position="bottomright" />
      <ZoomControl position="bottomright" />
      <MapTools />
      {/* Only show the draw layer if the drawing tools are currently allowed. */}
      {showDrawLayer && <DrawLayer />}

      {/* When creating a stage boundary, we want to prevent the map from being interacted with. */}
      {drawMode !== DrawMode.CreateStageBoundary && (
        <>
          <MapClickHandler />
          <VectorGrids
            resultIncludesCompletedProjects={resultIncludesCompletedProjects}
            selectedProjectData={selectedProjectData}
          />
        </>
      )}
      <SelectedContextualFeatureLayer />
      <ContextualLayer />
    </MapContainer>
  );
};
