import { useState, useMemo, useEffect } from "react";
import { VectorGrid } from "./VectorGrid";
import { useLayers } from "components/layers/LayersProvider";
import { useMap } from "react-leaflet";
import { Project } from "utils/types/django";
import { PanelID, usePanels } from "components/panel/PanelsProvider";

export const VectorGrids = ({
  resultIncludesCompletedProjects,
  selectedProjectData,
}: {
  resultIncludesCompletedProjects: boolean;
  selectedProjectData: Project | undefined;
}) => {
  const { projectLayers, completedProjectLayers } = useLayers();
  const { someAreOpen } = usePanels();

  const map = useMap();
  const [currentZoom, setCurrentZoom] = useState(map.getZoom());
  useEffect(() => {
    // We must use the zoomend event here; just calling map.getZoom() will
    // result in an out of date copy of the zoom.
    map.on("zoomend", () => {
      const zoom = map.getZoom();
      setCurrentZoom(zoom);
    });
  }, [map, setCurrentZoom]);

  // Check so we can prevent loading large tiles unnecessarily.
  const isCompletedProjectsVisible = useMemo(() => {
    // Completed projects should only be visible on the map when the user zooms
    // to 500m or further in.
    if (currentZoom < 14) {
      return false;
    }

    // If the form search results does not include completed projects and there
    // are form search results, ensure that these layers aren't displayed when
    // search results panels are open. These layers can resurface unexpectably
    // during a form search since the completedProjectLayers.layerVisibility
    // state is not wiped when a form search is submitted, even if the
    // "Include completed projects" checkbox is unchecked in the search form.
    //
    // This is presumably so that whatever layers are set can be used
    // again when the user has finished searching, but I am not 100% sure of the
    // reason for keeping the layer information around.
    if (
      someAreOpen([PanelID.SearchResults, PanelID.SearchResultsInArea]) &&
      !resultIncludesCompletedProjects
    ) {
      return false;
    }

    // any completed projects layer is set to visible. Note, this contain can
    // be true even if the form search has the completed projects flag disabled.
    if (
      Object.values(completedProjectLayers.layerVisibility).some(
        (layerVisibility) => layerVisibility === true
      )
    ) {
      return true;
    }

    // search results include completed projects
    if (resultIncludesCompletedProjects) {
      return true;
    }

    // a completed project is selected
    if (
      selectedProjectData &&
      selectedProjectData.state.toLowerCase() === "complete"
    ) {
      return true;
    }

    return false;
  }, [
    currentZoom,
    someAreOpen,
    resultIncludesCompletedProjects,
    completedProjectLayers.layerVisibility,
    selectedProjectData,
  ]);

  return (
    <>
      <VectorGrid
        layerName="projects"
        layerVisibility={projectLayers.layerVisibility}
        zIndex={300}
        className={
          isCompletedProjectsVisible ? "vectorgrid-pointer" : undefined
        }
      />
      {isCompletedProjectsVisible && (
        <VectorGrid
          layerName="completed_projects"
          layerVisibility={completedProjectLayers.layerVisibility}
          zIndex={200}
          className="vectorgrid-pointer"
        />
      )}
    </>
  );
};
