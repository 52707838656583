import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";
import { useField } from "formik";

export const fieldName = "projectStageId";
// Matching backend definition in backend.projects.models.Project.title
const maxLength = 100;

export const projectStageIdConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().matches(/^[\d]*$/, "Please enter a number."),
};

export const ProjectStageId = () => {
  const [searchByCustomBoundaryField] = useField("searchByCustomBoundary");

  return (
    <TextField
      label="FWV project/stage ID #"
      name={fieldName}
      maxLength={maxLength}
      disabled={searchByCustomBoundaryField.value}
    />
  );
};
