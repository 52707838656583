import { BaseLayerControl } from "./BaseLayerControl";
import { ContextualLayerPopup } from "./ContextualLayerPopup";
import "./TopRightButtonGroup.scss";

export const TopRightButtonGroup = () => {
  return (
    <div className="topright-button-group d-flex flex-column m-3">
      <BaseLayerControl />
      <ContextualLayerPopup />
    </div>
  );
};
