import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import { SearchForm } from "./SearchForm";
import {
  FormSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";
import { SearchResults } from "./SearchResults";
import { PanelWrapper } from "../PanelWrapper";
import {
  usePanels,
  PanelID,
  PanelVisibility,
} from "components/panel/PanelsProvider";
import { SavedSearchProvider } from "components/project/searched-projects/SavedSearchContext";
import { CollapsiblePanelBody } from "../CollapsiblePanelBody";
import { Button } from "react-bootstrap";
import { SearchResultsInAreaPanel } from "./SearchResultsInAreaPanel";

// Todo: Refactor details into two components: SearchFormPanel and
// SearchResultsPanel as they are very distinct. SearchPanels is a container of
// panels, including project panels, that are open while searching.
export const SearchPanels = () => {
  const navigate = useNavigate();

  const {
    setPanelVisibilities,
    expandPanel,
    closePanel,
    closeRightMostPanel,
    isExpanded,
    someAreExpanded,
    isCollapsed,
    isClosed,
    isOpen,
  } = usePanels();

  const {
    formSearchResults,
    resetBothSearches,
    formSearchStatus,
    cancelFormSearch,
  } = useSearchedProjects();

  const [searchResultsHeading, setSearchResultsHeading] =
    useState("Search Results");

  const isOnSearchPath = useMatchPath(Path.Search);

  useEffect(() => {
    /**
     * The initial time the first search panel is opened, add the search form
     * to the set of expanded panels and reset the search form/results:
     */
    if (isClosed(PanelID.SearchForm) && isOnSearchPath) {
      expandPanel(PanelID.SearchForm);
      resetBothSearches();
    }
  }, [
    expandPanel,
    isClosed,
    formSearchResults,
    isOnSearchPath,
    resetBothSearches,
  ]);

  return (
    <SavedSearchProvider>
      <PanelWrapper isOpen={isOpen(PanelID.SearchForm)}>
        <CollapsiblePanelBody
          panelId={PanelID.SearchForm}
          expandedTitle="Search"
          afterExpand={() => {
            // Stay on the project path if that panel is open to avoid the
            // project panel from closing when a collapsed search form panel is
            // expanded again.
            if (isClosed(PanelID.Project)) {
              navigate(Path.Search);
            }
          }}
          hideCloseButton={
            isCollapsed(PanelID.SearchForm) ||
            someAreExpanded([
              PanelID.SearchResults,
              PanelID.SearchResultsInArea,
              PanelID.Project,
            ])
          }
          onClose={() => {
            closeRightMostPanel();
            if (formSearchStatus === FormSearchStatus.InProgress) {
              cancelFormSearch();
            }
            navigate(Path.Index);
          }}
        >
          {formSearchStatus === FormSearchStatus.InProgress && (
            <>
              {/* Error handling in case a form search was left running;
                  allows the search to be cancelled manually. */}
              <p>A search is in progress.</p>
              <Button
                onClick={() => {
                  cancelFormSearch();
                  closePanel(PanelID.SearchResults);
                }}
              >
                Cancel Search
              </Button>
            </>
          )}
          {/* Form values will disappear if SearchForm is unmounted, so hide it without unmounting */}
          <SearchForm
            toggleViews={() => {
              setPanelVisibilities([
                {
                  id: PanelID.SearchForm,
                  visibility: PanelVisibility.Collapsed,
                },
                {
                  id: PanelID.SearchResults,
                  visibility: PanelVisibility.Expanded,
                },
                {
                  id: PanelID.SearchResultsInArea,
                  visibility: PanelVisibility.Closed,
                },
              ]);
            }}
            isHidden={
              !isExpanded(PanelID.SearchForm) ||
              formSearchStatus === FormSearchStatus.InProgress
            } // Form values will disappear if SearchForm is unmounted, so hide it without unmounting
          />
        </CollapsiblePanelBody>
      </PanelWrapper>
      <PanelWrapper isOpen={isOpen(PanelID.SearchResults)}>
        <CollapsiblePanelBody
          panelId={PanelID.SearchResults}
          expandedTitle={searchResultsHeading}
          collapsedTitle="Search Results"
          hideCloseButton={
            isCollapsed(PanelID.SearchResults) ||
            someAreExpanded([
              PanelID.Projects,
              PanelID.Project,
              PanelID.EditProject,
              PanelID.AddStage,
              PanelID.EditStage,
            ])
          }
          onClose={() => {
            // If there is an in-progress search, cancel it:
            if (formSearchStatus === FormSearchStatus.InProgress) {
              cancelFormSearch();
            }
            closeRightMostPanel();
          }}
        >
          {/* Search results will disappear if SearchResults is unmounted, so hide it without unmounting */}
          <SearchResults setSearchResultsHeading={setSearchResultsHeading} />
        </CollapsiblePanelBody>
      </PanelWrapper>
      <SearchResultsInAreaPanel />
    </SavedSearchProvider>
  );
};
