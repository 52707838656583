import { ReactComponent as ChevronIcon } from "assets/other-icons/chevron.svg";
import React from "react";

export const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => {
  const style = {
    ...props.style,
    transform: "rotate(270deg)",
  };
  return <ChevronIcon {...props} style={style} />;
};

export const ChevronUp = (props: React.SVGProps<SVGSVGElement>) => {
  const style = {
    ...props.style,
    transform: "rotate(90deg)",
  };
  return <ChevronIcon {...props} style={style} />;
};

export const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return <ChevronIcon {...props} />;
};

export const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => {
  const style = {
    ...props.style,
    transform: "rotate(180deg)",
  };
  return <ChevronIcon {...props} style={style} />;
};
