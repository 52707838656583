import classNames from "classnames";

export const LeftHeaderTable = ({
  small = false,
  children,
}: {
  small?: boolean;
  children: React.ReactNode;
}) => (
  <table
    className={classNames("w-100", {
      "text-small": !small,
      "text-smallest": small,
    })}
  >
    <colgroup>
      <col style={{ width: "32%" }} />
      <col style={{ width: "68%" }} />
    </colgroup>
    <tbody>{children}</tbody>
  </table>
);
