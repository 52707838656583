import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextArea } from "components/form/TextArea";

const fieldName = "projectDetails";
const maxLength = 500;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please enter project details."),
};

export const ProjectDetails = () => (
  <TextArea
    label="Project details"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
ProjectDetails.config = config;
