import { Routes, Route } from "react-router-dom";
import { Providers } from "components/scaffolding/Providers";
import { DefaultPageTitle } from "components/scaffolding/DefaultPageTitle";
import { RequireAuth } from "components/auth/RequireAuth";
import { LoginPage } from "pages/login/LoginPage";
import { NewAccountApplicationPage } from "pages/new-account/NewAccountApplicationPage";
import { RequestPasswordResetPage } from "pages/reset-password/RequestPasswordResetPage";
import { ErrorModal } from "components/modal/ErrorModal";
import { PasswordResetPage } from "pages/set-password/PasswordResetPage";
import { MainPage } from "pages/root/MainPage";

export const App = () => (
  <Providers>
    <DefaultPageTitle />
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="new-account" element={<NewAccountApplicationPage />} />
      <Route path="set-password" element={<PasswordResetPage />} />
      <Route path="reset-password" element={<RequestPasswordResetPage />} />
      <Route
        path="*"
        element={
          <RequireAuth>
            <MainPage />
          </RequireAuth>
        }
      />
    </Routes>
    <ErrorModal />
  </Providers>
);
