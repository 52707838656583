import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

type Props = {
  children?: string;
};

export const LoadingModal = ({ children }: Props) => {
  // Delay actual showing so it doesn't cause flash
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, []);

  return show ? (
    <Modal show centered contentClassName="p-4">
      <Modal.Header>
        <Modal.Title>{children ? children : "Loading..."}</Modal.Title>
      </Modal.Header>
    </Modal>
  ) : null;
};
