import { createContext } from "react";

export interface SetErrorModalArgs {
  title: string;
  content: React.ReactNode;
}

interface ModalContextInterface {
  show: boolean;
  title: string;
  content: React.ReactNode;
  setErrorModal: ({ title, content }: SetErrorModalArgs) => void;
  closeErrorModal: () => void;
}

const initialState: ModalContextInterface = {
  show: false,
  title: "",
  content: null,
  setErrorModal: ({ title, content }: SetErrorModalArgs) => {},
  closeErrorModal: () => {},
};

export const ErrorModalContext =
  createContext<ModalContextInterface>(initialState);
