import { useMemo } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./PaginationNavigation.scss";

// This must be consistent with the (typical) projects per page that are
// returned from the backend, otherwise the pagination won't be consistent.
// We cannot use projects.length to determine this value since the last page
// would normally have less results than the first page.
export const projectsPerPage = 50;

interface PaginationNavigationProps {
  pageCount: number;
  currentPageNumber: number;
  currentRouteSlug: string;
}

export function PaginationNavigation({
  pageCount,
  currentPageNumber,
  currentRouteSlug,
}: PaginationNavigationProps) {
  const previousPageNumber = currentPageNumber - 1;
  const nextPageNumber = currentPageNumber + 1;
  const firstPageNumber = 1;
  const lastPageNumber = pageCount;
  const numberOfPagesEitherSide = 2; // Number of pages either side of the middle page number

  const pageNumbersForCurrentBlock = useMemo(() => {
    const pagesPerPaginationBlock = numberOfPagesEitherSide * 2 + 1;
    const middlePageNumber = pagesPerPaginationBlock - numberOfPagesEitherSide;

    const range = (startNumber: number, endNumber: number) =>
      Array.from(
        { length: endNumber - startNumber + 1 },
        (_, n) => n + startNumber
      );
    if (currentPageNumber < firstPageNumber + middlePageNumber) {
      const blockEndPageNumber =
        pageCount < pagesPerPaginationBlock
          ? pageCount
          : pagesPerPaginationBlock;
      return range(firstPageNumber, blockEndPageNumber);
    }
    if (currentPageNumber > lastPageNumber - middlePageNumber) {
      return range(
        lastPageNumber - (pagesPerPaginationBlock - 1),
        lastPageNumber
      );
    }
    return range(
      currentPageNumber - numberOfPagesEitherSide,
      currentPageNumber + numberOfPagesEitherSide
    );
  }, [currentPageNumber, lastPageNumber, pageCount]);

  const thereArePagesBeyondTheCurrentBlock =
    currentPageNumber < lastPageNumber - numberOfPagesEitherSide;

  return (
    <nav aria-label="Search results navigation">
      <ul className="project-pagination-navigation pagination justify-content-center">
        <li className="page-item disabled">
          <span className="page-link pagination-heading">Page</span>
        </li>

        <li
          className={classNames("page-item", {
            disabled: currentPageNumber === firstPageNumber,
          })}
        >
          <Link
            className="page-link"
            to={`/${currentRouteSlug}?page=${firstPageNumber}`}
            aria-label="First"
          >
            &laquo;
          </Link>
        </li>
        <li
          className={classNames("page-item", {
            disabled: currentPageNumber === firstPageNumber,
          })}
        >
          <Link
            className="page-link"
            to={`/${currentRouteSlug}?page=${previousPageNumber}`}
            aria-label="Previous"
          >
            &lsaquo;
          </Link>
        </li>

        {pageNumbersForCurrentBlock.map((pageNumber: number) => (
          <Fragment key={pageNumber}>
            {pageNumber === currentPageNumber && (
              <li className="page-item active">
                <span className="page-link">{pageNumber}</span>
              </li>
            )}
            {pageNumber !== currentPageNumber && (
              <li className="page-item">
                <Link
                  className="page-link"
                  to={`/${currentRouteSlug}?page=${pageNumber}`}
                >
                  {pageNumber}
                </Link>
              </li>
            )}
          </Fragment>
        ))}

        {thereArePagesBeyondTheCurrentBlock && (
          <li className="page-item disabled">
            <Link className="page-link" to="#">
              ...
            </Link>
          </li>
        )}

        <li
          className={classNames("page-item", {
            disabled: currentPageNumber === lastPageNumber,
          })}
        >
          <Link
            className="page-link"
            to={`/${currentRouteSlug}?page=${nextPageNumber}`}
            aria-label="Next"
          >
            &rsaquo;
          </Link>
        </li>
        <li
          className={classNames("page-item", {
            disabled: currentPageNumber === lastPageNumber,
          })}
        >
          <Link
            className="page-link"
            to={`/${currentRouteSlug}?page=${lastPageNumber}`}
            aria-label="Last"
          >
            &raquo;
          </Link>
        </li>
      </ul>
    </nav>
  );
}
