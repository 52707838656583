import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  LoginPageLayout,
  GenericSideContent,
} from "components/layout/LoginPageLayout";
import { PageTitle } from "components/scaffolding/PageTitle";
import { useAuth } from "components/auth/useAuth";
import { PasswordResetForm } from "./PasswordResetForm";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingModal } from "components/loading/LoadingModal";

const useSearchParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const PasswordResetPage = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { isLoading, validateToken } = useAuth();

  const searchParams = useSearchParams();
  const email = searchParams.get("email") || "";
  const token = searchParams.get("token") || "";

  const onSuccess = () => setIsSuccessful(true);

  useEffect(() => {
    const validate = async () => {
      try {
        await validateToken({ email, token });
        setIsTokenValid(true);
      } catch (error) {
        setIsTokenValid(false);
        setIsSuccessful(false);
      }
    };

    validate();

    return () => {
      setIsTokenValid(false);
      setIsSuccessful(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // redirect to login page if query params are missing
  if (!(email && token)) {
    return <Navigate to="/login" />;
  }

  const title = isSuccessful
    ? "Password reset"
    : "Are you looking to set up a new password/reset your existing password?";
  const message = isTokenValid ? (
    <p>
      Your password has been successfully reset. You may now proceed to the log
      in screen.
    </p>
  ) : (
    <p>
      Sorry, this link is no longer valid as it's been longer than 48 hours
      since you applied. If you would still like a new password, please{" "}
      <Link to="/reset-password">submit a new request</Link>. For security
      purposes, you will need to click the link you receive within 48 hours.
    </p>
  );
  const content =
    !isSuccessful && isTokenValid ? (
      <div className="mt-4">
        <p className="mb-4">
          Please enter and confirm a new password for your account below. Your
          password must be at least 8 characters long, contain at least one
          capital letter, and at least one number.
        </p>
        <PasswordResetForm email={email} token={token} onSuccess={onSuccess} />
      </div>
    ) : (
      <div className="mt-4">
        {message}
        <div className="mt-4">
          <Link to="/login">Return to log in</Link>
        </div>
      </div>
    );

  const sideCol = (
    <GenericSideContent sideText="Common good technology to build a better New Zealand" />
  );
  return (
    <LoginPageLayout sideCol={sideCol}>
      {isLoading && <LoadingModal />}

      <PageTitle title={title} />

      {content}
    </LoginPageLayout>
  );
};
