import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { CancelButton } from "./CancelButton";

import { ProjectName } from "./formFields/ProjectName";
import { Reference } from "./formFields/Reference";
import { CreateProjectDuration } from "./formFields/CreateProjectDuration";
import { ContactName } from "./formFields/ContactName";
import { ContactPhone } from "./formFields/ContactPhone";
import { ContactEmail } from "./formFields/ContactEmail";
import { ProjectType } from "./formFields/ProjectType";
import { InfrastructureType } from "./formFields/InfrastructureType";
import { ProjectDetails } from "./formFields/ProjectDetails";
import { ChoosingAccordion } from "./ChoosingAccordion";

export const ProjectSummaryForm = ({
  onNext,
  setIsMultiStage,
}: {
  onNext: (values: any) => void;
  setIsMultiStage: (values: boolean) => void;
}) => {
  const { initialValues, validationSchema } = buildFormConfig(
    ProjectName.config,
    Reference.config,
    CreateProjectDuration.config,
    ContactName.config,
    ContactPhone.config,
    ContactEmail.config,
    ProjectType.config,
    InfrastructureType.config,
    ProjectDetails.config
  );

  return (
    <>
      <h3 className="h5">Project summary</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onNext}
      >
        {({ values }) => (
          <Form noValidate>
            <ProjectName />
            <Reference />
            <CreateProjectDuration />
            <ContactName />
            <ContactPhone />
            <ContactEmail />
            <ProjectType />
            <InfrastructureType projectType={values.projectType} />
            <ProjectDetails />

            <div className="d-flex align-items-baseline justify-content-end gap-3">
              <CancelButton />
              <button
                type="submit"
                className="btn btn-primary mt-3"
                onClick={() => setIsMultiStage(true)}
              >
                Create project <br />
                with stages
              </button>
              <button
                type="submit"
                className="btn btn-primary mt-3"
                onClick={() => setIsMultiStage(false)}
              >
                Create <br />
                project
              </button>
            </div>
            <div className="mt-3">
              <ChoosingAccordion />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
