import { useParams } from "react-router-dom";

import { PanelBody } from "pages/root/Panel/PanelBody";
import { LoadingModal } from "components/loading/LoadingModal";
import { usePermission } from "./usePermission";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";

interface Props {
  children: React.ReactNode;
}

export const RequireAddProject = ({ children }: Props) => {
  const permission = usePermission();

  if (!permission.addProject()) {
    return (
      <PanelBody>
        <p className="text-danger">
          You don't have permission to create project.
        </p>
      </PanelBody>
    );
  }

  return <>{children}</>;
};

export const RequireEditProject = ({ children }: Props) => {
  const permission = usePermission();
  const { projectId } = useParams();
  const { data: project, isLoading } = useProjectByIdQuery(projectId ?? null);

  if (isLoading) {
    return <LoadingModal />;
  }

  if (project && !permission.editProject(project.organisation.id)) {
    return (
      <PanelBody>
        <p className="text-danger">
          You don't have permission to edit this project.
        </p>
      </PanelBody>
    );
  }

  return <>{children}</>;
};
