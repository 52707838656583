import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "./AuthContext";
import { djangoApi } from "utils/djangoApi";
import keycloak, {
  authTokenName,
  clearTokens,
  refreshTokens,
} from "utils/keycloak";
import Cookies from "js-cookie";
import { useIdleTimer } from "react-idle-timer";
import { PromptModal } from "components/modal/PromptModal";

export interface LoginArgs {
  email: string;
  password?: string;
}

export interface ValidateTokenArgs {
  email: string;
  token: string;
}

export interface ResetPasswordArgs {
  email: string;
}

export interface SetPasswordArgs {
  email: string;
  token: string;
  password: string;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!Cookies.get(authTokenName)
  );
  const [isExpired, setIsExpired] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [authToken, setAuthToken] = useState(Cookies.get(authTokenName) ?? "");
  const queryClient = useQueryClient();

  const login = async ({ email, password }: LoginArgs): Promise<void> => {
    setIsLoading(true);

    // clear tokens before logging in, in case they are stale and cause a token error on the /login endpoint - see fwv-issue-repo#187 for an example
    clearTokens();

    const params = new URLSearchParams();
    params.append("email", email);

    if (password) {
      params.append("password", password);
    }

    try {
      const resp = await djangoApi.post("/api/v2/auth/login/", params);
      const sso = Object.keys(resp.data).includes("alias");

      if (sso) {
        await keycloak.login({ loginHint: email });
      } else {
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (keycloak.authenticated) {
        clearTokens();
        // redirect acts as a page refresh and flushes React state
        await keycloak.logout();
      } else {
        // only use /logout endpoint for session auth
        await djangoApi.post("/api/v2/auth/logout/");
        setIsAuthenticated(false);
        setIsExpired(false);
        queryClient.clear();
      }
      return Promise.resolve();
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const expireSession = () => {
    clearTokens();
    setIsExpired(true);
  };

  const resetPassword = async ({ email }: ResetPasswordArgs): Promise<void> => {
    setIsLoading(true);

    const params = new URLSearchParams();
    params.append("email", email);

    try {
      await djangoApi.post("/api/auth/reset-password/", params);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const setPassword = async ({
    email,
    token,
    password,
  }: SetPasswordArgs): Promise<void> => {
    setIsLoading(true);

    const params = new URLSearchParams();
    params.append("email", email);
    params.append("token", token);
    params.append("password", password);

    try {
      await djangoApi.post("/api/auth/set-password/", params);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const validateToken = async ({
    email,
    token,
  }: ValidateTokenArgs): Promise<void> => {
    setIsLoading(true);

    const params = new URLSearchParams();
    params.append("email", email);
    params.append("token", token);

    try {
      await djangoApi.post("/api/auth/validate-token/", params);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  useIdleTimer({
    onIdle: () => setHasTimedOut(true),
    timeout: 1000 * 60 * 60 * 4, // 4 hours
  });

  // Validate session on load
  useEffect(() => {
    // Use abort controller to avoid act() test errors
    const controller = new AbortController();
    const validateSession = async () => {
      setIsLoading(true);
      try {
        await djangoApi.get("/api/auth/validate-session/", {
          signal: controller.signal,
        });
        setIsAuthenticated(true);
      } catch (error) {
        if (keycloak.refreshToken) {
          setIsAuthenticated(await refreshTokens());
        } else {
          setIsAuthenticated(false);
        }
      }
      if (!controller.signal.aborted) {
        setIsLoading(false);
      }
    };
    validateSession();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    // must poll Keycloak client for token updates as client is non-reactive
    const interval = setInterval(() => {
      if (keycloak?.token !== authToken) {
        setAuthToken(keycloak?.token ?? "");
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [authToken]);

  const value = {
    isLoading,
    isAuthenticated,
    authToken,
    login,
    logout,
    validateToken,
    resetPassword,
    setPassword,
    expireSession,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <PromptModal
        title="Logged out"
        content="You have been logged out due to inactivity. Please log in again."
        show={isAuthenticated && hasTimedOut}
        cancelText="Close"
        onCancel={async () => {
          setHasTimedOut(false);
          if (keycloak.authenticated) {
            // don't remove Keycloak session so user can easily log back in
            clearTokens();
            setIsAuthenticated(false);
          } else {
            await logout();
          }
        }}
      />
      <PromptModal
        title="Session expired"
        content="Your session has expired. Please log in again."
        show={isExpired}
        cancelText="Close"
        onCancel={logout}
      />
    </AuthContext.Provider>
  );
}
