import { useState } from "react";
import { useField, FieldHookConfig } from "formik";
import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { Label } from "components/form/Label";
import { ReactComponent as VisibleIcon } from "assets/other-icons/password-visible.svg";
import { ReactComponent as HiddenIcon } from "assets/other-icons/password-hidden.svg";
import "./PasswordField.scss";

interface PasswordProps {
  label: React.ReactNode;
}

type OtherProps = FieldHookConfig<string> & React.HTMLProps<HTMLInputElement>;

export const PasswordField = (
  props: PasswordProps & Omit<OtherProps, "label">
) => {
  const { label, ...rest } = props;
  const [field, meta] = useField(rest);
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <FieldWrapper>
      <Label
        htmlFor={id}
        label={label}
        required={props.required} // required is used only cosmetically for label
      />
      <div className="position-relative">
        <input
          {...rest}
          {...field}
          id={id}
          className="form-control"
          type={isVisible ? "text" : "password"}
          required={false} // Don't want browser default validation
        />
        <button
          type="button"
          onClick={toggleVisibility}
          className="form-input-icon-button password-visibility-icon position-absolute top-50 translate-middle-y"
        >
          {!isVisible && <HiddenIcon />}
          {isVisible && <VisibleIcon />}
        </button>
      </div>
      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
