import {
  Watchlist,
  WatchlistSummary,
} from "components/watchlist/WatchlistContext";
import { useDjangoQuery } from "./useDjangoQuery";
import { watchlistsEndpoint } from "./useWatchlistsQuery";

export const bookmarksEndpoint = watchlistsEndpoint + "bookmarks/";
export const projectsPerWatchlist = 1000;

export const useWatchlistQuery = (watchlist?: WatchlistSummary) =>
  useDjangoQuery<Watchlist>(
    `${watchlistsEndpoint}${watchlist?.id}/?page_size=${projectsPerWatchlist}`
  );
