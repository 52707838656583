import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "email";
const maxLength = 100;

export const useEmailConfig = (): FormFieldConfig => {
  return {
    name: fieldName,
    initialValue: "",
    validationSchema: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter an email address."),
  };
};

export const Email = () => (
  <TextField
    label="Email address"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
