import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "components/auth/AuthProvider";
import { ErrorModalProvider } from "components/modal/ErrorModalProvider";
import { QueryProvider } from "./QueryProvider";

interface ProvidersProps {
  children: React.ReactNode;
}

// These provider apply to all pages including login, new-account, etc.
// Providers only needed after login (i.e. for the map and sidebars) are moved to MainPage.
export const Providers = ({ children }: ProvidersProps) => (
  <BrowserRouter>
    <QueryProvider>
      <AuthProvider>
        <ErrorModalProvider>
          <HelmetProvider>{children}</HelmetProvider>
        </ErrorModalProvider>
      </AuthProvider>
    </QueryProvider>
  </BrowserRouter>
);
