import { ReactComponent as AdminIcon } from "assets/sidebar-icons/admin.svg";
import { usePermission } from "components/permission/usePermission";
import { SystemRole } from "utils/types/django";

export const Admin = () => {
  const { role } = usePermission();

  return role === SystemRole.Superuser ? (
    <a href="/admin" rel="noopener noreferrer">
      <AdminIcon
        data-testid="map-icon"
        className="sidebar-icon"
        type="button"
        height="38px"
        style={{ marginBottom: "10px" }}
      />
    </a>
  ) : null;
};
