import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { DropdownMultipleCombobox } from "components/form/DropdownMultipleCombobox";
import { useOrganisationsQuery } from "components/fetch/useOrganisationsQuery";

const fieldName = "organisation";

export const organisationConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: [],
  validationSchema: Yup.array().of(Yup.string()),
};

export const Organisation = ({
  clearCondition,
}: {
  clearCondition?: boolean;
}) => {
  const { data } = useOrganisationsQuery();
  const sorted = data?.slice().sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  });
  const items = sorted?.map((organisation) => organisation.name) ?? [];

  return (
    <DropdownMultipleCombobox
      label="Organisation"
      name={fieldName}
      items={items}
      itemToString={(item: string) => item}
      placeholder="Select organisation(s)"
      clearCondition={clearCondition}
    />
  );
};
