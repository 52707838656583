import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  WatchlistSummary,
  sharedProjectsId,
} from "components/watchlist/WatchlistContext";
import { WatchlistNotificationsButton } from "./WatchlistNotificationsButton";
import "./Watchlist.scss";
import { NotificationCount } from "./NotificationCount";

export interface WatchlistListItemProps {
  watchlist: WatchlistSummary;
}

export const WatchlistListItem = ({ watchlist }: WatchlistListItemProps) => {
  const navigate = useNavigate();
  const { watchlistId } = useParams();
  const active = watchlistId === watchlist.id;

  let subtitle = "Currently empty";
  if (watchlist.projects > 0) {
    subtitle =
      (watchlist.projects === 1
        ? "1 Project "
        : `${watchlist.projects} Projects `) +
      (watchlist.id === sharedProjectsId ? "Shared" : "Watchlisted");
  }

  return (
    <div
      className={classNames(
        "watchlist-list-item d-flex align-items-center list-group-item list-group-item-action p-2",
        { active }
      )}
      aria-current={active}
    >
      <button
        className="btn flex-grow-1"
        onClick={() => navigate(`/dashboard/watchlist/${watchlist.id}`)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <strong>{watchlist.name}</strong>
            <small className="fw-300">{subtitle}</small>
          </div>
          {watchlist.unread > 0 && (
            <NotificationCount count={watchlist.unread} active />
          )}
        </div>
      </button>
      <WatchlistNotificationsButton watchlist={watchlist} />
    </div>
  );
};
