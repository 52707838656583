import { Position } from "@turf/helpers";

/**
 * Note: We do need both Polygon and LineString geometry to be displayed on the
 * map at the same time in order for projects within existing geometry (line
 * strings) from previous searches to be hovered over while the user also has
 * the ability to add new geometry (polygons) while the search form is open. So,
 * that explains much of the complexity in this file.
 */

/**
 * TODO: We should be able to avoid a lot of complexity here by seperating the
 * Polygon and LineString geometry into seperate states (rather than using a
 * global geoJSON state that can contain both. This would allow both the
 * Polygon and LineString geometry to be displayed on the map simultaneously and
 * we could use the TURF geometry types and conversion functions rather than
 * working with coordinates directly.
 */

/**
 * Returns the depth of the array
 * ['A', 'B'] is considered 2 deep.
 * [['A'], ['B']] is considered 3 deep.
 * [[['A']['B']]] is considered 4 deep.
 */
function getArrayDepth(value: unknown): number {
  return Array.isArray(value)
    ? 1 + Math.max(0, ...value.map(getArrayDepth))
    : 0;
}

/** Checks that there is at least one non-empty set of coordinates. */
export function areNestedCoordinatesPopulated(
  coordinates: Position[][][]
): boolean {
  const flattened = coordinates.flat(Infinity);
  const flattenedLength = flattened.length;
  return flattenedLength > 0;
}

/**
 * Convert MultiLineString coordinates to MultiPolygon compatible
   coordinates.
*/
export function normaliseCoordinatesToMultiPolygonCompatible(
  coordinates: Position[][] | Position[][][]
): Position[][][] {
  if (getArrayDepth(coordinates) === 3) {
    return [coordinates] as Position[][][];
  }
  if (getArrayDepth(coordinates) === 4) {
    return coordinates as Position[][][];
  }
  return [[[]]];
}

/**
 * Convert MultiPolygon coordinates to MultiLineString compatible
 * coordinates.
 */
export function normaliseCoordinatesToMultiLineStringCompatible(
  coordinates: Position[] | Position[][] | Position[][][]
): Position[][] {
  if (getArrayDepth(coordinates) === 4) {
    return coordinates.map((c) => c[0]) as Position[][];
  }
  if (getArrayDepth(coordinates) === 2) {
    return [coordinates] as Position[][];
  }
  if (getArrayDepth(coordinates) === 3) {
    return coordinates as Position[][];
  }
  return [[]];
}

/**
 * Convert MultiPolygon coordinates to MultiLineString compatible
 * coordinates.
 *
 * MultiPolygons can be created in two ways - with the lines defined
 * with one redundant outer array (length is 1), or with multiple
 * redundant outer arrays (length is > 1). When line strings and
 * polygons are combined, the multipolygons may be structured
 * differently, so we have to handle both cases:
 *
 * This function is slightly different from
 * normaliseCoordinatesToMultiLineStringCompatible since the passed coordinates
 * are already in Position[][][]
 */
export function convertCoordinatesToMultiLineStringCompatible(
  coordinates: Position[][][]
): Position[][] {
  if (coordinates.length === 1) {
    return coordinates[0].map((c) => c);
  }
  if (coordinates.length > 1) {
    return coordinates.map((c) => c[0]);
  }
  return [[]];
}
