import { useState, useEffect, useMemo } from "react";
import buffer from "@turf/buffer";
import { parse, stringify } from "wkt";
import { Formik, Form, FormikHelpers } from "formik";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { buildFormConfig } from "utils/form";
import { BACKEND_DATE_FORMAT } from "utils/constants/date";
import { Project } from "utils/types/django";
import { Path } from "utils/constants/paths";
import { VisuallyHidden } from "components/layout/VisuallyHidden";
import {
  SavedSearch as SavedSearchData,
  useSavedSearches,
} from "components/project/searched-projects/SavedSearchContext";
import {
  ProjectDateRange,
  dateRangeConfig,
  dateRangeStatusConfig,
} from "./formFields/ProjectDateRange";
import { SubmitButton } from "components/form/SubmitButton";
import { ResetButton } from "components/form/ResetButton";
import { Address, addressConfig } from "./formFields/Address";
import {
  ProjectStageId,
  projectStageIdConfig,
} from "./formFields/ProjectStageId";
import {
  ProjectStageTitle,
  projectStageTitleConfig,
} from "./formFields/ProjectStageTitle";
import { Reference, referenceConfig } from "./formFields/Reference";
import { Contact, contactConfig } from "./formFields/Contact";
import { Organisation, organisationConfig } from "./formFields/Organisation";
import { ProjectType, projectTypeConfig } from "./formFields/ProjectType";
import {
  CompletedProjects,
  completedProjectsConfig,
} from "./formFields/CompletedProjects";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import {
  SavedSearch,
  SavedSearchState,
  savedSearchConfig,
} from "./formFields/SavedSearch";
import { SavedSearchButton } from "components/form/SaveSearchButton";
import { savedSearchNameConfig } from "./formFields/SavedSearchName";
import { savedSearchWatchlistConfig } from "./formFields/SavedSearchWatchlist";
import {
  WatchlistSummary,
  useWatchlists,
} from "components/watchlist/WatchlistContext";
import SearchByCustomBoundary, {
  searchByCustomBoundaryConfig,
} from "./formFields/SearchByCustomBoundary";
import {
  DrawMode,
  useDrawLayer,
  GeoJSON,
} from "components/drawLayer/DrawLayerProvider";

// A bounding box, as it is returned from the API. The four numbers represent
// min longitude, min latitude, max longitude, and max latitude respectively.
export type BoundingBox = [number, number, number, number] | undefined;

// Based on: https://www.django-rest-framework.org/api-guide/pagination/#pagenumberpagination
// Note: The next and previous properites provided by Django REST framework are
// not used by the frontend, so are excluded from this frontend type.
export interface SearchResults {
  count: number;
  results: Project[];
  bbox: BoundingBox;
}

function onKeyDown(keyEvent: any) {
  if (keyEvent.key === "Enter") {
    keyEvent.preventDefault();
  }
}

export const SearchForm = ({
  toggleViews,
  isHidden,
}: {
  toggleViews: () => void;
  isHidden: boolean;
}) => {
  const [geoJsonOutlineOnly, setGeoJsonOutlineOnly] = useState(null as GeoJSON);
  const [clearCondition, setClearCondition] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const { initialValues, validationSchema } = buildFormConfig(
    savedSearchConfig,
    addressConfig,
    searchByCustomBoundaryConfig,
    projectStageIdConfig,
    projectStageTitleConfig,
    referenceConfig,
    contactConfig,
    organisationConfig,
    dateRangeConfig,
    dateRangeStatusConfig,
    projectTypeConfig,
    completedProjectsConfig,
    savedSearchNameConfig,
    savedSearchWatchlistConfig
  );

  const { startFormSearch } = useSearchedProjects();
  const { getSearch, updateSearch, saveSearch, refreshSearches } =
    useSavedSearches();
  const { savedSearchWatchlists, refetchWatchlists, invalidateWatchlistQuery } =
    useWatchlists();

  const {
    drawMode,
    setDrawMode,
    geoJson,
    setGeoJson,
    endDrawingSession,
    combineGeoJsonFeaturesIntoMultiPolygon,
    getGeoJsonAsOutlineOnly,
  } = useDrawLayer();

  const navigate = useNavigate();

  /**
   * If there is somehow a page param (used for search pagination), remove it
   * to prevent issues paginating after the form is submitted. This can occur
   * when the user clicks the browser refresh button (keeping the old URL) or
   * manually typing in the page param:
   */
  useEffect(() => {
    if (isInitialLoad) {
      const urlParams = new URLSearchParams(document.location.search);
      if (urlParams.get("page")) {
        navigate(Path.Search);
      }
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, navigate]);

  const geoJsonWKT = useMemo(() => {
    if (!geoJson || geoJson?.features.length === 0) return undefined;

    const mp = combineGeoJsonFeaturesIntoMultiPolygon(geoJson);
    const geometry = mp?.geometry;
    if (mp) {
      return `SRID=4326;${stringify(geometry)}`;
    }
    return "";
  }, [combineGeoJsonFeaturesIntoMultiPolygon, geoJson]);

  function loadGeoJsonSearchResults() {
    const gjoo = getGeoJsonAsOutlineOnly(geoJson);
    if (gjoo !== null) {
      setGeoJsonOutlineOnly(gjoo);

      // Clear existing geometry. This will cause the new outline only geometry
      // to be rendered since the layer count will be set to zero (see logic in
      // DrawLayer.tsx for how is done):
      setGeoJson(null);
      setDrawMode(DrawMode.LoadingSearchResults);
    }
  }

  // Called on submit, but only when the "Search" button is clicked:
  const onSearch = (values: any) => {
    const [startDate, endDate] = values[dateRangeConfig.name];
    let startsBefore, startsAfter, endsBefore, endsAfter;
    let orderBy = "most_recent";
    if (startDate && endDate) {
      switch (values[dateRangeStatusConfig.name]) {
        case "in-progress":
          endsAfter = format(startDate, BACKEND_DATE_FORMAT);
          startsBefore = format(endDate, BACKEND_DATE_FORMAT);
          orderBy = "start_date";
          break;
        case "starting":
          startsAfter = format(startDate, BACKEND_DATE_FORMAT);
          startsBefore = format(endDate, BACKEND_DATE_FORMAT);
          orderBy = "start_date";
          break;
        case "ending":
          endsAfter = format(startDate, BACKEND_DATE_FORMAT);
          endsBefore = format(endDate, BACKEND_DATE_FORMAT);
          orderBy = "end_date";
          break;
        default:
          break;
      }
    }

    const params = new FormData();

    if (values[searchByCustomBoundaryConfig.name]) {
      // When searching by Custom Boundaries:
      if (geoJsonWKT) {
        params.append("intersects", geoJsonWKT);
        loadGeoJsonSearchResults();
      }
    } else {
      // When searching by Location or Area:
      const { wkt } = values[addressConfig.name];
      let geom = wkt ? parse(wkt) : null;

      if (geom?.type === "Point") {
        // capture all projects within 250m if an address is chosen, ordering
        // by how close they are to the address
        orderBy = "near:" + wkt;
        geom = buffer(geom, 250, { units: "meters" });

        params.append("intersects", stringify(geom));
      } else if (wkt) {
        params.append("intersects", wkt);
      }
    }

    params.append("id", values[projectStageIdConfig.name] ?? "");
    params.append("title", values[projectStageTitleConfig.name] ?? "");
    params.append("reference", values[referenceConfig.name] ?? "");
    params.append("contact", values[contactConfig.name] ?? "");
    const organisations = values[organisationConfig.name];
    if (Array.isArray(organisations)) {
      organisations.forEach((organisation: string) => {
        params.append("organisation", organisation ?? "");
      });
    }
    params.append("starts_before", startsBefore ?? "");
    params.append("starts_after", startsAfter ?? "");
    params.append("ends_before", endsBefore ?? "");
    params.append("ends_after", endsAfter ?? "");
    const types = values[projectTypeConfig.name];
    if (Array.isArray(types)) {
      types.forEach((type: string) => {
        params.append("type", type ?? "");
      });
    }

    if (!values[completedProjectsConfig.name]) {
      params.append("state", "PLANNED");
      params.append("state", "IN_PROGRESS");
      params.append("state", "DEFERRED");
      params.append("state", "STALLED");
    }

    params.append("order_by", orderBy);

    // useQuery doesn't fit in the use case of fetching on click with params.
    // Can manually trigger with refetch() but doesn't accept params https://react-query.tanstack.com/guides/disabling-queries
    // Manipulating states to decide whether to run query adds unnecessary complexities.
    // Just making axios request is a simple and good solution.
    startFormSearch(params);
    toggleViews();
  };

  const onSave = async (values: any, helpers: FormikHelpers<any>) => {
    const startDate = values?.projectDateRange?.[0]
      ? format(values?.projectDateRange?.[0], BACKEND_DATE_FORMAT)
      : null;
    const endDate = values?.projectDateRange?.[1]
      ? format(values?.projectDateRange?.[1], BACKEND_DATE_FORMAT)
      : null;
    const data = {
      name: values?.savedSearchName,
      watchlist: values?.savedSearchWatchlist,
      id_num: values?.projectStageId ? Number(values.projectStageId) : null,
      shape: values?.searchByCustomBoundary ? geoJsonWKT : values?.address?.wkt,
      location: values?.address?.input,
      title: values?.projectStageTitle,
      reference: values?.reference,
      contact: values?.contact,
      organisations: values?.organisation,
      start_date: startDate && endDate ? startDate : null,
      end_date: startDate && endDate ? endDate : null,
      date_status: values?.dateRangeStatus,
      types: values?.projectType,
      include_completed: values?.completedProjects,
    } as SavedSearchData;

    const existingSearch = getSearch(data);
    const existingWatchlist = savedSearchWatchlists.find(
      (w: WatchlistSummary) => w.name === values?.savedSearchName
    );
    let completionState: SavedSearchState;

    try {
      if (existingSearch) {
        await updateSearch(data);
        completionState = SavedSearchState.Overwritten;
      } else {
        await saveSearch(data);
        completionState = SavedSearchState.Created;
      }
    } catch (error) {
      helpers.setErrors({ [savedSearchConfig.name]: "Failed to save search." });
      return;
    }

    await refreshSearches();

    // refetch any affected watchlists (no await as watchlists are not visible)
    if (values?.savedSearchWatchlist) {
      refetchWatchlists();
    } else if (existingWatchlist) {
      refetchWatchlists();
      invalidateWatchlistQuery(existingWatchlist.id);
    }

    helpers.setFieldValue(savedSearchConfig.name, completionState);
  };

  const onSubmit = (values: any, helpers: FormikHelpers<any>) => {
    switch (values[savedSearchConfig.name]) {
      case SavedSearchState.None:
        onSearch(values);
        break;
      case SavedSearchState.PromptName:
      case SavedSearchState.PromptOverwrite:
        onSave(values, helpers);
        break;
      default:
        // TODO: illegal state error
        break;
    }
  };

  const onReset = async () => {
    // Ensures that fields within non-Formik controlled components are also
    // cleared when the form is reset.
    // Optional clear condition props aim to de-couple components that manage
    // their own state from a particular form, so that we can keep those
    // re-usable.
    setClearCondition(true);
    endDrawingSession();
  };

  useEffect(() => {
    // Ensure that clear condition is reset after the prop is passed
    // down to the components that manage their own state:
    if (clearCondition) {
      setClearCondition(false);
    }
  }, [clearCondition, setClearCondition]);

  useEffect(() => {
    // Only once we've cleared the existing geoJson do we load new geoJson from
    // the temporary outline only geoJson state. This ensures that non-outline
    // only geometry is fully cleared to allow the outline only geometry to be
    // rendered:
    if (drawMode === DrawMode.LoadingSearchResults && geoJson === null) {
      setGeoJson(geoJsonOutlineOnly);
      setDrawMode(DrawMode.ViewSearchResults);

      // Clear the geoJsonOnlineOnly state now that it has been loaded into the
      // the global geoJson:
      setGeoJsonOutlineOnly(null);
    }
  }, [
    drawMode,
    geoJson,
    geoJsonOutlineOnly,
    setDrawMode,
    setGeoJson,
    setGeoJsonOutlineOnly,
  ]);

  /**
   * Using URL params to store form values would be nice, but it's hard for this form
   * because of the address search field, which has two values, and requires user interaction
   * (selecting from the list) to set the value. It also requires validation of the URL param
   * values.
   */

  return (
    <VisuallyHidden when={isHidden}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        <Form noValidate onKeyDown={onKeyDown}>
          <SavedSearch clearCondition={clearCondition} />

          <hr />

          <h3 className="h6">Search by attribute</h3>

          <Reference />
          <ProjectStageTitle />
          <ProjectStageId />
          <Organisation clearCondition={clearCondition} />
          <Contact />
          <ProjectType clearCondition={clearCondition} />
          <ProjectDateRange />

          <hr />

          <h3 className="h6">Search by location</h3>

          <Address clearCondition={clearCondition} />
          <SearchByCustomBoundary />

          <CompletedProjects />

          <div className="d-flex justify-content-between">
            <SavedSearchButton text="Save this search" />
            <div className="d-flex gap-3">
              <ResetButton text="Clear" />
              <SubmitButton text="Search" wide={false} />
            </div>
          </div>
        </Form>
      </Formik>
    </VisuallyHidden>
  );
};
