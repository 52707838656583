export enum Path {
  // root paths
  Index = "/",
  Dashboard = "/dashboard",
  Search = "/search",
  Layers = "/layers",
  MyOrganisation = "/my-organisation",
  Projects = "/projects",

  // projects
  AddProject = "/project/add",
  ProjectById = "/project/:projectId",
  EditProject = "/project/:projectId/edit",
  ProjectsAtPoint = "/projects/:lng/:lat",

  // stages
  Stages = "/project/:projectId/stages",
  AddStage = "/project/:projectId/stages/add",
  StageById = "/project/:projectId/stages/:stageId",
  EditStage = "/project/:projectId/stages/:stageId/edit",

  SearchResultsAtPoint = "/search/:lng/:lat",
}
