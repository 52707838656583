import { useMemo, useState, useCallback, useEffect } from "react";
import { useField } from "formik";
import { useCombobox, useMultipleSelection } from "downshift";
import { matchSorter } from "match-sorter";

import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import { Label } from "./Label";
import { SelectedItems } from "./SelectedItems";
import { ChevronDown } from "components/svg/Chevron";
import "./DropdownMultipleCombobox.scss";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  items: any[];
  itemToString?: (item: any) => string;
  getCustomClassNames?: (name: string) => string;
  onItemSelected?: (selectedItem: any) => void;
  clearCondition?: boolean;
}

// Based on this example
// https://www.downshift-js.com/use-multiple-selection#usage-with-combobox
export const DropdownMultipleCombobox = ({
  label,
  name,
  placeholder = "",
  required = false,
  items,
  itemToString = (item: string) => item,
  getCustomClassNames,
  onItemSelected = undefined,
  clearCondition,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error;
  const { value: selectedItems } = field;
  const { setValue } = helpers;

  const [inputValue, setInputValue] = useState("");
  const { getSelectedItemProps, getDropdownProps, setSelectedItems } =
    useMultipleSelection({ itemToString });

  const addSelectedItem = useCallback(
    (item: any) => {
      if (!selectedItems.includes(item)) {
        setValue([...selectedItems, item]);
      }
    },
    [selectedItems, setValue]
  );

  const removeSelectedItem = useCallback(
    (item: any) => {
      setValue(
        (selectedItems as any[]).reduce(
          (prev, current) => (item === current ? prev : [...prev, current]),
          []
        )
      );
    },
    [selectedItems, setValue]
  );

  const filteredItems = useMemo(() => {
    const selectedItemsExcluded = items.filter(
      (item) => selectedItems.indexOf(item) < 0
    );
    return matchSorter(selectedItemsExcluded, inputValue.toLowerCase(), {
      // Respect original order
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: [(item) => itemToString(item)],
    });
  }, [inputValue, itemToString, items, selectedItems]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: filteredItems,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue ?? "");
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            setInputValue("");
            onItemSelected
              ? onItemSelected(selectedItem)
              : addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    const items = (selectedItems as string[]).map((name) =>
      filteredItems.find((item) => itemToString(item) === name)
    );
    setSelectedItems(items);
  }, [selectedItems, filteredItems, setSelectedItems, itemToString]);

  useEffect(() => {
    if (clearCondition) {
      setInputValue("");
      setSelectedItems([]);
    }
  }, [clearCondition, setInputValue, setSelectedItems]);

  return (
    <FieldWrapper>
      <Label {...getLabelProps()} label={label} required={required} />

      <SelectedItems
        selectedItems={selectedItems}
        getSelectedItemProps={getSelectedItemProps}
        removeSelectedItem={removeSelectedItem}
        itemToString={itemToString}
      />

      <div {...getComboboxProps()} className="position-relative">
        <input
          {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
          className="form-control"
          name={name}
          placeholder={placeholder}
        />
        <button
          {...getToggleButtonProps()}
          aria-label="toggle menu"
          className="form-input-icon-button position-absolute top-50 translate-middle-y px-2"
          type="button"
        >
          <ChevronDown />
        </button>
      </div>

      <div className="position-relative">
        <ul {...getMenuProps()} className="list-group position-absolute w-100">
          {isOpen && (
            <div
              className="border rounded overflow-y-scroll"
              style={{
                maxHeight: "190px",
                zIndex: 100, // Just needed something to not go behind other form inputs
              }}
            >
              {filteredItems.length > 0 &&
                filteredItems.map((item, index) => (
                  <li
                    {...getItemProps({ item, index })}
                    key={`${item}${index}`}
                    className={
                      "list-group-item" +
                        (highlightedIndex === index ? " active" : "") +
                        " " +
                        getCustomClassNames?.(item) || ""
                    }
                  >
                    {itemToString(item)}
                  </li>
                ))}
            </div>
          )}
          {isOpen && filteredItems.length < 1 && (
            <li className="list-group-item text-muted">No matching options</li>
          )}
        </ul>
      </div>
      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
