import { CloseButton } from "./CloseButton";

export const PanelBody = ({
  children,
  hideCloseButton = false,
  onClose,
}: {
  children: React.ReactNode;
  hideCloseButton?: boolean;
  onClose?: (() => void) | null;
}) => (
  <>
    <div className="panel-body-wrapper">
      <div className="panel-body vh-100 overflow-y-scroll p-3">{children}</div>
    </div>
    {!hideCloseButton && <CloseButton onClick={onClose} />}
  </>
);
