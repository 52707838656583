import { format, parse } from "date-fns";
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from "utils/constants/date";
import { InternalProjectIcon } from "components/project/InternalProjectIcon";
import { LayerProperties } from "./VectorGrid";

interface ProjectPopupProps {
  properties: LayerProperties;
}

const formatDate = (date: string) => {
  try {
    // do nothing for the range error when the `date` is not in the expected shape.
    // we just return an empty string.
    return format(parse(date, BACKEND_DATE_FORMAT, new Date()), DATE_FORMAT);
  } catch {}
  return "";
};

export const ProjectPopup = ({ properties }: ProjectPopupProps) => {
  const { project_title, org_name, start_date, end_date } = properties;
  return (
    <div>
      <div className="d-flex gap-2">
        <strong>{project_title}</strong>
        <InternalProjectIcon project={properties} small />
      </div>
      <div>{org_name}</div>
      <div>
        {formatDate(start_date)} - {formatDate(end_date)}
      </div>
    </div>
  );
};
