import { useCallback } from "react";
import { useMap } from "react-leaflet";

export const useMapToggle = () => {
  const map = useMap();

  const enable = useCallback(() => {
    map.dragging.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
  }, [map]);

  const disable = useCallback(() => {
    map.dragging.disable();
    map.keyboard.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
  }, [map]);

  return [enable, disable];
};
