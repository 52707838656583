import { useDjangoQuery } from "./useDjangoQuery";

type Contact = {
  email: string;
  name: string;
  phone_num: string | null;
};
type Contacts = ReadonlyArray<Contact>;

export const useProjectContactsQuery = () =>
  useDjangoQuery<Contacts>("/api/projectcontacts/");

export const useProjectContactNames = () => {
  const { data } = useProjectContactsQuery();
  const names = data?.map((contact) => contact.name);
  // get case-insensitive unique values by using Map and setting its key to the lower case version of the string
  const unique = new Map(names?.map((s) => [s.toLowerCase(), s]));
  // then get just the values
  const uniqueNames = [...unique.values()];
  // sort, case-insensitive
  const sorted = uniqueNames.sort((a, b) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    if (aLower < bLower) return -1;
    if (bLower < aLower) return 1;
    return 0;
  });
  return sorted;
};
