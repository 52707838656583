import { infrastructureTypeColours } from "utils/constants/colours";
import { InfrastructureType } from "utils/types/django";
import "./InfrastructureLegend.scss";

export const CompletedInfrastructureLegend = ({
  infrastructure,
}: {
  infrastructure: string;
}) => {
  const colour =
    infrastructureTypeColours[infrastructure as InfrastructureType];
  return (
    <div className="ms-3 text-small fst-italic">
      <div className="d-flex align-items-center mb-1">
        <div
          className="infrastructure-legend me-2"
          style={{
            borderColor: colour,
            backgroundImage: `repeating-linear-gradient(
                45deg,
                transparent 0 2px,
                ${colour} 2px 3.5px
              )`,
          }}
        ></div>
        <div>Completed</div>
      </div>
    </div>
  );
};
