import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { ComboBox } from "components/form/ComboBox";
import { useOrganisationNames } from "components/fetch/useOrganisationsQuery";

export const fieldName = "organisation";

export const useOrganisationConfig = (): FormFieldConfig => {
  const organisationNames = useOrganisationNames();
  return {
    name: fieldName,
    initialValue: "",
    validationSchema: Yup.string().when("newOrganisation", {
      is: (newOrganisation: string) => !newOrganisation,
      then: Yup.string()
        .required(
          "Please select an organisation from the list, or enter a new organisation name."
        )
        .oneOf(
          organisationNames,
          "Please select an organisation from the list, or enter a new organisation name."
        ),
      otherwise: Yup.string(),
    }),
    noSortEdges: [[fieldName, "newOrganisation"]],
  };
};

interface OrganisationProps {
  newOrganisationValue: string;
}

export const Organisation = ({ newOrganisationValue }: OrganisationProps) => {
  const organisationNames = useOrganisationNames();
  return (
    <ComboBox
      label="Company / Organisation"
      name={fieldName}
      items={organisationNames}
      placeholder="Start typing to find your organisation"
      disabled={!!newOrganisationValue}
      required
    />
  );
};
