import Keycloak from "keycloak-js";
import Cookies, { CookieAttributes } from "js-cookie";

let url, redirectUri, domain;
let realm = "forward-works-viewer";

switch (window.location.hostname) {
  case "localhost":
    url = "http://localhost:8080";
    redirectUri = "http://localhost:9000";
    break;
  case "uat.forwardworks.co.nz":
    url = "https://id.forwardworks.co.nz";
    redirectUri = "https://uat.forwardworks.co.nz";
    domain = "uat.forwardworks.co.nz";
    realm = "forward-works-viewer-uat";
    break;
  case "forwardworks.co.nz":
  case "www.forwardworks.co.nz":
    url = "https://id.forwardworks.co.nz";
    redirectUri = "https://forwardworks.co.nz";
    domain = "forwardworks.co.nz";
    break;
}

export const authTokenName = "fwv-auth";
const refreshTokenName = "fwv-refresh";
const authTokenAttributes: CookieAttributes = {
  secure: true,
  sameSite: "None",
  domain,
};

const keycloak = new Keycloak({
  url,
  realm,
  clientId: "frontend",
});

const updateTokens = () => {
  if (keycloak.token) {
    Cookies.set(authTokenName, keycloak.token, authTokenAttributes);
  }

  if (keycloak.refreshToken) {
    localStorage.setItem(refreshTokenName, keycloak.refreshToken);
  }
};

export const clearTokens = () => {
  Cookies.remove(authTokenName, authTokenAttributes);
  localStorage.removeItem(refreshTokenName);
};

export const refreshTokens = async () => {
  if (keycloak.refreshToken) {
    try {
      const success = await keycloak.updateToken(-1);
      updateTokens();
      return success;
    } catch (error) {
      console.error("Failed to refresh authentication token:", error);
    }
  }
  return false;
};

keycloak.onAuthSuccess = updateTokens;
keycloak.onAuthError = clearTokens;
keycloak.onAuthRefreshSuccess = updateTokens;
keycloak.onAuthRefreshError = clearTokens;
keycloak.onTokenExpired = refreshTokens;

keycloak
  .init({
    redirectUri,
    flow: "hybrid",
    token: Cookies.get(authTokenName),
    refreshToken: localStorage.getItem(refreshTokenName) ?? undefined,
    checkLoginIframe: false,
  })
  .catch((error: unknown) => error && console.error(error));

export default keycloak;
