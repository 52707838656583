import classNames from "classnames";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { Stage } from "utils/types/django";
import { ReactComponent as AlertIcon } from "assets/other-icons/alert.svg";

export const ClashButton = ({
  active = false,
  disabled = false,
  stageId,
  onClick,
}: {
  active: boolean;
  disabled: boolean;
  stageId?: number;
  onClick?: () => void;
}) => {
  const { selectedProject, selectClop } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  const count = stageId
    ? project?.stages.find((s: Stage) => s.id === stageId)?.clashes.length
    : project?.stages.reduce(
        (prev: number, s: Stage) => s.clashes.length + prev,
        0
      );
  const hasClashes = (count ?? 0) > 0;
  const buttonClasses = classNames("btn", "btn-clop", "btn-clop-clash", {
    "has-count": hasClashes,
    active: active,
  });
  const iconClasses = classNames("icon-clash", {
    active: hasClashes,
  });

  return (
    <button
      type="button"
      onClick={() => {
        selectClop(null);
        onClick && onClick();
      }}
      className={buttonClasses}
      disabled={disabled || !hasClashes}
    >
      <div className="d-flex gap-1">
        <AlertIcon
          width={16}
          height={24}
          className={iconClasses}
          aria-label="Clashing projects"
        />
        {count}
      </div>
    </button>
  );
};
