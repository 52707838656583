import classNames from "classnames";
import { useSelectedProject } from "components/project/useSelectedProject";
import { LeftHeaderRow, LeftHeaderTable } from "components/table";
import { ProjectOverview } from "utils/types/django";
import { ReactComponent as CalendarIcon } from "assets/other-icons/calendar.svg";
import "./ProjectOverviewTable.scss";
import { InternalProjectIcon } from "components/project/InternalProjectIcon";

export const ProjectOverviewTable = ({
  project,
}: {
  project: ProjectOverview;
}) => {
  const { selectedClop, selectClop } = useSelectedProject();
  const isSelected = project.id === selectedClop;

  return (
    <div
      className={classNames("clop-list-item p-2 border rounded", {
        active: isSelected,
      })}
    >
      <LeftHeaderTable>
        <LeftHeaderRow
          header="Project name"
          data={
            <div className="d-flex gap-2">
              {project.title}
              <InternalProjectIcon project={project} small />
            </div>
          }
        />
        <LeftHeaderRow header="Organisation" data={project.organisation.name} />
        <LeftHeaderRow
          header={
            <div className="d-flex align-items-center gap-2">
              <CalendarIcon width={16} height={19} aria-label="Calendar" />{" "}
              Start date
            </div>
          }
          data={project.start_date}
        />
        <LeftHeaderRow
          header={
            <div className="d-flex align-items-center gap-2">
              <CalendarIcon width={16} height={19} aria-label="Calendar" /> End
              date
            </div>
          }
          data={project.end_date}
        />
      </LeftHeaderTable>
      <div className="mt-1">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => selectClop(project.id)}
        >
          View project
        </button>
      </div>
    </div>
  );
};
