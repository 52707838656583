import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { PasswordField } from "components/form/PasswordField";

const fieldName = "password";
const maxLength = 100;

export const passwordConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().when("sso", {
    is: false,
    then: Yup.string().required("Please enter a password."),
    otherwise: Yup.string(),
  }),
};

export const Password = () => (
  <PasswordField
    label="Password"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
