import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";
import { fieldName as emailFieldName } from "./Email";

const fieldName = "rememberMe";

export const useRememberMeConfig = (): FormFieldConfig => {
  const isSaved = !!localStorage.getItem(emailFieldName);
  return {
    name: fieldName,
    initialValue: isSaved,
  };
};

export const RememberMe = () => (
  <CheckBox label="Remember me" name={fieldName} />
);
