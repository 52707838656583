import { Formik, Form } from "formik";
import { AxiosError } from "axios";
import { buildFormConfig } from "utils/form";
import { Email, useEmailConfig } from "./formFields/Email";
import { passwordConfig } from "./formFields/Password";
import { useRememberMeConfig } from "./formFields/RememberMe";
import { SingleSignOn, ssoConfig } from "./formFields/SingleSignOn";
import { useAuth } from "components/auth/useAuth";
import { useErrorModal } from "components/modal/useErrorModal";
import { replaceText } from "utils/replaceText";
import { AUTH_MESSAGE_REPLACE_LIST } from "utils/constants/messages";
import { SubmitButton } from "components/form/SubmitButton";

export const LoginForm = () => {
  const emailConfig = useEmailConfig();
  const rememberMeConfig = useRememberMeConfig();
  const { initialValues, validationSchema } = buildFormConfig(
    emailConfig,
    passwordConfig,
    rememberMeConfig,
    ssoConfig
  );

  const { login } = useAuth();
  const { setErrorModal } = useErrorModal();

  const onSubmit = async (values: any, actions: any) => {
    const email = values[emailConfig.name];
    const password = values[passwordConfig.name];
    const rememberMe = values[rememberMeConfig.name];
    const sso = values[ssoConfig.name];

    if (rememberMe) {
      localStorage.setItem(emailConfig.name, email);
    } else {
      localStorage.removeItem(emailConfig.name);
    }

    if (sso) {
      try {
        await login({ email });
      } catch (error) {
        actions.setFieldValue(ssoConfig.name, false, false); // display password field
      }
    } else {
      try {
        await login({ email, password });
      } catch (error) {
        let errorMessage = "Something went wrong. Please try again later.";
        if (error instanceof AxiosError && error.response?.status === 403) {
          const backendMessage = error.response?.data.detail as string;
          errorMessage = backendMessage
            ? replaceText(backendMessage, AUTH_MESSAGE_REPLACE_LIST)
            : errorMessage;
        }
        setErrorModal({
          title: "Login failed",
          content: errorMessage,
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form noValidate>
        <Email />
        <SingleSignOn />
        <SubmitButton text="Log in" />
      </Form>
    </Formik>
  );
};
