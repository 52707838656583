import { useMemo } from "react";
import {
  projectsPerRequestToPopulateMap,
  FormSearch,
} from "components/project/searched-projects/SearchedProjectsContext";
import ProgressBar from "components/progressBar/ProgressBar";
import "./PaginationLoading.scss";

interface PaginationLoadingProps {
  totalProjectsCount: number;
  currentSearch: FormSearch | null;
}

export function PaginationLoading({
  totalProjectsCount,
  currentSearch,
}: PaginationLoadingProps) {
  const loadedRequests = useMemo(() => {
    return (
      (currentSearch?.totalRequests || 0) -
      (currentSearch?.remainingRequests || 0) +
      1
    );
  }, [currentSearch]);

  const loadedProjects = useMemo(() => {
    const projectsLoadedRoundedUp =
      (currentSearch?.accumulatedResults?.length || 0) *
        projectsPerRequestToPopulateMap +
      projectsPerRequestToPopulateMap;
    return projectsLoadedRoundedUp < totalProjectsCount
      ? projectsLoadedRoundedUp
      : totalProjectsCount;
  }, [currentSearch, totalProjectsCount]);

  return (
    <>
      <p className="project-pagination-loading disabled">
        Loading projects -{" "}
        {currentSearch?.remainingRequests
          ? `${loadedProjects} / ${totalProjectsCount}`
          : ""}
      </p>
      <ProgressBar
        currentProgress={loadedRequests || 0}
        completionValue={currentSearch?.totalRequests || 0}
      />
    </>
  );
}
