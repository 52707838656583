import { infrastructureTypeColours } from "utils/constants/colours";
import { InfrastructureType } from "utils/types/django";
import "./InfrastructureLegend.scss";

export const InfrastructureLegend = ({
  infrastructure,
}: {
  infrastructure: string;
}) => {
  const colour =
    infrastructureTypeColours[infrastructure as InfrastructureType];
  return (
    <div className="ms-3 text-small fst-italic">
      <div className="d-flex align-items-center mb-1">
        <div
          className="infrastructure-legend me-2"
          style={{
            borderColor: colour,
            background: `${colour}99`,
          }}
        ></div>
        <div>Planned</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          className="infrastructure-legend me-2"
          style={{
            borderColor: "#000",
            background: `${colour}99`,
          }}
        ></div>
        <div>In Progress</div>
      </div>
    </div>
  );
};
