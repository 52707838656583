import { Impact } from "utils/types/django";
import { useImpactTypesQuery } from "components/fetch/useImpactTypesQuery";
import { useImpactTimeframesQuery } from "components/fetch/useImpactTimeframesQuery";
import "./ImpactsInfo.scss";

export const ImpactsInfo = ({ impacts }: { impacts: Array<Impact> }) => {
  const { data: types } = useImpactTypesQuery();
  const { data: timeframes } = useImpactTimeframesQuery();

  return (
    <div className="mt-2">
      <div className="fw-700 text-small impact-label">Impacts</div>
      <div className="text-small">
        {(!impacts || impacts?.length < 1) && <div>No impacts</div>}
        <div>
          <table className="w-100">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "50%" }} />
            </colgroup>
            <tbody>
              {impacts?.map((impact) => (
                <tr key={impact.id}>
                  <td>{types?.[impact.impact_type]}</td>
                  <td className="fst-italic">
                    {timeframes?.[impact.time_of_effect]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
