import React from "react";
import { useField, FieldHookConfig } from "formik";
import { FieldWrapper } from "./FieldWrapper";
import { FieldError } from "./FieldError";
import "./Select.scss";
import { Label } from "components/form/Label";

interface Props {
  label: string;
  hideLabel?: boolean;
  noWrapper?: boolean;
}

export const Select = (
  props: Props & FieldHookConfig<string> & React.HTMLProps<HTMLSelectElement>
) => {
  const { label, required, hideLabel, noWrapper, ...rest } = props;
  const [field, meta] = useField(rest);
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;
  return (
    <FieldWrapper noWrapper={noWrapper}>
      <Label
        htmlFor={id}
        label={label}
        required={required}
        hidden={hideLabel}
      />
      <select
        {...rest}
        {...field}
        id={id}
        className="form-select form-select-field2"
        required={false} // Don't want browser default validation
      />
      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
