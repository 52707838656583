import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";

export const fieldName = "acceptTermsOfUse";

export const acceptTermsOfUseConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: false,
  validationSchema: Yup.bool().oneOf([true], "Please accept the Terms of Use."),
};

export const AcceptTermsOfUse = () => (
  <CheckBox
    label={
      <span>
        Please accept the{" "}
        <a
          href="https://www.forwardworks.org.nz/termsofuse.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
      </span>
    }
    name={fieldName}
  />
);
