import { PromptModal } from "components/modal/PromptModal";
import { Project } from "utils/types/django";

interface ShareProjectInfoModalProps {
  project: Project;
  show: boolean;
  onCancel: () => void;
}

export const ShareProjectInfoModal = ({
  project,
  show,
  onCancel,
}: ShareProjectInfoModalProps) => {
  if (!project.is_internal) {
    return null;
  }

  const content =
    project.shared_organisations.length > 0 ? (
      <>
        <strong>
          This project has been shared with the following organisations:
        </strong>
        <ul className="overflow-y-scroll max-vh-25 mt-2">
          {project.shared_organisations.map((organisation: string) => (
            <li>{organisation}</li>
          ))}
        </ul>
      </>
    ) : (
      <>
        This is an internal project visible only to your organisation. It can
        be shared to other organisations but only by users with specific
        permissions - please either speak to your organisation's Forward Works
        Viewer champion, or contact{" "}
        <a href="mailto:support@forwardworks.co.nz">
          support@forwardworks.co.nz
        </a>{" "}
        for more information.
      </>
    );

  return (
    <PromptModal show={show} content={content}>
      <button className="btn btn-primary" onClick={onCancel}>
        Close
      </button>
    </PromptModal>
  );
};
