import { useState } from "react";

export enum ClashOpportunityState {
  None,
  Clashes,
  Opportunities,
  Collaborations,
}

export const useClashOpportunityState = () =>
  useState<ClashOpportunityState>(ClashOpportunityState.None);
