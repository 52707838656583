import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { PasswordField } from "components/form/PasswordField";

const maxLength = 100;

export const useNewPasswordConfig = (): FormFieldConfig => ({
  name: "newPassword",
  initialValue: "",
  validationSchema: Yup.string()
    .required("Please enter a password.")
    .min(8, "Password must be at least 8 characters long.")
    .matches(/^.*[A-Z].*$/, "Password must contain at least 1 capital letter.")
    .matches(/^.*\d.*$/, "Password must contain at least 1 number."),
});

export const useConfirmPasswordConfig = (): FormFieldConfig => ({
  name: "confirmPassword",
  initialValue: "",
  validationSchema: Yup.string()
    .required("Please re-enter your password.")
    .oneOf(
      [Yup.ref("newPassword")],
      "The passwords do not match. Please try again."
    ),
});

export const NewPassword = () => (
  <PasswordField
    label="New password"
    name="newPassword"
    maxLength={maxLength}
    required
  />
);

export const ConfirmPassword = () => (
  <PasswordField
    label="Confirm password"
    name="confirmPassword"
    maxLength={maxLength}
    required
  />
);
