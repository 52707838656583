import { Link } from "react-router-dom";

export const SuccessMessage = () => (
  <div className="mt-4">
    <p>
      Thanks for signing up. Your details have been sent to a FWV administrator.
    </p>
    <p>
      You should receive an email with your account information shortly - please
      note we aim to have your account processed and approved within 24 business
      hours.
    </p>
    <p>
      If you have not received this email within 24 hours then please check your
      spam folder, otherwise{" "}
      <a href="mailto:support@forwardworks.co.nz">contact support</a>.
    </p>
    <Link to="/login">Return to log in</Link>
  </div>
);
