import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";

interface InitialValues {
  [index: string]: any;
}
interface ValidationSchema {
  [index: string]: any;
}

export const buildFormConfig = (...fieldConfigs: FormFieldConfig[]) => {
  const initialValues: InitialValues = {};
  const validationSchema: ValidationSchema = {};
  let noSortEdges: [string, string][] = [];
  fieldConfigs.forEach((field) => {
    initialValues[field.name] = field.initialValue;
    if (field.validationSchema) {
      validationSchema[field.name] = field.validationSchema;
    }
    if (field.noSortEdges && field.noSortEdges.length > 0) {
      noSortEdges = noSortEdges.concat(field.noSortEdges);
    }
  });
  return {
    initialValues,
    validationSchema: Yup.object().shape(validationSchema, noSortEdges),
  };
};
