import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "reference";
const maxLength = 50;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required(
    "Please enter your project reference."
  ),
};

export const Reference = () => (
  <TextField
    label="Your project reference"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
Reference.config = config;
