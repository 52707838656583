import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { format, parse } from "date-fns";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import { GeoJSON } from "components/drawLayer/DrawLayerProvider";
import { VisuallyHidden } from "components/layout/VisuallyHidden";
import { EditProjectSummaryForm } from "./EditProjectSummaryForm";
import { ProjectDetailForm } from "./ProjectDetailForm";
import { useDrawLayer, DrawMode } from "components/drawLayer/DrawLayerProvider";
import { DATE_FORMAT } from "utils/constants/date";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useProjecttypesQuery } from "components/fetch/useProjecttypesQuery";
import { useErrorModal } from "components/modal/useErrorModal";
import { LoadingModal } from "components/loading/LoadingModal";
import { replaceText } from "utils/replaceText";
import { useProject } from "components/project/ProjectProvider";
import { PanelBody } from "../PanelBody";
import { durationFromStages } from "utils/date";
import { NavPromptModal } from "components/navBlocker/NavPromptModal";
import { useNavPrompt } from "components/navBlocker/useNavPrompt";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { PanelID, usePanels } from "components/panel/PanelsProvider";
import { useMyOrganisationsProjects } from "components/project/my-organisation/MyOrganisationsProjectsContext";

export const EditProjectPanel = () => {
  const [page, setPage] = useState(0);
  const [pageValues, setPageValues] = useState([] as any[]);
  const [isSaving, setIsSaving] = useState(false);

  const { selectedProject } = useSelectedProject();
  const { data: project, isLoading } = useProjectByIdQuery(selectedProject);
  const isMultiStage = !!project?.is_multistage;
  const { data: projectTypes } = useProjecttypesQuery();
  const { geoJson, setGeoJson, setDrawMode } = useDrawLayer();
  const navigate = useNavigate();
  const isOnEditProjectPath = useMatchPath(Path.EditProject);

  const { expandPanel, isClosed } = usePanels();

  // noGeom only supports single-stage projects; assume all multi-stage projects have geometry
  const hasGeom = useMemo(
    () => project?.is_multistage || !!project?.stages?.[0]?.shape,
    [project]
  );

  // Initialise form values with project data on load
  useEffect(() => {
    if (pageValues.length > 0) return; // Already initialised
    if (!project || !project.stages?.[0] || !projectTypes) return; // Missing required data

    const initialSummaryPageValues = {
      projectName: project.title || "",
      reference: project.reference || "",
      projectDuration: [
        parse(project.start_date, DATE_FORMAT, new Date()),
        parse(project.end_date, DATE_FORMAT, new Date()),
      ],
      contactName: project.contact?.name || "",
      contactPhone: project.contact?.phone_num || "",
      contactEmail: project.contact?.email || "",
      projectType: replaceText(project.project_type, [
        { from: "Horizontal Construction", to: "Horizontal" },
        { from: "Vertical Construction", to: "Vertical" },
      ]),
      infrastructureType: replaceText(
        project.infrastructure_type,
        projectTypes.map((type) => ({
          from: type.display_name,
          to: type.name,
        }))
      ),
      projectDetails: project.details || "",
    };
    // Only single-stage project has next page to be initialised.
    // All fields need initialisation so that it can be saved without going next page.
    const stage = project.stages[0];
    const initialDetailPageValues = isMultiStage
      ? {}
      : {
          stageName: stage.title || "",
          stageDescription: stage.description || "",
          stageDuration: [
            parse(project.start_date, DATE_FORMAT, new Date()),
            parse(project.end_date, DATE_FORMAT, new Date()),
          ],
          trafficImpacts:
            stage.impacts?.map((impact) => ({
              type: impact.impact_type,
              timeframe: impact.time_of_effect,
            })) || [],
        };
    setPageValues([initialSummaryPageValues, initialDetailPageValues]);
  }, [isMultiStage, pageValues.length, project, projectTypes, setGeoJson]);

  // Only enter draw polygon mode on details panel
  useEffect(() => {
    if (page === 1) {
      setDrawMode(DrawMode.CreateStageBoundary);
    } else {
      setDrawMode(DrawMode.Inactive);
    }
    return () => {
      setDrawMode(DrawMode.Inactive);
    };
  }, [page, setDrawMode]);

  // Initialise geoJson for edit
  useEffect(() => {
    const shape = project?.stages?.[0]?.shape as GeoJSON;
    if (!isMultiStage && shape) {
      setGeoJson(shape);
    }
    // Clean up saved GeoJSON on unmount
    return () => {
      setGeoJson(null);
    };
  }, [isMultiStage, setGeoJson, project?.stages]);

  const { updateProject } = useProject();
  const { refetch: refetchThisProject } = useProjectByIdQuery(project?.id!);
  const { refetch: refetchMyOrgProjects } = useMyOrganisationsProjects();
  const { invalidateWatchlistQueries } = useWatchlists();
  const { shouldPrompt, forceNavigate } = useNavPrompt();
  const { setErrorModal } = useErrorModal();

  const returnToProjectPanel = () => {
    forceNavigate(`/project/${selectedProject}`);
  };

  const onSubmit = async (values: any) => {
    const newPageValues: any[] = [...pageValues];
    newPageValues[page] = values;

    if (isMultiStage) {
      const [maxStartDate, minEndDate] = durationFromStages(project?.stages!);
      const [startDate, endDate] = newPageValues[0]?.projectDuration;

      if (startDate > maxStartDate) {
        setErrorModal({
          title: "Invalid start date",
          content:
            "Project must start earlier than its first stage (or on the same day).",
        });
        return;
      } else if (endDate < minEndDate) {
        setErrorModal({
          title: "Invalid end date",
          content:
            "Project must end later than its last stage (or on the same day).",
        });
        return;
      }
    } else {
      // Check geoJson for singleStage project
      if (!geoJson || !geoJson.features || geoJson.features.length < 1) {
        setErrorModal({
          title: "No boundary set",
          content: "Please add at least one boundary before saving.",
        });
        return;
      }
    }

    setIsSaving(true);

    try {
      await updateProject(project!, {
        title: newPageValues[0].projectName,
        details: newPageValues[0].projectDetails,
        reference: newPageValues[0].reference,
        infrastructure_type: newPageValues[0].infrastructureType.toLowerCase(),
        start_date: format(newPageValues[0].projectDuration[0], DATE_FORMAT),
        end_date: format(newPageValues[0].projectDuration[1], DATE_FORMAT),
        contact: {
          name: newPageValues[0].contactName,
          email: newPageValues[0].contactEmail,
          phone_num: newPageValues[0].contactPhone,
        },
        stages: isMultiStage
          ? project?.stages.map((stage) => ({
              // If it's multi-stage, we don't update stages so just copy existing data.
              // Include only what's required. (Omit shape and last_updated)
              id: stage.id,
              title: stage.title,
              description: stage.description,
              start_date: stage.start_date,
              end_date: stage.end_date,
              impacts: stage.impacts,
            }))
          : [
              // If it's single-stage, update stage with form data.
              {
                id: project?.stages?.[0]?.id,
                title: newPageValues[1].stageName,
                description: newPageValues[1].stageDescription,
                start_date: format(
                  newPageValues[0].projectDuration[0],
                  DATE_FORMAT
                ),
                end_date: format(
                  newPageValues[0].projectDuration[1],
                  DATE_FORMAT
                ),
                shape: geoJson,
                impacts: newPageValues[1].trafficImpacts.map(
                  (
                    impact: { type: string; timeframe: string },
                    index: number
                  ) => ({
                    id: project?.stages?.[0]?.impacts?.[index]?.id, // Reuse ids if exist
                    impact_type: impact.type,
                    time_of_effect: impact.timeframe,
                  })
                ),
              },
            ],
      });
      setErrorModal({
        title: "Project saved",
        content: "Project successfully updated.",
      });
      refetchThisProject();
      refetchMyOrgProjects();
      invalidateWatchlistQueries();
      returnToProjectPanel();
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onNext = useCallback(
    (values: any) => {
      const newPageValues: any[] = [...pageValues];
      newPageValues[page] = values;
      setPageValues(newPageValues);
      setPage(page + 1);
    },
    [page, pageValues]
  );

  const onBack = (values: any) => {
    if (!hasGeom) {
      navigate(-1);
      return;
    }

    const newPageValues: any[] = [...pageValues];
    newPageValues[page] = values;
    setPageValues(newPageValues);
    setPage(page - 1);
  };

  useEffect(() => {
    // skip summary form if adding geometry to a project with no geometry
    if (!hasGeom && page === 0 && pageValues?.[0]) {
      onNext(pageValues[0]);
    }
  }, [page, pageValues, hasGeom, onNext]);

  useEffect(() => {
    if (isOnEditProjectPath && isClosed(PanelID.EditProject)) {
      expandPanel(PanelID.EditProject);
    }
  }, [expandPanel, isClosed, isOnEditProjectPath]);

  return (
    <PanelBody onClose={returnToProjectPanel}>
      <h2 className="h3">
        {/* Editing the project boundary should only be possible on the second page. */}
        {page === 0 ? "Edit project" : "Edit project boundary"}
      </h2>

      {(isLoading || isSaving) && <LoadingModal />}
      {project &&
        pageValues.length > 0 && ( // Don't render form until form values are initialised
          <>
            <VisuallyHidden when={page !== 0}>
              <EditProjectSummaryForm
                pageValues={pageValues[0]}
                isMultiStage={isMultiStage}
                onNext={onNext}
                onSubmit={onSubmit}
                project={project}
              />
            </VisuallyHidden>

            <VisuallyHidden when={page !== 1}>
              <ProjectDetailForm
                pageValues={pageValues}
                isMultiStage={isMultiStage}
                onBack={onBack}
                onSubmit={onSubmit}
              />
            </VisuallyHidden>
          </>
        )}

      <NavPromptModal
        enabled={shouldPrompt}
        title="Are you sure?"
        content="Do you want to close edit project form? Unsaved data will be lost."
        onConfirm={returnToProjectPanel}
      />
    </PanelBody>
  );
};
