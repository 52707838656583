import classNames from "classnames";

interface AccordionProps {
  id: string;
  title: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  underline?: boolean;
  padding?: boolean;
}

export const Accordion = ({
  id,
  title,
  children,
  defaultExpanded = false,
  underline = true,
  padding = true,
}: AccordionProps) => (
  <div className="accordion">
    <button
      className={classNames("accordion-button", {
        "border-bottom": underline,
        "border-2": underline,
        "border-dark": underline,
        "py-0": !padding,
        collapsed: !defaultExpanded,
      })}
      type="button"
      data-bs-toggle="collapse"
      data-bs-target={`#${id}`}
      aria-expanded={defaultExpanded ? "true" : "false"}
      aria-controls={id}
    >
      {title}
    </button>
    <div
      id={id}
      className={classNames("accordion-collapse", "collapse", {
        show: defaultExpanded,
      })}
    >
      <div className="accordion-body">{children}</div>
    </div>
  </div>
);
