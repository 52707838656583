import { ReactNode } from "react";
import { useErrorModal } from "components/modal/useErrorModal";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import "./PaginationContainer.scss";

interface PaginationContainerProps {
  pageCount: number;
  currentPageNumber: number;
  children: ReactNode | ReactNode[];
}

export function PaginationContainer({
  pageCount,
  currentPageNumber,
  children,
}: PaginationContainerProps) {
  const navigate = useNavigate();
  const { setErrorModal } = useErrorModal();

  // Don't show the pagination if there is only one, or no pages.
  if (pageCount <= 1) return null;

  // Prevent user from navigating to a page greater than the current page:
  if (currentPageNumber > 0 && currentPageNumber > pageCount) {
    setErrorModal({
      title: "Page not found",
      content: `Page ${currentPageNumber} (specified in the URL) is greater than the number of available pages.`,
    });
    navigate(Path.Index);
    return null;
  }

  return <div className="project-pagination-container">{children}</div>;
}
