import { ReactComponent as MapIcon } from "assets/control-icons/map-with-marker-icon.svg";
import { useMyOrganisationsProjectsMapFilter } from "./MyOrganisationsProjectsMapFilterProvider";

export const MyOrganisationsProjectsToggleButton = () => {
  const { enabled, toggle } = useMyOrganisationsProjectsMapFilter();

  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-view-projects"
      title={
        enabled
          ? "Show all organisation's projects on the map"
          : "Show only my organisation's projects on the map"
      }
      aria-pressed={enabled}
      onClick={(e) => {
        toggle();
        e.currentTarget.blur();
      }}
    >
      <MapIcon style={{ width: 20 }} />
    </button>
  );
};
