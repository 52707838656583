import { createContext, useCallback, useContext, useState } from "react";

interface VectorGridContextInterface {
  nonce: string;
  refreshVectorGrid: () => void;
}

export const VectorGridContext = createContext<VectorGridContextInterface>(
  null!
);

export const VectorGridProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [nonce, setNonce] = useState("nonce");

  const refreshVectorGrid = useCallback(() => {
    setTimeout(() => {
      setNonce(Math.random().toString());
    }, 500);
  }, []);

  const value = {
    nonce,
    refreshVectorGrid,
  };
  return (
    <VectorGridContext.Provider value={value}>
      {children}
    </VectorGridContext.Provider>
  );
};

export const useVectorGrid = () => {
  return useContext(VectorGridContext);
};
