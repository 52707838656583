import * as Yup from "yup";
import { FieldArray } from "formik";
import { FormFieldConfig } from "utils/types/form";
import { LinkButton } from "components/button/LinkButton";
import { Select } from "components/form/Select";
import { useImpactTypesQuery } from "components/fetch/useImpactTypesQuery";
import { useImpactTimeframesQuery } from "components/fetch/useImpactTimeframesQuery";
import { ReactComponent as BinIcon } from "assets/other-icons/delete.svg";

const fieldName = "trafficImpacts";

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: [],
  validationSchema: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Please select."),
      timeframe: Yup.string().required("Please select."),
    })
  ),
};

export const TrafficImpacts = ({
  title,
  values,
}: {
  title: string;
  values: any;
}) => {
  const { data: types } = useImpactTypesQuery();
  const { data: timeframes } = useImpactTimeframesQuery();

  return (
    <FieldArray name={fieldName}>
      {({ remove, push }) => (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="h6 mb-0">{title}</h4>
            <LinkButton onClick={() => push({ type: "", timeframe: "" })}>
              <div className="d-flex align-items-center">
                <span
                  className="me-1"
                  style={{ fontSize: "24px", lineHeight: "1" }}
                >
                  +
                </span>
                Add row
              </div>
            </LinkButton>
          </div>

          {values[fieldName]?.length > 0 && (
            <table className="table table-sm table-borderless text-small w-100">
              <thead>
                <tr>
                  <th>Impact name</th>
                  <th>Time of effect</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values[fieldName].map((_: never, index: number) => (
                  <tr key={`impact${index}`}>
                    <td>
                      <Select
                        label={`Impact name ${index}`}
                        name={`${fieldName}.${index}.type`}
                        hideLabel
                        noWrapper
                      >
                        <option value=""></option>
                        {types &&
                          Object.entries(types)?.map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Select>
                    </td>
                    <td>
                      <Select
                        label={`Time of effect ${index}`}
                        name={`${fieldName}.${index}.timeframe`}
                        hideLabel
                        noWrapper
                      >
                        <option value=""></option>
                        {timeframes &&
                          Object.entries(timeframes)?.map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Select>
                    </td>
                    <td>
                      <BinIcon
                        type="button"
                        width={24}
                        height={38}
                        className="btn-delete"
                        onClick={() => remove(index)}
                        aria-label="Delete traffic impact"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </FieldArray>
  );
};
TrafficImpacts.config = config;
