import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "contactEmail";
const maxLength = 150;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter an email address."),
};

export const ContactEmail = () => (
  <TextField
    label="Contact email address"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
ContactEmail.config = config;
