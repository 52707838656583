import { useParams } from "react-router-dom";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { ReactComponent as BinIcon } from "assets/other-icons/delete.svg";

export const DeleteWatchlistButton = () => {
  const { watchlistId } = useParams();
  const { deleteWatchlist } = useWatchlists();
  return (
    <BinIcon
      type="button"
      height={26}
      className="btn-delete"
      title="Delete watchlist"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      onClick={() => deleteWatchlist(Number(watchlistId), true)}
    />
  );
};
