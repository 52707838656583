import { ProjectOverview, Stage } from "utils/types/django";
import { ProjectOverviewTable } from "./ProjectOverviewTable";

export const OpportunitiesDetails = ({ stages }: { stages: Stage[] }) => {
  return !!stages ? (
    <>
      <hr />
      <h3 className="h5 mb-3">Opportunities</h3>
      <div className="d-flex flex-column gap-2">
        {stages
          .reduce(
            // collect clashes from each stage
            (projects: ProjectOverview[], s: Stage) => [
              ...projects,
              ...s.opportunities,
            ],
            []
          )
          .map((project: ProjectOverview) => (
            <ProjectOverviewTable key={project.id} project={project} />
          ))}
      </div>
    </>
  ) : null;
};
