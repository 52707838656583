import L from "leaflet";

export const hatchPattern = (colour: string) => {
  let canvas = document.createElement("canvas");
  if (!canvas) {
    return;
  }
  canvas.height = 8;
  canvas.width = 8;

  let canvasCtx = canvas.getContext("2d");
  if (!canvasCtx) {
    return;
  }
  canvasCtx.strokeStyle = colour;
  canvasCtx.lineWidth = 1.5;

  const x0 = 0;
  const x1 = canvas.width;
  const y0 = 0;
  const y1 = canvas.height;
  const offset = 8;

  const lines = [
    [
      [x0, y0],
      [x1, y1],
    ],
    [
      [x0 - offset, y0],
      [x1 - offset, y1],
    ],
    [
      [x0 + offset, y0],
      [x1 + offset, y1],
    ],
  ];

  canvasCtx.beginPath();
  for (let line of lines) {
    canvasCtx.moveTo(line[0][0], line[0][1]);
    canvasCtx.lineTo(line[1][0], line[1][1]);
  }
  canvasCtx.stroke();

  return canvas;
};

L.Canvas.include({
  _fillStroke(ctx: any, layer: any) {
    const options = layer.options;

    if (options.fill) {
      ctx.globalAlpha = options.fillOpacity;
      if (options.pattern) {
        ctx.imageSmoothingEnabled = false; // Disable anti-aliasing of the canvas
        ctx.fillStyle = ctx.createPattern(options.pattern, "repeat");
      } else {
        ctx.fillStyle = options.fillColor || options.color;
      }
      ctx.fill(options.fillRule || "evenodd");
    }

    if (options.stroke && options.weight !== 0) {
      if (ctx.setLineDash) {
        ctx.setLineDash(layer.options && (layer.options._dashArray || []));
      }

      ctx.globalAlpha = options.opacity;
      ctx.lineWidth = options.weight;
      ctx.strokeStyle = options.color;
      ctx.lineCap = options.lineCap;
      ctx.lineJoin = options.lineJoin;
      ctx.stroke();
    }
  },
});
