import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";
import { useField } from "formik";

export const fieldName = "projectStageTitle";
// Matching backend definition in backend.projects.models.Project.title
const maxLength = 150;

export const projectStageTitleConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string(),
};

export const ProjectStageTitle = () => {
  const [searchByCustomBoundaryField] = useField("searchByCustomBoundary");

  return (
    <TextField
      label="Project/stage title"
      name={fieldName}
      maxLength={maxLength}
      disabled={searchByCustomBoundaryField.value}
    />
  );
};
