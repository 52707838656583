import { useEffect, useRef } from "react";
import { FeatureGroup } from "react-leaflet";
import Leaflet from "leaflet";
import { EditControl } from "react-leaflet-draw";
import { GeoJSON } from "components/drawLayer/DrawLayerProvider";
import { useDrawLayer, DrawMode } from "components/drawLayer/DrawLayerProvider";
import "leaflet-draw/dist/leaflet.draw.css";
import "./DrawLayer.scss";

const polygonStyle = {
  color: "#17adb7",
  fillColor: "#000",
  weight: 3,
  opacity: 1,
  fillOpacity: 0.6,
};

export const DrawLayer = () => {
  const { geoJson, setGeoJson, drawMode } = useDrawLayer();

  const fgRef = useRef<Leaflet.FeatureGroup>(null);

  // Sync with stored data on mount
  useEffect(() => {
    // Only add new layers if there are no existing layers. Therefore, it is
    // the responsiblity of the module loading the geoJson to ensure that old
    // layers are cleared first, otherwise new layers won't be added, for
    // example when loading geoJson from a different saved search.
    if (fgRef.current && fgRef.current.getLayers().length === 0 && geoJson) {
      Leaflet.geoJSON(geoJson, { style: polygonStyle }).eachLayer((layer) => {
        fgRef.current?.addLayer(layer);
      });
    }
  }, [geoJson]);

  const onChange = () => {
    if (!fgRef.current) return;

    // TODO: We should investigate how we can support multiple GeoJSON types
    // here that are more precise than just "GeoJSON". This could be done, for
    // example, but having different draw layers and geoJSON state for each
    // use case, rather than a global draw layer.
    setGeoJson(fgRef.current.toGeoJSON() as GeoJSON);
  };

  return (
    <FeatureGroup ref={fgRef}>
      <EditControl
        position="topleft"
        onCreated={onChange}
        onEdited={onChange}
        onDeleted={onChange}
        draw={{
          polygon: {
            shapeOptions: polygonStyle,
          },
          polyline: false,
          circle: false,
          rectangle: false,
          marker: false,
          circlemarker: false,
        }}
        edit={{
          edit:
            drawMode === DrawMode.CreateStageBoundary
              ? { selectedPathOptions: polygonStyle }
              : false,
        }}
      />
    </FeatureGroup>
  );
};
