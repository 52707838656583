import { LeftHeaderTable, LeftHeaderRow } from "components/table";
import { ReactComponent as CalendarIcon } from "assets/other-icons/calendar.svg";

export const DateInfo = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return (
    <div className="">
      <LeftHeaderTable>
        <LeftHeaderRow
          header={
            <div className="d-flex align-items-center gap-2">
              <CalendarIcon width={18} height={19} aria-label="Calendar" />{" "}
              Start date
            </div>
          }
          data={startDate}
        />
        <LeftHeaderRow
          header={
            <div className="d-flex align-items-center gap-2">
              <CalendarIcon width={18} height={19} aria-label="Calendar" /> End
              date
            </div>
          }
          data={endDate}
        />
      </LeftHeaderTable>
    </div>
  );
};
