import logo from "assets/logo.png";
import "./LoginMainCol.scss";

interface LoginMainColProps {
  children: React.ReactNode;
}

export const LoginMainCol = ({ children }: LoginMainColProps) => (
  <main className="col-6">
    <img
      src={logo}
      alt="National Forward Works Viewer logo"
      className="mt-4 ms-4"
      style={{ width: "160px" }}
    />
    <div className="login-main-content">{children}</div>
  </main>
);
