import { ProjectLink } from "components/project/ProjectLink";
import { PublishedProjectsTablePagination } from "./PublishedProjectsTablePagination";
import { LoadingModal } from "components/loading/LoadingModal";
import { InternalProjectIcon } from "components/project/InternalProjectIcon";
import { useMyOrganisationsProjects } from "components/project/my-organisation/MyOrganisationsProjectsContext";

export const PublishedProjectsTable = () => {
  const { projects, page, isLoading } = useMyOrganisationsProjects();

  if (isLoading) {
    return <LoadingModal />;
  } else if (!projects || !Array.isArray(projects.results)) {
    return null;
  } else if (projects.count === 0) {
    return <div>No published projects found.</div>;
  }

  return (
    <>
      <table className="table table-sm table-hover text-small w-100">
        <tbody>
          {projects.results.map((project) => (
            <tr key={project.id}>
              <td className="d-flex justify-content-between">
                <ProjectLink project={project} flyTo />
                <InternalProjectIcon project={project} small />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PublishedProjectsTablePagination
        totalProjectsCount={projects?.count ?? 0}
        currentPageNumber={page}
      />
    </>
  );
};
