import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

const fieldName = "contactName";
const maxLength = 150;

const config: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string().required("Please enter contact name."),
};

export const ContactName = () => (
  <TextField
    label="Contact name"
    name={fieldName}
    maxLength={maxLength}
    required
  />
);
ContactName.config = config;
