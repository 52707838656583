import L from "leaflet";
import "leaflet.vectorgrid";

// Adds bringToFront functionality
L.VectorGrid.CustomProtobuf = L.VectorGrid.Protobuf.extend({
  options: {
    filter: (properties: any) => true,
    fetchOptions: {},
  },
  setFeatureStyle: function (id: number, layerStyle: any) {
    this._overriddenStyles[id] = layerStyle;

    for (var tileKey in this._vectorTiles) {
      var tile = this._vectorTiles[tileKey];
      var features = tile._features;
      var data = features[id];
      if (data) {
        var feat = data.feature;

        var styleOptions = layerStyle;
        if (layerStyle[data.layerName]) {
          styleOptions = layerStyle[data.layerName];
        }

        this._updateStyles(feat, tile, styleOptions);
        data.feature.bringToFront();
      }
    }
    return this;
  },
  _getVectorTilePromiseOrg:
    L.VectorGrid.Protobuf.prototype._getVectorTilePromise,
  _getVectorTilePromise: function (coords: any) {
    return this._getVectorTilePromiseOrg(coords).then((json: any) => {
      if (!this.options.filter) return json;
      for (var layerName in json.layers) {
        json.layers[layerName].features = json.layers[
          layerName
        ].features.filter(
          (feature: any) =>
            feature?.properties && this.options.filter(feature?.properties)
        );
        // Sets a deterministic ordering for all features
        json.layers[layerName].features = json.layers[layerName].features.sort(
          (a: any, b: any) => {
            const cmp = a.properties.project_id - b.properties.project_id;
            return cmp ? cmp : a.properties.id - b.properties.id;
          }
        );
      }
      return json;
    });
  },
  setFilter: function (filter: (properties: any) => boolean) {
    this.options.filter = filter;
    this.redraw();
    return this;
  },
});

L.vectorGrid.customProtobuf = function (url, options) {
  return new L.VectorGrid.CustomProtobuf(url, options);
};

// Has custom onClick event that fires map event along with canvas event.
L.Canvas.CustomTile = L.Canvas.Tile.extend({
  _onClick: function (e: any) {
    var point = this._map.mouseEventToLayerPoint(e).subtract(this.getOffset()),
      layer,
      clickedLayer;

    for (var id in this._layers) {
      layer = this._layers[id];
      if (
        layer.options.interactive &&
        layer._containsPoint(point) &&
        !this._map._draggableMoved(layer)
      ) {
        clickedLayer = layer;
      }
    }
    if (clickedLayer) {
      // this only fires canvas event
      this._fireEvent([clickedLayer], e);
    }
    // this propagates to map
    this._fireEvent(false, e);
  },
});

L.canvas.customTile = function (tileCoord: any, tileSize: any, opts: any) {
  return new L.Canvas.CustomTile(tileCoord, tileSize, opts);
};
