import { useRef, useState, ChangeEvent, MutableRefObject } from "react";
import { useField } from "formik";
import { LinkButton } from "components/button/LinkButton";
import { ChevronLeft, ChevronRight } from "components/svg/Chevron";
import { DropdownMultipleSelectorSelect } from "./DropdownMultipleSelectorSelect";

interface DropdownMultipleSelectorProps<T> {
  name: string;
  items: T[];
  size?: number;
  itemToString: (item: T | null) => string;
}

export function DropdownMultipleSelector<T>({
  name,
  items,
  size,
  itemToString,
}: DropdownMultipleSelectorProps<T>) {
  const selectLeft = useRef<HTMLSelectElement>(null);
  const selectRight = useRef<HTMLSelectElement>(null);

  const [selectedLeft, setSelectedLeft] = useState<T[]>([]);
  const [selectedRight, setSelectedRight] = useState<T[]>([]);

  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error;
  const { value } = field;
  const { setValue } = helpers;

  const buttonSize = 40;

  const selectedItems = (e: ChangeEvent<HTMLSelectElement>) =>
    Array.from(e.target.selectedOptions, (option: HTMLOptionElement) =>
      Number(option.value)
    ).map((index: number) => items[index]);

  const deselectAllFrom = (ref: MutableRefObject<HTMLSelectElement>) => {
    if (!ref.current) return;
    Array.from(ref.current.options).forEach((option: HTMLOptionElement) => {
      option.selected = false;
    });
  };

  const removeAll = () => {
    setSelectedLeft([]);
    setSelectedRight([]);
    setValue([]);
  };

  const shiftLeftToRight = () => {
    setValue([...value, ...selectedLeft]);
    setSelectedLeft([]);
    deselectAllFrom(selectLeft as MutableRefObject<HTMLSelectElement>);
  };

  const shiftRightToLeft = () => {
    setValue(value.filter((item: T) => !selectedRight.includes(item)));
    setSelectedRight([]);
    deselectAllFrom(selectRight as React.MutableRefObject<HTMLSelectElement>);
  };

  return (
    <>
      <div className="d-flex gap-2">
        <DropdownMultipleSelectorSelect
          ref={selectLeft}
          title="Invite parties"
          size={size}
          items={items}
          value={items.filter((item: T) => !value.includes(item))}
          filterPlaceholder="Type in organisation you want to invite"
          itemToString={itemToString}
          onChange={(e) => setSelectedLeft(selectedItems(e))}
          onFilterChange={(e) => {
            deselectAllFrom(selectLeft as MutableRefObject<HTMLSelectElement>);
            setSelectedLeft([]);
          }}
        />
        <div className="d-flex flex-column align-self-center gap-2">
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{ width: buttonSize, height: buttonSize }}
            onClick={shiftLeftToRight}
            disabled={selectedLeft.length === 0}
          >
            <ChevronRight />
          </button>
          <button
            className="btn btn-outline-primary"
            style={{ width: buttonSize, height: buttonSize }}
            onClick={shiftRightToLeft}
            disabled={selectedRight.length === 0}
          >
            <ChevronLeft />
          </button>
        </div>
        <DropdownMultipleSelectorSelect
          ref={selectRight}
          title="Project shared with..."
          size={size}
          items={items}
          value={value}
          filterPlaceholder="Filter"
          itemToString={itemToString}
          onChange={(e) => setSelectedRight(selectedItems(e))}
          onFilterChange={(e) => {
            deselectAllFrom(selectRight as MutableRefObject<HTMLSelectElement>);
            setSelectedRight([]);
          }}
        />
      </div>
      <div className="d-flex justify-content-between">
        <span className="text-danger">{isError && meta.error}</span>
        <LinkButton onClick={removeAll}>Remove all</LinkButton>
      </div>
    </>
  );
}
