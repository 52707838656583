import { ReactNode } from "react";
import {
  FormSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";
import { BookmarkAll } from "./BookmarkAll";

export const SearchResultsButtonsContainer = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  return (
    <div className="d-flex justify-content-between gap-2 mb-3">{children}</div>
  );
};

export const SearchResultsButtons = () => {
  const { formSearchStatus, flyToBounds } = useSearchedProjects();

  return (
    <SearchResultsButtonsContainer>
      <BookmarkAll />
      {formSearchStatus === FormSearchStatus.Inactive && (
        <button onClick={flyToBounds} className="btn btn-primary">
          Zoom to results
        </button>
      )}
    </SearchResultsButtonsContainer>
  );
};
