import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { CheckBox } from "components/form/CheckBox";

const fieldName = "completedProjects";

export const completedProjectsConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: false,
  validationSchema: Yup.boolean(),
};

export const CompletedProjects = () => {
  return <CheckBox label="Include completed projects" name={fieldName} />;
};
