import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { buildFormConfig } from "utils/form";
import { FormFieldConfig } from "utils/types/form";
import { useOrganisationsQuery } from "components/fetch/useOrganisationsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { Organisation } from "utils/types/django";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import {
  sharedProjectsId,
  useWatchlists,
} from "components/watchlist/WatchlistContext";
import {
  ShareProjectModals,
  ShareProjectModalsProps,
} from "./ShareProjectModals";
import { fieldName } from "./ShareProject";

interface ShareProjectFormModalProps {
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
}

export const ShareProjectFormModal = ({
  onSubmit,
  ...props
}: ShareProjectFormModalProps & ShareProjectModalsProps) => {
  const api = useDjangoApi();
  const { selectedProject } = useSelectedProject();
  const { data: organisations } = useOrganisationsQuery();
  const { data: project, refetch } = useProjectByIdQuery(selectedProject);
  const { refetchWatchlists, invalidateWatchlistQuery } = useWatchlists();

  const config: FormFieldConfig = {
    name: fieldName,
    initialValue: organisations?.filter((o: Organisation) =>
      project?.shared_organisations.includes(o.name)
    ),
    validationSchema: Yup.array(),
  };
  const { initialValues, validationSchema } = buildFormConfig(config);

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    const organisations = (values.sharedOrganisations as Organisation[]).map(
      (o: Organisation) => o.name
    );
    await api.postDjangoApi(
      `/api/projects/${selectedProject}/shared-organisations/`,
      { organisations }
    );

    onSubmit(values, helpers);
    invalidateWatchlistQuery(sharedProjectsId);
    refetchWatchlists();
    refetch();
  };

  if (!project || !organisations) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize // required to reset initialValues after the first submission
    >
      <Form noValidate>
        <ShareProjectModals {...props} />
      </Form>
    </Formik>
  );
};
