import { ReplaceTextList } from "utils/replaceText";

export const AUTH_MESSAGE_REPLACE_LIST: ReplaceTextList = [
  {
    from: "Could not authenticate user",
    to: "Email or Password is incorrect. Please try again.",
  },
  {
    from: "Account locked due to repeated login failures",
    to: "You have entered your password incorrectly too many times. Your account has been locked for the next 10 minutes. Please try again later, or use the 'Forgot password' link to reset your password.",
  },
];
