import { Helmet } from "react-helmet-async";

interface PageTitleProps {
  title: string;
}

export const PageTitle = ({ title }: PageTitleProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <h1>{title}</h1>
  </>
);
