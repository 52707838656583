import { useMyOrganisation } from "components/fetch/useMyOrganisation";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useSelectedProject } from "components/project/useSelectedProject";

export const SharedProjectDisclaimer = () => {
  const organisation = useMyOrganisation();
  const { selectedProject } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  return project &&
    project.is_internal &&
    project.organisation.id !== organisation?.id ? (
    <>
      <hr />
      <h4 className="text-smallest fw-700 mb-1">Disclaimer</h4>
      <div className="text-smallest fw-300">
        This project has been shared with you by{" "}
        <strong>{project.organisation.name}</strong> for your private viewing
        only. Redistribution or further sharing of this project is not
        permitted. You can see who the project has been shared with to ensure
        transparency and proper access control.
      </div>
    </>
  ) : null;
};
