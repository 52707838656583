import { createContext } from "react";
import {
  LoginArgs,
  ValidateTokenArgs,
  ResetPasswordArgs,
  SetPasswordArgs,
} from "./AuthProvider";

interface AuthContextInterface {
  isLoading: boolean;
  isAuthenticated: boolean;
  authToken: string;
  login: ({ email, password }: LoginArgs) => Promise<void>;
  logout: () => Promise<void>;
  validateToken: ({ email, token }: ValidateTokenArgs) => Promise<void>;
  resetPassword: ({ email }: ResetPasswordArgs) => Promise<void>;
  setPassword: ({ email, token, password }: SetPasswordArgs) => Promise<void>;
  expireSession: () => void;
}

export const AuthContext = createContext<AuthContextInterface>(null!);
