import { Path } from "utils/constants/paths";
import { PanelID } from "components/panel/PanelsProvider";
import { NavBarButton } from "./NavBarButton";
import { ReactComponent as MyProjectsIcon } from "assets/sidebar-icons/my-projects.svg";

const panelId = PanelID.MyOrganisation;
const path = Path.MyOrganisation;

export const MyProjects = () => (
  <NavBarButton
    component={MyProjectsIcon}
    path={path}
    panelId={panelId}
    data-testid="map-icon"
    height="38px"
    style={{ marginTop: "1px" }}
  />
);
