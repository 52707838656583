import { createContext, useState, useCallback, useContext } from "react";
import { Id } from "../SelectedProjectContext";

interface HoveredProjectContextInterface {
  hoveredProjectId: Id;
  hoveredStageId: Id;
  setHoveredProjectId: React.Dispatch<React.SetStateAction<Id>>;
  setHoveredStageId: React.Dispatch<React.SetStateAction<Id>>;
  removeHover: () => void;
}

export const HoveredProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [hoveredProjectId, setHoveredProjectId] = useState(null as Id);
  const [hoveredStageId, setHoveredStageId] = useState(null as Id);

  const removeHover = useCallback(() => {
    setHoveredProjectId(null);
    setHoveredStageId(null);
  }, []);

  const value = {
    hoveredProjectId,
    hoveredStageId,
    setHoveredProjectId,
    setHoveredStageId,
    removeHover,
  };

  return (
    <HoveredProjectContext.Provider value={value}>
      {children}
    </HoveredProjectContext.Provider>
  );
};

export const HoveredProjectContext =
  createContext<HoveredProjectContextInterface>(null!);

export const useHoveredProject = () => {
  return useContext(HoveredProjectContext);
};
