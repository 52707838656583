// Adaptation of leaflet-ruler repurposed for FWV
// https://github.com/gokertanrisever/leaflet-ruler

import L from "leaflet";

const distanceBetween = (pointA, pointB, scale) => {
  const f1 = pointA.lat;
  const l1 = pointA.lng;
  const f2 = pointB.lat;
  const l2 = pointB.lng;
  const toRadian = Math.PI / 180;
  const R = 6371 * (scale ?? 1);
  const deltaF = (f2 - f1) * toRadian;
  const deltaL = (l2 - l1) * toRadian;
  const a =
    Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
    Math.cos(f1 * toRadian) *
      Math.cos(f2 * toRadian) *
      Math.sin(deltaL / 2) *
      Math.sin(deltaL / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.abs(R * c);
};

L.Control.Ruler = L.Control.extend({
  options: {
    circleMarker: {
      color: "#00A8BC",
      radius: 5,
    },
    lineStyle: {
      color: "#18ABB5",
      weight: 3,
    },
    lengthUnit: {
      display: "km",
      decimal: 3,
      factor: 1,
      label: "",
    },
  },
  onAdd: function (map) {
    this._map = map;

    this._container = L.DomUtil.create("div", "leaflet-bar");
    L.DomEvent.disableClickPropagation(this._container);

    this._choice = false;
    this._defaultCursor = this._map._container.style.cursor;
    this._allLayers = L.layerGroup();

    return this._container;
  },
  toggleMeasure: function () {
    this._choice = !this._choice;
    this._clickedLatLong = null;
    this._clickedPoints = [];
    this._totalDistance = 0.0;

    if (this._choice) {
      this._map.doubleClickZoom.disable();
      L.DomEvent.on(this._map._container, "keydown", this._escape, this);
      L.DomEvent.on(this._map._container, "dblclick", this._closePath, this);

      this._clickCount = 0;
      this._tempLine = L.featureGroup().addTo(this._allLayers);
      this._tempPoint = L.featureGroup().addTo(this._allLayers);
      this._pointLayer = L.featureGroup().addTo(this._allLayers);
      this._polylineLayer = L.featureGroup().addTo(this._allLayers);

      this._allLayers.addTo(this._map);
      this._map._container.style.cursor = "crosshair";
      this._map.on("click", this._clicked, this);
      this._map.on("mousemove", this._moving, this);
    } else {
      this._map.doubleClickZoom.enable();
      L.DomEvent.off(this._map._container, "keydown", this._escape, this);
      L.DomEvent.off(this._map._container, "dblclick", this._closePath, this);
      this._map.removeLayer(this._allLayers);

      this._allLayers = L.layerGroup();
      this._map._container.style.cursor = this._defaultCursor;
      this._map.off("click", this._clicked, this);
      this._map.off("mousemove", this._moving, this);
    }
  },
  _distanceText: function (distance) {
    return `<b>${this.options.lengthUnit.label}</b> ${distance.toFixed(
      this.options.lengthUnit.decimal
    )} ${this.options.lengthUnit.display}`;
  },
  _clicked: function (e) {
    this._clickedLatLong = e.latlng;
    this._clickedPoints.push(this._clickedLatLong);
    L.circleMarker(this._clickedLatLong, this.options.circleMarker).addTo(
      this._pointLayer
    );

    const isNewPoint =
      this._clickCount > 0 &&
      !e.latlng.equals(this._clickedPoints[this._clickedPoints.length - 2]);

    if (isNewPoint) {
      if (this._movingLatLong) {
        L.polyline(
          [this._clickedPoints[this._clickCount - 1], this._movingLatLong],
          this.options.lineStyle
        ).addTo(this._polylineLayer);
      }

      this._calculateTotalDistance();

      L.circleMarker(this._clickedLatLong, this.options.circleMarker)
        .bindTooltip(this._distanceText(this._totalDistance), {
          permanent: true,
          className: "result-tooltip",
        })
        .addTo(this._pointLayer)
        .openTooltip();
    }

    this._clickCount++;
  },
  _moving: function (e) {
    if (!this._clickedLatLong) return;

    this._movingLatLong = e.latlng;

    if (this._tempLine) {
      this._map.removeLayer(this._tempLine);
      this._map.removeLayer(this._tempPoint);
    }

    this._tempLine = L.featureGroup();
    this._tempPoint = L.featureGroup();
    this._tempLine.addTo(this._map);
    this._tempPoint.addTo(this._map);
    this._addedLength = distanceBetween(
      this._movingLatLong,
      this._clickedLatLong,
      this.options.lengthUnit.factor
    );

    L.polyline(
      [this._clickedLatLong, this._movingLatLong],
      this.options.lineStyle
    ).addTo(this._tempLine);

    L.circleMarker(this._movingLatLong, this.options.circleMarker)
      .bindTooltip(
        this._distanceText(this._totalDistance + this._addedLength),
        {
          sticky: true,
          offset: L.point(0, -40),
          className: "moving-tooltip",
        }
      )
      .addTo(this._tempPoint)
      .openTooltip();
  },
  _escape: function (e) {
    if (e.keyCode === 27) {
      if (this._clickCount > 0) {
        this._closePath();
      } else {
        this._choice = true;
        this.toggleMeasure();
      }
    }
  },
  _calculateTotalDistance: function () {
    let distance = 0.0;

    if (this._clickedPoints.length >= 2) {
      for (let i = 1; i < this._clickedPoints.length; i++) {
        distance += distanceBetween(
          this._clickedPoints[i - 1],
          this._clickedPoints[i],
          this.options.lengthUnit.factor
        );
      }
    }

    this._totalDistance = distance;
  },
  _closePath: function () {
    this._map.removeLayer(this._tempLine);
    this._map.removeLayer(this._tempPoint);

    if (this._clickCount <= 1) {
      this._map.removeLayer(this._pointLayer);
    }

    this._choice = false;

    this.toggleMeasure();
  },
});

L.control.ruler = function (options) {
  return new L.Control.Ruler(options);
};
