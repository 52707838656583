import React from "react";
import { Field, useField, FieldHookConfig } from "formik";
import { FieldError } from "./FieldError";
import { FieldWrapper } from "./FieldWrapper";
import { Label } from "./Label";

interface CheckBoxProps {
  label: React.ReactNode;
}

type OtherProps = FieldHookConfig<string> & React.HTMLProps<HTMLInputElement>;

export const CheckBox = (props: CheckBoxProps & Omit<OtherProps, "label">) => {
  const { label, ...rest } = props;
  const field = useField(rest);
  const meta = field[1];
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;

  return (
    <FieldWrapper>
      <Field
        {...rest}
        id={id}
        className="form-check-input me-2"
        type="checkbox"
        required={false} // Don't want browser default validation
      />
      <Label
        htmlFor={id}
        label={label}
        required={props.required} // required is used only cosmetically for label
        className="form-check-label fw-600"
      />

      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
};
