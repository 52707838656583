import React from "react";
import { Field, useField, FieldHookConfig } from "formik";
import { FieldError } from "./FieldError";
import { FieldWrapper } from "./FieldWrapper";
import { Label } from "./Label";

type CheckBoxProps = {
  label: React.ReactNode;
};

type OtherProps = FieldHookConfig<string> & React.HTMLProps<HTMLInputElement>;

export default function Switch(
  props: CheckBoxProps & Omit<OtherProps, "label">
) {
  const { label, ...rest } = props;
  const [, meta] = useField(rest);
  const id = props.id || props.name;
  const isError = meta.touched && meta.error;

  return (
    <FieldWrapper>
      <div className="form-check form-switch">
        <Field
          {...rest}
          id={id}
          className="form-check-input me-2"
          type="checkbox"
          role="switch"
          required={false} // Don't want browser default validation
        />
        <Label
          htmlFor={id}
          label={label}
          required={props.required} // required is used only cosmetically for label
          className="form-check-label fw-600"
        />
      </div>

      {isError && <FieldError>{meta.error}</FieldError>}
    </FieldWrapper>
  );
}
