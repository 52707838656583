import * as Yup from "yup";
import { FormFieldConfig } from "utils/types/form";
import { TextField } from "components/form/TextField";

export const fieldName = "contact";
// Matching backend definition in backend.projects.models.ProjectContact
// max_length for email is not defined
// Use this https://stackoverflow.com/a/574698
const maxLength = 320;

export const contactConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: Yup.string(),
};

export const Contact = () => (
  <TextField
    label="Contact name or email address"
    name={fieldName}
    maxLength={maxLength}
  />
);
