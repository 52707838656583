interface ResetButtonProps {
  text: string;
}

export const ResetButton = ({ text }: ResetButtonProps) => (
  <button
    type="reset"
    className="btn-link btn-reset fw-700 bg-transparent border-0 mt-3"
  >
    {text}
  </button>
);
