import { useEffect, useMemo } from "react";
import {
  WatchlistSummary,
  WatchlistId,
  bookmarksId,
  emptyBookmarks,
  sharedProjectsId,
  useWatchlists,
} from "components/watchlist/WatchlistContext";
import { useWatchlist } from "components/watchlist/useWatchlist";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WatchlistProjectTable } from "./WatchlistProjectTable";
import { ReactComponent as ChevronsLeft } from "assets/other-icons/angles-right-solid.svg";
import { LoadingModal } from "components/loading/LoadingModal";
import { useErrorModal } from "components/modal/useErrorModal";
import { DeleteWatchlistButton } from "./DeleteWatchlistButton";
import { WatchlistUnreadProjectsInfo } from "./WatchlistUnreadProjectsInfo";
import { projectsPerWatchlist } from "components/fetch/useWatchlistQuery";

export const Watchlist = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { watchlistId } = useParams();
  const {
    bookmarks,
    sharedProjects,
    savedSearchWatchlists,
    isLoading: isLoadingWatchlists,
  } = useWatchlists();
  const { setErrorModal } = useErrorModal();

  const watchlistSummary = useMemo(() => {
    switch (watchlistId) {
      case bookmarksId:
        return bookmarks;
      case sharedProjectsId:
        return sharedProjects;
      default:
        return (
          savedSearchWatchlists.find(
            (w: WatchlistSummary) => w.id === Number(watchlistId)
          ) ?? emptyBookmarks
        );
    }
  }, [watchlistId, bookmarks, sharedProjects, savedSearchWatchlists]);

  const { data: watchlist, isLoading: isLoadingWatchlist } =
    useWatchlist(watchlistSummary);

  useEffect(() => {
    const invalid =
      pathname.startsWith("/dashboard/watchlist/") &&
      watchlistId &&
      ![bookmarksId, sharedProjectsId].includes(watchlistId as WatchlistId) &&
      isNaN(Number(watchlistId)) &&
      !isLoadingWatchlists;
    if (invalid) {
      setErrorModal({
        title: "Watchlist not found",
        content: "Could not find the watchlist specified.",
      });
      navigate("/dashboard");
    }
  }, [
    pathname,
    watchlistId,
    bookmarks,
    savedSearchWatchlists,
    watchlistSummary,
    isLoadingWatchlists,
    setErrorModal,
    navigate,
  ]);

  if (isLoadingWatchlist) {
    return <LoadingModal />;
  }

  return watchlistId && watchlist ? (
    <div className="panel-overlay">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start gap-3">
          <h2 className="h4">{watchlist.name}</h2>
          {![bookmarksId, sharedProjectsId].includes(
            watchlistId as WatchlistId
          ) && <DeleteWatchlistButton />}
        </div>
        <ChevronsLeft
          type="button"
          width={24}
          height={38}
          className="btn-collapse"
          aria-label="Collapse panel"
          onClick={() => navigate("/dashboard")}
        />
      </div>
      <hr />
      <WatchlistUnreadProjectsInfo watchlist={watchlistSummary} />
      {watchlistSummary.projects > projectsPerWatchlist && (
        <p className="text-danger">
          Some projects cannot be displayed as there are 1000 or more projects
          in this watchlist.
        </p>
      )}
      <WatchlistProjectTable watchlist={watchlist} />
    </div>
  ) : null;
};
