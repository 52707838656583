import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { Id } from "../SelectedProjectContext";
import { Project } from "utils/types/django";
import { LatLng, LatLngBoundsLiteral, LatLngTuple } from "leaflet";
import { BoundingBox, SearchResults } from "pages/root/Panel/Search/SearchForm";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { LoadingModal } from "components/loading/LoadingModal";

export enum FormSearchStatus {
  Inactive = "Inactive",
  InProgress = "InProgress",
  CompletionRequested = "CompletionRequested",
  CompletionStarted = "CompletionStarted",
  CancellationRequested = "CancellationRequested",
  CancellationStarted = "CancellationStarted",
}

export enum PointSearchStatus {
  Inactive = "Inactive",
  InProgress = "InProgress",
  NavigationRequested = "NavigationRequested",
  ReadyToNavigate = "ReadyToNavigate",
}

export interface FormSearch {
  params: FormData | null;
  totalRequests: number;
  remainingRequests: number;
  accumulatedResults: SearchResults[];
}

interface SearchedProjectsContextInterface {
  formSearchResults: SearchResults | null; // All currently loaded paginated results after submitting the search form
  currentPageResults: SearchResults | null; // TODO: Used to assist with pagination, may no longer be needed since we load all of formSearchResults before showing pagination
  pointSearchResults: SearchResults | null; // Results at the current map point, should be a subset of paginated results
  formSearchParams: FormData;
  formSearchResultsIds: Id[] | null;
  pointSearchResultsIds: Id[] | null;
  point: LatLng | null; // The current map point
  bounds: LatLngBoundsLiteral | null;
  isFlyingToBounds: boolean;
  isLoadingFirstPage: boolean; // TODO: The use case for this can probably be added to formSearchStatus as a new status before InProgress
  formSearchStatus: FormSearchStatus;
  pointSearchStatus: PointSearchStatus;
  currentFormSearch: FormSearch | null;

  /**
   * Used to show complete projects in the search result on the map
   * even if the layer is turned off.
   */
  resultIncludesCompletedProjects: boolean;

  startFormSearch: (parameters: FormData) => void;
  searchWithPageNumber: (pageNumber: number) => void;
  cancelFormSearch: () => void;
  completePointSearch: () => void;
  searchPointForOverlappingResults: (
    aPoint: LatLng,
    pointSearchParams: FormData
  ) => void;
  resetFormSearch: () => void;
  resetPointSearch: () => void;
  resetBothSearches: () => void;
  flyToBounds: () => void;
  setPoint: (point: LatLng | null) => void;
}

const defaultFormSearch: FormSearch = {
  params: null,
  totalRequests: 0,
  remainingRequests: 0,
  accumulatedResults: [],
};

export const projectsPerRequestToPopulateMap = 1000;

export const SearchedProjectsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [point, setPoint] = useState<LatLng | null>(null);
  const [isFlyingToBounds, setIsFlyingToBounds] = useState<boolean>(false);
  const [formSearchResults, setFormSearchResults] =
    useState<SearchResults | null>(null);
  const [currentPageResults, setCurrentPageResults] =
    useState<SearchResults | null>(null);
  const [pointSearchResults, setPointSearchResults] =
    useState<SearchResults | null>(null);
  const [formSearchParams, setFormSearchParams] = useState<FormData>(
    new FormData()
  );
  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState<boolean>(false);
  const [formSearchStatus, setFormSearchStatus] = useState<FormSearchStatus>(
    FormSearchStatus.Inactive
  );
  const [pointSearchStatus, setPointSearchStatus] = useState<PointSearchStatus>(
    PointSearchStatus.Inactive
  );
  const [currentFormSearch, setCurrentFormSearch] =
    useState<FormSearch>(defaultFormSearch);
  const { search } = useDjangoApi();

  const getIds = (res: SearchResults | null) =>
    res?.results?.map
      ? res.results.map((project: Project) => project.id)
      : null;

  const formSearchResultsIds = useMemo(
    () => getIds(formSearchResults),
    [formSearchResults]
  );
  const pointSearchResultsIds = useMemo(
    () => getIds(pointSearchResults),
    [pointSearchResults]
  );

  const bounds = useMemo(() => {
    const bbox = formSearchResults?.bbox;
    return bbox
      ? [
          bbox.slice(0, 2).reverse() as LatLngTuple,
          bbox.slice(2).reverse() as LatLngTuple,
        ]
      : null;
  }, [formSearchResults]);

  /**
   * Returns true if either formSearchResults or pointSearchResults contains a
   * a project with the state "Complete".
   */
  const resultIncludesCompletedProjects = useMemo(
    () =>
      (formSearchResults?.results?.some(
        (project) => project.state.toLowerCase() === "complete"
      ) ||
        pointSearchResults?.results?.some(
          (project) => project.state.toLowerCase() === "complete"
        )) ??
      false,
    [formSearchResults, pointSearchResults]
  );

  const resetFormSearch = useCallback(() => {
    setFormSearchResults(null);
    setCurrentPageResults(null);
  }, []);

  const resetPointSearch = useCallback(() => {
    setPoint(null);
    setPointSearchResults(null);
  }, []);

  const resetBothSearches = useCallback(() => {
    resetFormSearch();
    resetPointSearch();
  }, [resetFormSearch, resetPointSearch]);

  // Returns a combined bounding box covering the largest surface area:
  const largestBoundingBox = (boundingBoxes: BoundingBox[]): BoundingBox => {
    return [
      Math.min(
        ...boundingBoxes.map((bb) => (bb ? bb[0] : Number.POSITIVE_INFINITY))
      ), // min longitude
      Math.min(
        ...boundingBoxes.map((bb) => (bb ? bb[1] : Number.POSITIVE_INFINITY))
      ), // min latitude
      Math.max(
        ...boundingBoxes.map((bb) => (bb ? bb[2] : Number.NEGATIVE_INFINITY))
      ), // max longitude
      Math.max(
        ...boundingBoxes.map((bb) => (bb ? bb[3] : Number.NEGATIVE_INFINITY))
      ), // max latitude
    ];
  };

  const continueFormSearch = useCallback(
    (
      params: FormData | null,
      totalRequests: number,
      remainingRequests: number,
      accumulatedResults: SearchResults[]
    ) => {
      const pageNumber = totalRequests - remainingRequests + 1;
      const pageSize = projectsPerRequestToPopulateMap.toString();
      search(
        `/api/v2/projects/search/?page=${pageNumber}&page_size=${pageSize}`,
        params
      ).then((res: SearchResults) => {
        const clonedAccumlatedResults: SearchResults[] = [
          ...accumulatedResults,
        ];
        clonedAccumlatedResults.push(res);

        const newRemainingRequests = remainingRequests - 1;

        if (newRemainingRequests <= 0) {
          // Once all requests are complete, combine the search results into a
          // single SearchParams object:
          const projects: Project[] = formSearchResults
            ? [...formSearchResults.results]
            : [];

          // Also get the bounding boxes (generated for each page on the server):
          const boundingBoxes: BoundingBox[] = [];

          clonedAccumlatedResults.forEach((resultsFromRequest) => {
            resultsFromRequest.results.forEach((projectsFromRequest) => {
              projects.push(projectsFromRequest);
            });
            if (resultsFromRequest.bbox) {
              boundingBoxes.push(resultsFromRequest.bbox);
            }
          });
          const combinedSearchResults: SearchResults = {
            count: res.count,
            results: projects,
            bbox: largestBoundingBox(boundingBoxes),
          };
          // Now that we have combined the results for all results, update the
          // map again with the complete result set:
          setFormSearchResults(combinedSearchResults);

          // Now that all pages are loaded, turn loading off for the remaining
          // pages:
          setFormSearchStatus(FormSearchStatus.CompletionRequested);
        } else {
          // Set the form search parameters that we'll use to call this
          // function again if pagination has not been cancelled:
          setCurrentFormSearch({
            params,
            totalRequests,
            remainingRequests: newRemainingRequests,
            accumulatedResults: clonedAccumlatedResults,
          });
        }
      });
    },
    [formSearchResults, search, setCurrentFormSearch, setFormSearchStatus]
  );

  const startFormSearch = useCallback(
    (params: FormData) => {
      if (formSearchStatus === FormSearchStatus.InProgress) return;
      setIsLoadingFirstPage(true);

      // Before running a form search, reset the point search so that it
      // doesn't conflict with the new form search. Point searches should always
      // add to the form search:
      resetPointSearch();

      // useQuery doesn't fit in the use case of fetching on click with params.
      // Can manually trigger with refetch() but doesn't accept params https://react-query.tanstack.com/guides/disabling-queries
      // Manipulating states to decide whether to run query adds unnecessary complexities.
      // Just making axios request is a simple and good solution.
      search("/api/v2/projects/search/", params)
        .then((res: SearchResults) => {
          const totalProjectsCount = res.count;
          const numberOfRequestsToPopulateWholeMap = Math.ceil(
            totalProjectsCount / projectsPerRequestToPopulateMap
          );

          setFormSearchResults(null);

          setCurrentFormSearch({
            params,
            totalRequests: numberOfRequestsToPopulateWholeMap,
            remainingRequests: numberOfRequestsToPopulateWholeMap,
            accumulatedResults: [],
          });

          setFormSearchStatus(
            totalProjectsCount > 0
              ? FormSearchStatus.InProgress
              : FormSearchStatus.CompletionRequested
          );

          // Sets the first page of search results that are used in pagination:
          setCurrentPageResults(res);
        })
        .catch((err: unknown) => {})
        .finally(() => {
          // Finish loading the first page (which will remove the loading modal
          // while other pages load in the background).
          setIsLoadingFirstPage(false);
        });
      setFormSearchParams(params);
    },
    [formSearchStatus, resetPointSearch, search]
  );

  // Used for pagination, uses a cached version of the search params so that we
  // don't need to resubmit the search form each time a page is changed:
  const searchWithPageNumber = (pageNumber: number) => {
    if (isLoadingFirstPage) return;
    setIsLoadingFirstPage(true);
    search(`/api/v2/projects/search/?page=${pageNumber}`, formSearchParams)
      .then((res: SearchResults) => {
        setCurrentPageResults(res);
      })
      .catch((err: unknown) => {})
      .finally(() => setIsLoadingFirstPage(false));
  };

  const cancelFormSearch = useCallback(() => {
    setFormSearchStatus(FormSearchStatus.CancellationRequested);
  }, []);

  const completePointSearch = useCallback(() => {
    setPointSearchStatus(PointSearchStatus.Inactive);
  }, []);

  const resetCurrentFormSearch = useCallback(() => {
    setCurrentFormSearch({
      params: defaultFormSearch.params,
      totalRequests: defaultFormSearch.totalRequests,
      remainingRequests: defaultFormSearch.remainingRequests,
      accumulatedResults: [...defaultFormSearch.accumulatedResults],
    });
  }, []);

  // Search for projects overlapping the a point on the map (decoupled from the
  // the current point which is now set after the results are returned):
  const searchPointForOverlappingResults = useCallback(
    (aPoint: LatLng, pointSearchParams: FormData) => {
      // Prevents another search from occurring when a point is clicked if there
      // is a paginated or point search on the go:
      if (
        formSearchStatus !== FormSearchStatus.Inactive ||
        pointSearchStatus !== PointSearchStatus.Inactive
      ) {
        return;
      }

      // If the specified coordinates are not a valid number, don't try to
      // search them:
      if (isNaN(Number(aPoint.lat)) || isNaN(Number(aPoint.lng))) return;

      // Trigger another API query when the user clicks on a point. Because the
      // "Search results in area" or "Projects in area" results are typically
      // low in number, these are not paginated.
      setPointSearchStatus(PointSearchStatus.InProgress);
      pointSearchParams.append(
        "intersects",
        `SRID=4326;POINT(${aPoint.lng} ${aPoint.lat})`
      );
      search("/api/v2/projects/search/", pointSearchParams)
        .then((res: SearchResults) => {
          // Set both the point search results (previously overlappingResults)
          // and current point after the search is complete so that the
          // MapClickHandler knows that it should apply the results of the click:
          setPointSearchResults(res);
          setPoint(aPoint);
        })
        .catch((err: unknown) => {})
        .finally(() => {
          setPointSearchStatus(PointSearchStatus.NavigationRequested);
        });
    },
    [formSearchStatus, pointSearchStatus, search]
  );

  const flyToBounds = () => {
    // trigger any listeners of isFlyingToBounds
    setIsFlyingToBounds(true);
    setTimeout(() => {
      setIsFlyingToBounds(false);
    }, 100);
  };

  // Continue or cancel a paginated form search:
  useEffect(() => {
    // When the current form search state changes, call the function to
    // continue the form search again with the new values:
    if (
      formSearchStatus === FormSearchStatus.InProgress &&
      currentFormSearch.remainingRequests !== 0
    ) {
      continueFormSearch(
        currentFormSearch.params,
        currentFormSearch.totalRequests,
        currentFormSearch.remainingRequests,
        currentFormSearch.accumulatedResults
      );
    }

    // When a form search is completed, clear the current form search:
    if (formSearchStatus === FormSearchStatus.CompletionRequested) {
      resetCurrentFormSearch();
      setFormSearchStatus(FormSearchStatus.CompletionStarted);
    }

    if (
      formSearchStatus === FormSearchStatus.CompletionStarted &&
      !currentFormSearch
    ) {
      setFormSearchStatus(FormSearchStatus.Inactive);
    }

    // If the form search has been cancelled, prevent further calls of
    // the continueFormSearch function by resetting the current form
    // search:
    if (formSearchStatus === FormSearchStatus.CancellationRequested) {
      resetCurrentFormSearch();
      resetBothSearches();

      setFormSearchStatus(FormSearchStatus.CancellationStarted);
    }

    // Only when the form search object has been reset (no accumulated results
    // or remaining requests) should we consider the form search (or
    // cancellation of it) complete:
    if (
      [
        FormSearchStatus.CompletionStarted,
        FormSearchStatus.CancellationStarted,
      ].includes(formSearchStatus) &&
      currentFormSearch.accumulatedResults.length === 0 &&
      currentFormSearch.remainingRequests === 0
    ) {
      setFormSearchStatus(FormSearchStatus.Inactive);
    }
  }, [
    resetBothSearches,
    formSearchStatus,
    currentFormSearch,
    continueFormSearch,
    resetCurrentFormSearch,
  ]);

  // Continue a point search:
  useEffect(() => {
    // Only once both the point and point search results are set should we
    // attempt to navigate:
    if (
      pointSearchStatus === PointSearchStatus.NavigationRequested &&
      point &&
      pointSearchResults
    ) {
      setPointSearchStatus(PointSearchStatus.ReadyToNavigate);
    }
  }, [pointSearchStatus, point, pointSearchResults]);

  const value = {
    formSearchResults,
    currentPageResults,
    pointSearchResults,
    formSearchParams,
    formSearchResultsIds,
    pointSearchResultsIds,
    point,
    bounds,
    isFlyingToBounds,
    isLoadingFirstPage,
    formSearchStatus,
    pointSearchStatus,
    currentFormSearch,
    resultIncludesCompletedProjects,
    startFormSearch,
    searchWithPageNumber,
    cancelFormSearch,
    completePointSearch,
    searchPointForOverlappingResults,
    resetFormSearch,
    resetPointSearch,
    resetBothSearches,
    flyToBounds,
    setPoint,
  };

  return (
    <SearchedProjectsContext.Provider value={value}>
      {children}
      {isLoadingFirstPage && <LoadingModal />}
    </SearchedProjectsContext.Provider>
  );
};

export const SearchedProjectsContext =
  createContext<SearchedProjectsContextInterface>(null!);

export const useSearchedProjects = () => {
  return useContext(SearchedProjectsContext);
};
