import "./InternalProjectIcon.scss";

interface InternalProjectIconProps {
  project?: { is_internal: boolean };
  small?: boolean;
}

export const InternalProjectIcon = ({
  project,
  small = false,
}: InternalProjectIconProps) => {
  const size = small ? 20 : 32;
  return !project || project.is_internal ? (
    <div className="h-100">
      <div
        title="Internal project"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        style={{ width: size, height: size, lineHeight: `${size}px` }}
        className="internal-project-icon fw-700 user-select-none text-center rounded-circle"
      >
        I
      </div>
    </div>
  ) : null;
};
