import { useState, useCallback, useEffect } from "react";

import { ConfirmCancelPromptModal } from "components/modal/PromptModal";
import { useErrorModal } from "components/modal/useErrorModal";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { useProject } from "components/project/ProjectProvider";
import { useMyOrganisationsProjects } from "components/project/my-organisation/MyOrganisationsProjectsContext";
import { PanelID, usePanels } from "components/panel/PanelsProvider";
import { Path } from "utils/constants/paths";
import { useSearchedProjects } from "components/project/searched-projects/SearchedProjectsContext";
import { useMyOrganisation } from "components/fetch/useMyOrganisation";
import { LoadingModal } from "components/loading/LoadingModal";
import { useNavigate } from "react-router-dom";

enum DeleteProjectStatus {
  Idle = "idle",
  Requested = "requested",
  Deleting = "deleting",
}

export const DeleteProjectButton = ({
  projectId,
  projectTitle,
}: {
  projectId: number;
  projectTitle: string;
}) => {
  const [status, setStatus] = useState<DeleteProjectStatus>(DeleteProjectStatus.Idle);
  const { setErrorModal } = useErrorModal();
  const { data: project, remove } = useProjectByIdQuery(projectId);
  const { refetch: refetchMyOrgProjects } = useMyOrganisationsProjects();
  const { deleteProject } = useProject();
  const { someSearchPanelsAreOpen, isOpen, expandPanel } = usePanels();
  const { searchWithPageNumber } = useSearchedProjects();
  const myOrganisation = useMyOrganisation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    // navigate to the appropriate panel after deletion
    if (someSearchPanelsAreOpen) {
      navigate(Path.Search);
      expandPanel(PanelID.SearchResults);
    } else if (isOpen(PanelID.MyOrganisation)) {
      navigate(Path.MyOrganisation);
      expandPanel(PanelID.MyOrganisation);
    } else {
      // overlapping projects panel is dismissed when project is selected,
      // so there's nothing to return to
      navigate(Path.Index);
    }
  }, [someSearchPanelsAreOpen, expandPanel, isOpen, navigate]);

  const refetch = useCallback(() => {
    // refetch from the appropriate sources depending on the project deleted
    // and where it was deleted from
    if (someSearchPanelsAreOpen) {
      searchWithPageNumber(1);
    }

    if (project?.organisation?.name === myOrganisation?.name) {
      refetchMyOrgProjects();
    }
  }, [someSearchPanelsAreOpen, searchWithPageNumber, project, myOrganisation, refetchMyOrgProjects]);

  useEffect(() => {
    if (!project) {
      setErrorModal({
        title: "Request failed",
        content: "Project does not exist.",
      });
    } else if (status === DeleteProjectStatus.Deleting) {
      deleteProject(project).then(() => {
        refetch();
        remove(); // Removes cache
        setErrorModal({
          title: "Project deleted",
          content: `"${projectTitle}" project was deleted.`,
        });
        setStatus(DeleteProjectStatus.Idle);

        // TODO: navigation doesn't work if called at the same time as deletion
        setTimeout(handleNavigate, 100);
      }).catch(() => {
        setErrorModal({
          title: "Request failed",
          content: "Failed to delete project.",
        });
      });
    }
  }, [project, projectTitle, status, refetch, remove, handleNavigate, deleteProject, setErrorModal]);
    
  return (
    <>
      {status === DeleteProjectStatus.Deleting && <LoadingModal />}
      <button
        type="button"
        className="btn btn-outline-danger mt-3"
        onClick={() => setStatus(DeleteProjectStatus.Requested)}
      >
        Delete project
      </button>
      <ConfirmCancelPromptModal
        show={status === DeleteProjectStatus.Requested}
        title="Delete project?"
        content={`Are you sure you wish to delete the "${projectTitle}" project and all of its associated information?`}
        cancelText="Cancel"
        confirmText="Confirm deletion"
        onCancel={() => setStatus(DeleteProjectStatus.Idle)}
        onConfirm={() => setStatus(DeleteProjectStatus.Deleting)}
      />
    </>
  );
};
