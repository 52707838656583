import loginLaptop from "assets/login-laptop.png";
import "./LoginPageSideContent.scss";

export const LoginPageSideContent = () => (
  <div className="login-side-content d-flex flex-column">
    <div className="text-center pb-3">
      <h2 className="pb-4 h1">National Forward Works Viewer</h2>
      <p className="h3">
        View current, future and historic works from different organisations and
        regions on one simple map
      </p>
    </div>
    <ul className="pb-4">
      <li>
        Plan your programme with confidence using the clash and opportunity
        checker
      </li>
      <li>Optimise for a dig-once approach</li>
      <li>Mitigate programme risk and community disruption</li>
      <li>
        Improve sustainable outcomes through collaboration with other
        organisations and agencies
      </li>
    </ul>
    <img
      src={loginLaptop}
      className="login-laptop"
      alt="A open laptop showing the Forward Works Viewer dashboard"
    />
  </div>
);
