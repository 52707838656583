import { useDjangoQuery } from "./useDjangoQuery";
import { PaginatedResults, Project } from "utils/types/django";
import { Id } from "components/project/SelectedProjectContext";

export const useProjectsQuery = (
  params?: FormData,
  enabled: boolean = true
) => {
  return useDjangoQuery<PaginatedResults<Project>>(
    params ? `/api/projects/?${params.toString()}` : "/api/projects/",
    {
      enabled,
    }
  );
};

export const useProjectByIdQuery = (id: Id | string) => {
  return useDjangoQuery<Project>(`/api/projects/${id}/`, {
    enabled: !!id,
  });
};
