import classNames from "classnames";
import "./CascadingCheckboxAccordion.scss";

export enum CHECKBOX_STATES {
  Checked = "checked",
  Indeterminate = "indeterminate",
  Empty = "empty",
}

interface Props {
  id: string;
  title: React.ReactNode;
  indentation?: boolean;
  checked?: CHECKBOX_STATES;
  toggleChecked: (checked: CHECKBOX_STATES) => void;
  disabled?: boolean;
  children: React.ReactNode;
}

export const CascadingCheckboxAccordion = ({
  id,
  title,
  indentation = true,
  checked = CHECKBOX_STATES.Empty,
  toggleChecked,
  disabled = false,
  children,
}: Props) => (
  <div className={classNames("accordion", { "ms-3": indentation })}>
    <div className="cascading-checkbox d-flex justify-content-between align-items-center py-1 rounded">
      <input
        type="checkbox"
        className={classNames(`cascading-checkbox-input me-2 ${checked}`, {
          disabled: disabled,
        })}
        id={`${id}-checkbox`}
        onChange={() => toggleChecked(checked)}
        checked={checked !== CHECKBOX_STATES.Empty}
        disabled={disabled}
      />
      <label htmlFor={`${id}-checkbox`} className="flex-grow-1">
        {title}
      </label>
      <button
        className="accordion-button collapsed w-auto p-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded="false"
        aria-controls={id}
        aria-label={`Toggle ${title} accordion`}
      ></button>
    </div>
    <div id={id} className="accordion-collapse collapse">
      <div className="accordion-body p-0">{children}</div>
    </div>
  </div>
);
