import classNames from "classnames";

export interface EmailIconButtonProps {
  checked: boolean;
  title: string;
  width: number;
  height: number;
  onClick: () => void;
}

export const EmailIconButton = ({
  checked,
  title,
  width,
  height,
  onClick,
}: EmailIconButtonProps) => (
  <button
    className="btn btn-email"
    title={title}
    onClick={onClick}
    onMouseLeave={(e) => e.currentTarget.blur()}
  >
    <span
      style={{ width, height }}
      className={classNames("icon-email", { "icon-email-checked": checked })}
    ></span>
  </button>
);
