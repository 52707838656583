import { useEffect } from "react";
import { PanelBody } from "../PanelBody";
import { LoadingModal } from "components/loading/LoadingModal";
import { Tabs, TabItem, TabContent, TabPane } from "components/tab";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { ProjectSummaryTable } from "./ProjectSummaryTable";
import {
  usePanels,
  PanelID,
  PanelVisibility,
} from "components/panel/PanelsProvider";
import { InternalProjectIcon } from "components/project/InternalProjectIcon";

const summaryId = "clop-project-summary";

export const ClopPanel = () => {
  const { selectedClop, clearSelectedClop } = useSelectedProject();
  const { data: project, isLoading } = useProjectByIdQuery(selectedClop);

  const { setPanelVisibilities, someAreOpen } = usePanels();

  useEffect(() => {
    // When loading the project summary of a Clash or Opportunity, we should
    // close the search panels entirely if they are expanded or collapsed:
    if (
      selectedClop &&
      someAreOpen([PanelID.SearchForm, PanelID.SearchResults])
    ) {
      setPanelVisibilities([
        { id: PanelID.SearchForm, visibility: PanelVisibility.Closed },
        { id: PanelID.SearchResults, visibility: PanelVisibility.Closed },
      ]);
    }
  }, [someAreOpen, setPanelVisibilities, selectedClop]);

  if (!selectedClop) {
    return <PanelBody>{null}</PanelBody>;
  }
  if (selectedClop && isLoading) {
    return (
      <PanelBody>
        <LoadingModal />
      </PanelBody>
    );
  }
  if (!project) {
    return (
      <PanelBody>
        <div className="text-danger">
          Error: Can't display project summary. Selected project (id:{" "}
          {selectedClop}) does not exist.
        </div>
      </PanelBody>
    );
  }

  return (
    <PanelBody onClose={() => clearSelectedClop()}>
      <div className="d-flex justify-content-between w-100">
        <h2 className="h3">{project.title}</h2>
        <InternalProjectIcon project={project} />
      </div>
      <Tabs>
        <TabItem paneId={summaryId} active>
          Summary
        </TabItem>
      </Tabs>

      <TabContent>
        <TabPane id={summaryId} active>
          <h3 className="h5">Project summary</h3>
          <ProjectSummaryTable project={project} />
        </TabPane>
      </TabContent>
    </PanelBody>
  );
};
